<template>
  <!-- 服务参数 -->
  <div class="serviceParameters">
    <div class="serviceTop">
      <div class="serviceName">服务项目</div>
      <el-select
        v-model="value1"
        value-key="id"
        placeholder="请选择是否会员"
        clearable
        @change="queryBtn"
        @clear="clear"
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.name"
          :value="item"
        >
        </el-option>
      </el-select>
      <button @click="serviceAdd">{{ value1 ? "编辑" : "新增" }}</button>
    </div>
    <div class="serviceMid">
      <div class="serviceIntroduction">服务介绍</div>
      <el-input
        type="textarea"
        :rows="2"
        placeholder="请输入服务介绍"
        v-model="serviceData.introduce"
      >
      </el-input>
      <div class="serviceIntroduction serviceIntroduction1">
        服务清单
        <div class="addBtn" @click="addIntroduction">+</div>
      </div>
      <div class="serviceList">
        <div
          class="serviceItem"
          v-for="(item, index) in this.serviceData.serviceDetailedList"
          :key="index"
        >
          {{ item.type }}
          <div class="itemCancel" @click="delItem(item, index)">x</div>
        </div>
      </div>
    </div>
    <div class="serviceBot">
      <el-button @click="cancel" size="small">取 消</el-button>
      <el-button type="primary" :loading="loading" size="small" @click="okBtn"
        >确 定</el-button
      >
    </div>

    <!-- 编辑弹窗 -->
    <el-dialog
      :title="diaTitle === 1 ? '新增服务项目' : '新增服务清单'"
      :visible.sync="editShow"
      width="40%"
      :show-close="false"
    >
      <div class="new">
        <span v-if="diaTitle === 1">服务项目</span>
        <span v-else>服务清单</span>
        <el-input
          type="text"
          :placeholder="diaTitle === 1 ? '请输入服务项目' : '请输入服务清单'"
          v-model="value2"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel1" size="small">取 消</el-button>
        <el-button
          type="primary"
          :loading="loading"
          size="small"
          @click="okBtn1"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { addServiceProject, getSlectAllServiceProject, getSelectServiceProjectById, updateServiceProject } from '@/api/goodsManagement/goodsManagement.js'
export default {
  name: 'serviceParameters',
  components: {},
  props: {},
  data() {
    return {
      diaTitle: 1,
      editShow: false,
      isEdit: 1,
      serviceData: {
        introduce: "",
        name: "",
        serviceDetailedList: []
      },

      value1: null,
      value2: '',
      sum: null,
      loading: false,
      options: [], // 服务项目列表
      serviceList: [
        {
          id: 1,
          name: '底盘'
        },
        {
          id: 2,
          name: '方向盘'
        },
        {
          id: 3,
          name: '刹车'
        },
        {
          id: 4,
          name: '座椅'
        },
        {
          id: 51,
          name: '反光镜'
        },
        {
          id: 6,
          name: '车窗'
        },
      ]
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getSlectAllServiceProject();
  },
  methods: {
    // 查看所有服务项目列表
    async getSlectAllServiceProject() {
      const res = await getSlectAllServiceProject();
      // console.log(res);
      if (res.status === 200) {
        this.options = res.data;
        this.sum = res.data.length;
      }
      // console.log(this.sum);
    },
    async queryBtn() {
      // console.log('111');
      this.getSlectAllServiceProject();
      const res = await getSelectServiceProjectById(this.value1.id);
      // console.log(res);
      if (res.status === 200) {
        this.serviceData = res.data;
        this.isEdit = 2;
      }
    },
    clear() {
      if (this.options.length > this.sum) {
        this.options.splice(this.sum, 1);
      }
      this.isEdit = 1;
      this.serviceData = {
        introduce: "",
        name: "",
        serviceDetailedList: []
      };
    },
    // 新增服务项目按钮
    serviceAdd() {
      if (!this.value1) {
        this.value2 = '';
      } else {
        this.value2 = this.serviceData.name;
      }
      this.diaTitle = 1;
      this.editShow = true;
    },
    // 新增服务清单按钮
    addIntroduction() {
      this.value2 = '';
      this.diaTitle = 2;
      this.editShow = true;
    },
    // 删除清单
    delItem(val, index) {
      // console.log(val);
      this.$confirm('确认删除该清单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // console.log('删除分类', val);
        this.serviceData.serviceDetailedList.splice(index, 1);
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    // 取消按钮
    cancel() {
      if (this.options.length > this.sum) {
        this.options.splice(this.sum, 1);
      }
      this.isEdit = 1;
      this.value1 = null;
      this.serviceData = {
        introduce: "",
        name: "",
        serviceDetailedList: []
      };
    },
    // 确定按钮
    async okBtn() {
      if (this.isEdit === 1) {
        const res = await addServiceProject(this.serviceData);
        // console.log(res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '新增成功',
            type: 'success'
          });
          this.loading = false;
          // this.cancel();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
          this.loading = false;
        }
      } else {
        const res = await updateServiceProject(this.serviceData);
        // console.log(res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '编辑成功',
            type: 'success'
          });
          this.loading = false;
          this.cancel()
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
          this.loading = false;
        }
      }
    },
    // 弹层取消
    cancel1() {
      this.editShow = false;
    },
    // 弹层确定
    okBtn1() {
      if (this.diaTitle === 1) {
        if (!this.value1) {
          if (this.options.length > this.sum) {
            this.options.splice(this.sum, 1);
          }
          this.options.push({
            id: 9999,
            name: this.value2
          });
          this.value1 = {
            id: 9999,
            name: this.value2
          };
          this.serviceData.name = this.value2;
          this.editShow = false;
        } else {
          this.options.forEach(item => {
            if (this.value1.id === item.id) {
              item.name = this.value2;
            }
          })
          this.serviceData.name = this.value2;
          this.editShow = false;
        }
      } else {
        if (this.isEdit === 1) {
          this.serviceData.serviceDetailedList.push({
            type: this.value2
          });
          this.editShow = false;
          // this.$forceUpdate();
        } else {
          this.serviceData.serviceDetailedList.push({
            id: null,
            serviceProjectId: this.serviceData.id,
            type: this.value2
          });
          this.editShow = false;
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.serviceParameters {
  padding-top: 39px;
  .serviceTop {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    display: flex;
    .serviceName {
      width: 64px;
      height: 30px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #313131;
      line-height: 30px;
      margin-right: 10px;
    }
    button {
      width: 70px;
      height: 30px;
      background: #5f6679;
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 30px;
      cursor: pointer;
      margin-right: 10px;
    }
  }
  .serviceMid {
    width: 377px;
    height: 393px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    .serviceIntroduction {
      width: 377px;
      height: 34px;
      background: #f7f7f7;
      border-bottom: 1px solid #eeeeee;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7d7d7d;
      line-height: 34px;
      text-align: center;
    }
    ::v-deep.el-textarea {
      min-height: 155px !important;
      .el-textarea__inner {
        min-height: 155px !important;
        border: none;
        border-radius: 0;
        resize: none; /* 这个是去掉 textarea 下面拉伸的那个标志，如下图 */
      }
    }
    .serviceIntroduction1 {
      position: relative;
      border-top: 1px solid #eeeeee;
      .addBtn {
        cursor: pointer;
        position: absolute;
        top: 7px;
        right: 128px;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background: #d8d8d8;
        font-size: 24px;
        color: #fff;
        line-height: 18px;
        text-align: center;
      }
    }
    .serviceList {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 20px;
      padding-right: 0;
      .serviceItem {
        position: relative;
        width: 75px;
        height: 30px;
        background: #efefef;
        border-radius: 2px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666b71;
        line-height: 30px;
        text-align: center;
        margin-right: 13px;
        margin-bottom: 13px;
        .itemCancel {
          cursor: pointer;
          position: absolute;
          top: -8px;
          right: -8px;
          width: 16px;
          height: 16px;
          border-radius: 8px;
          border: 1px solid #fff;
          background: #efefef;
          font-size: 16px;
          color: #fff;
          line-height: 14px;
          text-align: center;
        }
      }
    }
  }
  .serviceBot {
    margin-top: 60px;
    padding-bottom: 60px;
    padding-left: 99px;
    .el-button--default {
      margin-right: 40px;
      width: 70px;
      height: 30px;
      background: #f0f2f6;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #5f6679;
      line-height: 30px;
      text-align: center;
      padding: 0;
    }
    .el-button--primary {
      width: 70px;
      height: 30px;
      background: #5f6679;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 30px;
      text-align: center;
      padding: 0;
    }
  }
  .new {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    span {
      display: inline-block;
      width: 58px;
      text-align: right;
      margin-right: 10px;
    }
    .el-input {
      width: 80% !important;
    }
  }
  // 输入框和选择框大小
  .el-input {
    width: 223px !important;
    margin-right: 10px !important;
  }
  .el-select {
    width: 223px !important;
    height: 30px !important;
    margin-right: 10px;
  }
  .serviceTop::v-deep .el-input__icon {
    line-height: 30px !important;
  }
  .serviceTop::v-deep .el-input__inner {
    height: 30px !important;
  }
}
</style>
