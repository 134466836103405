<template>
  <div class="home">
    <el-container>
      <el-aside class="menu">
        <div class="logo">
          <div class="icon">
            <img src="@/assets/ledianlogo.png" alt="" />
          </div>
        </div>
        <MenuVue></MenuVue>
      </el-aside>

      <el-container>
        <el-header style="font-size: 16px">
          <HeaderVue></HeaderVue>
        </el-header>
        <el-main>
          <!-- {{$route.meta.title}} -->
          <div class="container">
            <router-view></router-view>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import MenuVue from "../../components/menu/Menu.vue";
import HeaderVue from "../../components/header/Header.vue";
export default {
  data() {
    const item = {
      date: "2016-05-02",
      name: "王小虎",
      address: "上海市普陀区金沙江路 1518 弄",
    };
    return {
      tableData: Array(2).fill(item), // 表格数据
      menuData: [
        {
          path: "/user",
          title: "用户管理",
          icon: "el-icon-s-custom",
          id: 1,
          children: [
            {
              path: "/user/ulist",
              title: "用户列表",
              name: "ulist",
              component: "Ulist",
              id: 2,
              meta: { title: "用户列表" },
            },
          ],
        },
        {
          path: "/pment",
          title: "项目管理",
          id: 3,
          icon: "el-icon-s-platform",
          children: [
            {
              path: "/pment/manlist",
              id: 4,
              title: "项目列表",
              name: "manlist",
              component: "Manlist",
              meta: { title: "项目列表" },
            },
          ],
        },
        {
          path: "/menlist",
          title: "量表管理",
          id: 5,
          icon: "el-icon-document-copy",
          children: [
            {
              path: "/gaugeCategory",
              title: "量表分类",
              component: "Slist",
              id: 6,
              meta: { title: "量表分类" },
            },
            {
              path: "/menlist/sdelist",
              title: "量表组合",
              name: "sdelist",
              component: "Sdelist",
              id: 7,
              meta: { title: "量表数据" },
            },
          ],
        },
        {
          path: "/tment",
          title: "合作方管理",
          icon: "el-icon-s-cooperation",
          id: 8,
          children: [
            {
              path: "/tment/parlist",
              title: "合作方列表",
              name: "parlist",
              component: "Parlist",
              id: 9,
              meta: { title: "合作方列表" },
            },
          ],
        },
        {
          path: "/stment",
          title: "系统管理",
          icon: "el-icon-menu",
          id: 10,
          children: [
            {
              path: "/stment/tislist",
              title: "组织架构",
              name: "tislist",
              component: "Tislist",
              id: 11,
              meta: { title: "组织架构" },
            },
            {
              path: "/stment/tuserlist",
              title: "系统用户",
              name: "tuserlist",
              component: "Tuserlist",
              id: 12,
              meta: { title: "系统用户" },
            },
            {
              path: "/stment/trolelist",
              title: "角色管理",
              name: "trolelist",
              component: "Trolelist",
              id: 13,
              meta: { title: "角色管理" },
            },
            {
              path: "/stment/setpwd",
              title: "密码修改",
              name: "setpwd",
              component: "Setpwd",
              id: 14,
              meta: { title: "密码修改" },
            },
          ],
        },
      ], // 菜单数组
      isCollapse: false, // 控制菜单是否展开
    };
  },
  components: {
    MenuVue,
    HeaderVue,
  },
};
</script>

<style scoped lang="scss">
.home {
  // position: relative;
  // height: 100vh;
  // overflow-y: scroll;
  // background-color: #5B87A3;
  // box-sizing: border-box;
  // min-height: 100%;
  // overflow: hidden;
  .menu {
    /* // 修改滚动条样式 */
    &::-webkit-scrollbar {
      display: none;
      // width: 10px;
      /*高宽分别对应横竖滚动条的尺寸 */
      // max-height: 10px;
      // background-color: #dde;
    }
  }

  .el-container {
    height: 100%;
  }
}
.el-header {
  background: #232932;
  color: #333;
  line-height: 40px;
  height: 80px !important;
  padding: 0;
  // box-shadow: 1px 0px 1px 0px #A4D6F6;
  box-shadow: 0px 2px 2px 0px rgba(3, 31, 48, 0.15);
}
.el-aside {
  overflow-x: hidden;
  box-sizing: border-box;
  // overflow: hidden;
  color: #333;
  height: 100vh;
  max-width: 200px;
  // box-shadow: 1px 0px 1px 0px #A4D6F6;
  box-shadow: 2px 0px 2px 0px rgba(3, 31, 48, 0.17);
  // position: absolute;
  // z-index: 2;
  // position: sticky;
  padding: 0;
  background: #232932;
  // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.24);
  .logo {
    height: 80px;
    width: 100%;
    position: sticky;
    top: 0px;
    z-index: 2;
    background: #232932;
    box-shadow: 0px 2px 2px 0px rgba(3, 31, 48, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      width: 150px;
      height: 50px;
      border-radius: 8px;
      text-align: center;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.el-main {
  background: #f0f2f6;
  // height: 100%;
  // height: 100vh;
  overflow: hidden;
  width: 100%;
  // flex-direction: column;
  // flex-grow: 1;
  // align-content: stretch;
  padding-bottom: 0px !important;
  .container {
    background: white;
    height: calc(100vh - 120px);
    overflow-y: scroll;
    box-shadow: -1px -1px 1px 0px rgba(0, 0, 0, 0.08),
      1px 1px 1px 0px rgba(0, 0, 0, 0.08);
  }
}
</style>