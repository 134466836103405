<template>
  <!-- 个人客户详情 -->
  <div class="customerDetail">
    <div class="shopDetail">
      <el-form ref="form" :model="formData" label-width="100px">
        <!-- <el-form-item label="微信昵称" v-if="checkEdit === 'edit'">
          <el-input v-model="formData.vxName" disabled></el-input>
        </el-form-item>
        <el-form-item label="UID" v-if="checkEdit === 'edit'">
          <el-input v-model="formData.openId" disabled></el-input>
        </el-form-item> -->
        <el-form-item label="姓名">
          <el-input
            v-model="formData.userName"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-select
            v-model="formData.gender"
            placeholder="请选择性别"
            clearable
          >
            <el-option
              v-for="item in genderOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input
            v-model="formData.telephone"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="注册时间" v-if="checkEdit === 'edit'">
          <el-input v-model="formData.createTime" disabled></el-input>
        </el-form-item>
        <!-- <el-form-item label="车辆数" v-if="checkEdit === 'edit'">
          <el-input
            v-model="formData.carVoList.length"
            placeholder="请输入车辆数"
          ></el-input>
        </el-form-item> -->
        <el-form-item>
          <div class="titleBox">
            <div class="div1Box">车牌号</div>
            <div class="div2Box">车型</div>
            <el-button
              class="btnBox"
              type="warning"
              size="mini"
              @click="addSite"
              >添加</el-button
            >
          </div>
          <div v-if="this.formData.carVoList.length !== 0">
            <div
              class="contentBox"
              v-for="(item, index) in this.formData.carVoList"
              :key="index"
            >
              <div class="div1Box div1Box2">
                <el-input
                  v-model="item.carNumber"
                  placeholder="请输入车牌号"
                  maxlength="8"
                  style="border: none; width: 90%; height: 90%; padding: 0"
                />
              </div>
              <div class="div1Box2 div2Box">
                <el-cascader
                  v-model="item.carClassificationId"
                  :props="defaultProps"
                  :options="options"
                  @change="handleChange"
                  style="border: none; width: 90%; height: 90%; padding: 0"
                ></el-cascader>
                <el-button
                  v-if="checkEdit === 'edit'"
                  class="btnBox1"
                  type="warning"
                  size="mini"
                  @click="seeSite(item)"
                  >查看</el-button
                >
              </div>
            </div>
          </div>
          <div class="contentBox1" v-else>暂无信息</div>
        </el-form-item>

        <!-- <el-form-item label="是否会员">
          <el-switch
            v-model="formData.isVip"
            active-color="#5F6679"
            :active-value="1"
            :inactive-value="2"
            inactive-color="#B3B3B3"
          ></el-switch>
        </el-form-item> -->

        <el-form-item label="是否会员">
          <el-select
            v-model="formData.memberId"
            placeholder="请选择是否会员"
            clearable
          >
            <el-option
              v-for="item in vipOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="开通会员时间"
          v-if="formData.isVip === 1 && checkEdit === 'edit'"
        >
          <el-input
            disabled
            v-model="formData.vipTime"
            placeholder="请输入开通会员时间"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="会员到期时间"
          v-if="formData.isVip === 1 && checkEdit === 'edit'"
        >
          <el-input
            disabled
            v-model="formData.vipTime"
            placeholder="请输入会员到期时间"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="余额" v-if="checkEdit === 'edit'">
          <div class="moneySumBox">
            <el-input
              disabled
              type="number"
              v-model="formData.balance"
              placeholder="请输入余额"
            ></el-input>
            <el-button
              class="btnBox"
              type="warning"
              size="mini"
              @click="moneySumBtn"
              >余额变更</el-button
            >
          </div>
        </el-form-item> -->
        <el-form-item label="消费总金额" v-if="checkEdit === 'edit'">
          <el-input
            v-model="formData.moneySum"
            placeholder="请输入消费总金额"
          ></el-input>
        </el-form-item>
        <el-form-item label="积分余额" v-if="checkEdit === 'edit'">
          <div class="moneySumBox">
            <el-input
              disabled
              type="number"
              v-model="formData.integral"
              placeholder="请输入积分"
            ></el-input>
            <el-button
              class="btnBox"
              type="warning"
              size="mini"
              @click="moneySumBtn1"
              >积分变更</el-button
            >
          </div>
        </el-form-item>
        <el-form-item>
          <el-button class="cancelBox" @click="cancel">取消</el-button>
          <el-button type="primary" :loading="loading" @click="onSubmit"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <!-- 余额变更弹窗 -->
    <el-dialog
      title="余额变更"
      :visible.sync="moneySumShow"
      width="40%"
      :show-close="false"
    >
      <div class="new">
        <span>余额变更</span>
        <el-input
          type="text"
          maxlength="10"
          placeholder="请输入余额变更金额"
          v-model="balanceData.balance"
        ></el-input>
      </div>
      <div class="new">
        <span>变更原因</span>
        <el-input
          type="text"
          maxlength="50"
          placeholder="请输入变更原因"
          v-model="balanceData.applyReason"
        ></el-input>
      </div>
      <div class="new">
        <span>凭证号</span>
        <el-input
          type="text"
          maxlength="20"
          placeholder="请输入凭证号"
          v-model="balanceData.voucherNumber"
        ></el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="moneyCancel" size="small">取 消</el-button>
        <el-button type="primary" :loading="loading" size="small" @click="okBtn"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 积分变更弹窗 -->
    <el-dialog
      title="积分变更"
      :visible.sync="integralShow"
      width="40%"
      :show-close="false"
    >
      <div class="new">
        <span>积分变更</span>
        <el-input
          type="text"
          maxlength="10"
          placeholder="请输入积分变更数值"
          v-model="integralData.integral"
        ></el-input>
      </div>
      <div class="new">
        <span>变更原因</span>
        <el-input
          type="text"
          maxlength="50"
          placeholder="请输入变更原因"
          v-model="integralData.applyReason"
        ></el-input>
      </div>
      <div class="new">
        <span>凭证号</span>
        <el-input
          type="text"
          maxlength="20"
          placeholder="请输入凭证号"
          v-model="integralData.voucherNumber"
        ></el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="moneyCancel1" size="small">取 消</el-button>
        <el-button
          type="primary"
          :loading="loading"
          size="small"
          @click="okBtn1"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  addCustomer, getSelectCarClass, updateCustomer, getSelectCustomerById, getMember,
  deleteFile, customerBalanceChange, customerIntegralChange
} from '@/api/customerManagement/customerManagement.js'
export default {
  name: 'customerDetail',
  data() {
    return {
      moneySumShow: false,
      balanceData: {
        balance: null, // 余额
        customerId: parseInt(sessionStorage.getItem('customerId')),
        applyReason: '', // 申请原因
        voucherNumber: '', // 凭证号
        // uploadData: [
        //   {
        //     id: null,
        //     rechargeVoucher: '',
        //     explain: '',
        //   }
        // ],
      }, // 余额变更数据
      integralShow: false,
      integralData: {
        integral: null, // 积分
        customerId: parseInt(sessionStorage.getItem('customerId')),
        applyReason: '', // 申请原因
        voucherNumber: '', // 凭证号
      }, // 积分变更数据

      // -----------------------------
      checkEdit: sessionStorage.getItem('checkEdit'),
      token: sessionStorage.getItem('token'),
      customerId: parseInt(sessionStorage.getItem('customerId')),
      defaultProps: {
        value: 'id',
        children: 'children',
        label: 'name',
        emitPath: false
      },
      loading: false,
      formData: {
        carVoList: [
          {
            carClassificationId: null,
            carNumber: ""
          }
        ],
        balance: 0, // 余额
        memberId: null, // 会员id
        gender: null, // 性别1男2女3保密
        integral: null, // 积分
        isVip: 2,
        moneySum: null,
        telephone: "",
        userName: ""
      },
      options: [],
      vipOptions: [
        {
          id: null,
          name: '非会员'
        }
      ],
      genderOptions: [
        {
          id: 1,
          name: '男'
        },
        {
          id: 2,
          name: '女'
        },
        // {
        //   id: 3,
        //   name: '保密'
        // },
      ],
    }
  },
  created() {
    this.getSelectCarClass();
    this.getMember();
    // console.log(this.customerId);
    if (this.customerId) {
      this.getSelectCustomerById();
    }
  },
  methods: {
    // 客户详情
    async getSelectCustomerById() {
      const res = await getSelectCustomerById({ customerId: parseInt(this.customerId) });
      // console.log(res);
      if (res.status === 200) {
        this.formData = {
          carVoList: res.data.carVoList,
          id: res.data.id,
          balance: res.data.balance ? res.data.balance : 0,
          integral: res.data.integral,
          isVip: res.data.isVip,
          memberId: res.data.memberId,
          moneySum: res.data.moneySum,
          gender: res.data.gender,
          telephone: res.data.telephone,
          createTime: res.data.createTime,
          vipTime: res.data.vipTime,
          openId: res.data.openId,
          vxName: res.data.vxName,
          userName: res.data.userName
        };
      }
      // console.log(this.formData);
    },
    // 车辆分类数据
    async getSelectCarClass() {
      const res = await getSelectCarClass();
      // console.log(res);
      if (res.status === 200) {
        this.options = res.data;
      }
      // console.log(this.options);
      this.options.forEach(item => {
        if (item.children.length === 0) {
          item.children = undefined;
        } else {
          item.children.forEach(item1 => {
            if (item1.children.length === 0) {
              item1.children = undefined;
            }
          })
        }
      });
    },
    // 获取会员类型列表
    async getMember() {
      const res = await getMember(1);
      // console.log(res);
      if (res.status === 200) {
        this.vipOptions = [...this.vipOptions, ...res.data];
      }
    },
    // 级联选择
    handleChange(val) {
      // console.log(val);
    },
    // 查看车辆详情
    seeSite(val) {
      // console.log(val);
      sessionStorage.setItem('carId', val.carId);
      sessionStorage.setItem('carCheckEdit', 'edit');
      this.$router.push('/home/carTabs/editCarCat');
    },
    // 提交信息
    async onSubmit() {
      this.loading = true;
      this.formData.integral = parseInt(this.formData.integral);
      if (this.formData.memberId) {
        this.formData.isVip = 1;
      } else {
        this.formData.isVip = 2;
      }
      for (let i = 0; i < this.formData.carVoList.length; i++) {
        if (!this.formData.carVoList[i].carClassificationId) {
          this.formData.carVoList.splice(i, 1);
          i--;
        }
      }
      if (this.checkEdit === 'add') {
        // console.log(this.formData);
        const res = await addCustomer(this.formData);
        // console.log(res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '新增成功',
            type: 'success'
          });
          this.loading = false;
          sessionStorage.setItem('customerId', res.data);
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
          this.loading = false;
        }
      } else {
        const res = await updateCustomer(this.formData);
        // console.log(res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '编辑成功',
            type: 'success'
          });
          this.loading = false;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
          this.loading = false;
        }
      }
    },
    // 取消按钮
    cancel() {
      this.$router.push('/home/customerManagement')
    },
    // 添加车辆
    addSite() {
      this.formData.carVoList.push(
        {
          carClassificationId: null,
          carNumber: ""
        }
      )
    },
    // 余额变更 
    moneySumBtn() {
      this.moneySumShow = true;
    },
    // 弹层取消按钮
    moneyCancel() {
      this.moneySumShow = false;
    },
    // 弹层确定按钮
    async okBtn() {
      // this.balanceData.explain = this.balanceData.uploadData[0].explain
      // this.balanceData.balance = Number(this.balanceData.balance)
      // console.log(this.balanceData);
      const res = await customerBalanceChange(this.balanceData);
      // console.log(res);
      if (res.status === 200) {
        this.$message({
          showClose: true,
          message: '余额变更成功',
          type: 'success'
        });
        this.getSelectCustomerById();
        this.balanceData = {
          balance: null, // 余额
          customerId: parseInt(sessionStorage.getItem('customerId')),
          applyReason: '', // 申请原因
          voucherNumber: '', // 凭证号
        };
        this.moneySumShow = false;
      } else {
        this.$message({
          showClose: true,
          message: res.msg,
          type: 'error'
        });
      }
    },
    // 积分变更 
    moneySumBtn1() {
      this.integralShow = true;
    },
    // 弹层取消按钮
    moneyCancel1() {
      this.integralShow = false;
    },
    // 弹层确定按钮
    async okBtn1() {
      const res = await customerIntegralChange(this.integralData);
      // console.log(res);
      if (res.status === 200) {
        this.$message({
          showClose: true,
          message: '积分变更成功',
          type: 'success'
        });
        this.getSelectCustomerById();
        this.integralData = {
          integral: null, // 积分
          customerId: parseInt(sessionStorage.getItem('customerId')),
          applyReason: '', // 申请原因
          voucherNumber: '', // 凭证号
        };
        this.integralShow = false;
      } else {
        this.$message({
          showClose: true,
          message: res.msg,
          type: 'error'
        });
      }
    },

  }
}
</script>

<style lang='scss' scoped>
.customerDetail {
  width: 100%;
  min-height: 300px;
  ::v-deep .el-button {
    width: 70px;
    height: 30px;
    background: linear-gradient(270deg, #01df6e 0%, #2df891 50%, #01df6e 100%);
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    border: none;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 5px;
    margin-bottom: 20px;
  }
  ::v-deep .el-button--default {
    background: #f4fff5;
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    color: #01df6e;
    margin-right: 20px;
  }
  .new {
    // display: flex;
    // align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    span {
      display: inline-block;
      width: 58px;
      text-align: right;
      margin-right: 10px;
    }
    .newText {
      width: 100%;
      margin-bottom: 10px;
    }
    .el-input {
      width: 80% !important;
    }
    .conItem {
      display: flex;
      margin-bottom: 10px;

      .uploadBox {
        position: relative;
        width: 65px;
        height: 65px;
        background: #ffffff;
        border: 1px solid #979797;
        border-radius: 5px;
        margin-right: 21px;

        .avatar-uploader .el-upload {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }

        .avatar-uploader .el-upload:hover {
          border-color: #409eff;
        }

        .avatar-uploader-icon {
          display: block;
          font-style: normal;
          font-size: 24px;
          color: #8c939d;
          width: 65px;
          height: 65px;
          line-height: 65px;
          text-align: center;
        }

        .avatar {
          width: 65px;
          height: 65px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .colse {
          cursor: pointer;
          position: absolute;
          top: -9px;
          right: -9px;
          width: 18px;
          height: 18px;
          line-height: 18px;
          text-align: center;
          font-size: 12px;
          border-radius: 9px;
          background: #5f6679;
          color: #fff;
          z-index: 999;
        }
      }

      .inputBox {
        flex: 1;
        position: relative;
        // width: 380px;
        // height: 65px;
        // background: #ffffff;
        // border: 1px solid #cccccc;
        .delIconBox {
          cursor: pointer;
          position: absolute;
          top: 15px;
          right: 10px;
          width: 34px;
          height: 34px;
          border-radius: 17px;
          line-height: 34px;
          text-align: center;
          background: #f0f2f6;
        }

        ::v-deep.el-textarea {
          width: 92%;
          height: 65px !important;

          .el-textarea__inner {
            width: 92%;
            height: 65px !important;
            // border: none;
            // border-radius: 0;
            resize: none; /* 这个是去掉 textarea 下面拉伸的那个标志，如下图 */
            padding-right: 50px;
          }
        }
      }
    }
  }
  .shopDetail {
    width: 520px;
    padding-top: 20px;
    .el-myselect {
      width: 100%;
    }
    .el-mytime {
      width: 108px;
    }
    .el-select {
      width: 100%;
    }
    .carid {
      width: 100%;
      height: 40px;
      line-height: 40px;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7d7d7d;
      span {
        cursor: pointer;
        margin: 0 8px;
      }
    }
    .titleBox {
      position: relative;
    }
    .titleBox,
    .contentBox {
      display: flex;
      div {
        // flex: 1;
        // width: 260px;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #7d7d7d;
        background: #ffffff;
        padding-left: 10px;
        box-sizing: border-box;
        border: 1px solid #dddddd;
        ::v-deep.el-input__inner {
          width: 100%;
          height: 100%;
          border: none;
        }
      }
      .div1Box {
        flex: 1;
        border-right: none;
      }
      .div2Box {
        flex: 2;
      }
      .div1Box2 {
        position: relative;
        border-top: none;
        .btnBox1 {
          position: absolute;
          top: 3px;
          right: -90px;
          min-width: 80px;
          height: 30px;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          background: linear-gradient(
            270deg,
            #e7710d 0%,
            #f6903a 50%,
            #e7710d 100%
          );
          box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
          border-radius: 3px;
        }
      }
      .btnBox {
        position: absolute;
        top: 3px;
        right: -90px;
        min-width: 80px;
        height: 30px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        background: linear-gradient(
          270deg,
          #e7710d 0%,
          #f6903a 50%,
          #e7710d 100%
        );
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
      }
    }
    .moneySumBox {
      position: relative;
      .btnBox {
        position: absolute;
        top: 5px;
        right: -90px;
        min-width: 80px;
        height: 30px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        background: linear-gradient(
          270deg,
          #e7710d 0%,
          #f6903a 50%,
          #e7710d 100%
        );
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
      }
    }
    .contentBox1 {
      // width: 520px;
      width: 100%;
      height: 36px;
      line-height: 36px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #dddddd;
      box-sizing: border-box;
      border: 1px solid #dddddd;
      border-top: none;
    }
  }
}
</style>