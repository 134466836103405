<template>
  <!-- 常用地址 -->
  <div class="address">
    <div class="staff">
      <el-button type="primary" @click="addShow">新增</el-button>

      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="address" label="地址"> </el-table-column>
        <el-table-column prop="parkingSpace" label="停车位"> </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <span @click="edit(scope.row)" style="margin-right: 20px"
              >编辑</span
            >
            <span @click="del(scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 编辑弹窗 -->
    <el-dialog
      :title="diaTitle === 1 ? '新增常用地址' : '编辑常用地址'"
      :visible.sync="editShow"
      width="40%"
      :show-close="false"
    >
      <div class="new">
        <span>地址前缀</span>
        <el-cascader
          v-model="newStaff.shopAddressPrefixId"
          :options="shopAddressPrefixOptions"
          :props="Props"
          placeholder="请选择地址前缀"
          @change="handleChange"
          clearable
        >
        </el-cascader>
      </div>
      <div class="new">
        <span>门牌号</span>
        <el-input
          type="text"
          placeholder="请输入门牌号"
          v-model="address"
        ></el-input>
      </div>
      <div class="new">
        <span>停车位</span>
        <el-input
          type="text"
          placeholder="请输入停车位"
          v-model="newStaff.parkingSpace"
        ></el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="small">取 消</el-button>
        <el-button type="primary" :loading="loading" size="small" @click="okBtn"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { addAddress, getPrefixList, updateAddress, deleteAddress, getSelectAddressByCustomerId } from '@/api/customerManagement/customerManagement.js'
export default {
  name: 'Address',
  data() {
    return {
      customerId: parseInt(sessionStorage.getItem('customerId')),

      tableData: [],// 常用地址列表
      diaTitle: 1,
      editShow: false,//新增员工弹窗状态
      loading: false,
      newStaff: {
        address: "",
        createTime: "",
        customerId: null,
        id: null,
        isDefault: 2,
        parkingSpace: "",
        shopAddressPrefixId: null // 店铺前缀id
      }, // 新增地址数据
      address: "",
      Props: {
        value: 'id',
        children: 'children',
        label: 'addressPrefix',
        emitPath: false
      },
      shopAddressPrefixOptions: [], // 地址前缀数据
    }
  },
  created() {
    if (this.customerId) {
      this.getSelectAddressByCustomerId();
      this.getPrefixList();
    }
  },
  methods: {
    // 获取店铺地址前缀
    async getPrefixList() {
      const res = await getPrefixList({ customerId: this.customerId });
      // console.log(res);
      if (res.status === 200) {
        this.shopAddressPrefixOptions = res.data;
      }
    },
    // 级联选择
    handleChange(val) {
      console.log(val);
    },
    // 常用地址列表数据
    async getSelectAddressByCustomerId() {
      const res = await getSelectAddressByCustomerId({ customerId: this.customerId });
      // console.log(res);
      if (res.status === 200) {
        this.tableData = res.data;
      }
    },
    // 新增
    addShow() {
      this.newStaff = {
        address: "",
        createTime: "",
        customerId: this.customerId,
        id: null,
        isDefault: 2,
        parkingSpace: ""
      };
      this.address = "";
      this.diaTitle = 1;
      this.editShow = true;
    },
    // 编辑
    edit(val) {
      // console.log(val);
      this.newStaff = {
        address: val.address,
        createTime: val.createTime,
        customerId: this.customerId,
        id: val.id,
        isDefault: val.isDefault,
        parkingSpace: val.parkingSpace,
        shopAddressPrefixId: val.shopAddressPrefixId,
      };
      this.shopAddressPrefixOptions.forEach(item => {
        item.children.forEach(item1 => {
          if (item1.id === this.newStaff.shopAddressPrefixId) {
            this.address = this.newStaff.address.slice(item.addressPrefix.length + item1.addressPrefix.length);
          }
        })
      })
      this.diaTitle = 2;
      this.editShow = true;
    },
    //删除
    del(val) {
      // console.log('删除', val);
      this.$confirm('确认删除该地址?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // console.log('删除分类', val);
        const res = await deleteAddress({ addressId: parseInt(val.id) });
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '删除成功',
            type: 'success'
          });
          this.editShow = false;
          this.getSelectAddressByCustomerId();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 取消按钮
    cancel() {
      this.editShow = false;
    },
    // 确定按钮
    async okBtn() {
      if (!this.newStaff.shopAddressPrefixId) {
        this.$message({
          showClose: true,
          message: '请选择地址前缀！',
          type: 'warning'
        });
        return;
      }
      if (this.address.replace(/[, ]/g, '') === '') {
        this.$message({
          showClose: true,
          message: '请输入门牌号！',
          type: 'warning'
        });
        return;
      }
      if (this.newStaff.parkingSpace.replace(/[, ]/g, '') === '') {
        this.$message({
          showClose: true,
          message: '请输入停车位！',
          type: 'warning'
        });
        return;
      }
      this.loading = true;
      this.shopAddressPrefixOptions.forEach(item => {
        item.children.forEach(item1 => {
          if (item1.id === this.newStaff.shopAddressPrefixId) {
            this.newStaff.address = item.addressPrefix + item1.addressPrefix + this.address;
          }
        })
      })
      if (this.diaTitle === 1) {
        // console.log(this.newStaff);
        const res = await addAddress(this.newStaff);
        // console.log(res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '新增成功',
            type: 'success'
          });
          this.loading = false;
          this.editShow = false;
          this.getSelectAddressByCustomerId();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
          this.loading = false;
        }
      } else {
        const res = await updateAddress(this.newStaff);
        // console.log(res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '编辑成功',
            type: 'success'
          });
          this.loading = false;
          this.editShow = false;
          this.getSelectAddressByCustomerId();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
          this.loading = false;
        }
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.address {
  width: 100%;
  .staff {
    width: 100%;
    margin-top: 40px;
    overflow: hidden;
    span {
      cursor: pointer;
    }
  }
  ::v-deep .el-button {
    width: 70px;
    height: 30px;
    background: linear-gradient(270deg, #01df6e 0%, #2df891 50%, #01df6e 100%);
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    border: none;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 5px;
    margin-bottom: 20px;
  }
  ::v-deep .el-button--default {
    background: #f4fff5;
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    color: #01df6e;
    margin-right: 20px;
  }
  .new {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    span {
      display: inline-block;
      width: 58px;
      text-align: right;
      margin-right: 10px;
    }
    .el-input {
      width: 80% !important;
    }
    .el-cascader {
      width: 80% !important;
    }
  }
}
</style>