<template>
  <div class="record">
    <div class="searchBar">
      <el-select
        v-model="searchRecordsInfo.stationName"
        placeholder="请选择工位名"
        clearable
      >
        <el-option
          v-for="item in siteName"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-date-picker
        class="mydatapicker"
        v-model="valueTime"
        type="datetimerange"
        @change="changeTime"
        value-format="yyyy-MM-dd HH:mm:ss"
        range-separator="至"
        start-placeholder="查询开始时间"
        end-placeholder="查询结束时间"
      >
      </el-date-picker>

      <el-input
        placeholder="请输入员工名"
        prefix-icon="el-icon-search"
        v-model="searchRecordsInfo.userName"
        clearable
      >
      </el-input>

      <button @click="searchSitesRecords">查询</button>
      <!-- <button @click="addRe()">新增</button> -->
    </div>
    <div class="recordsTable">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="shopStationNumber" label="工位号" width="80">
        </el-table-column>
        <!-- <el-table-column prop="orderNumber" label="工位号" width="180">
            </el-table-column> -->
        <el-table-column prop="type" label="工位类型" width="100">
          <template slot-scope="scope">
            <div v-if="scope.row.type === 1">洗车工位</div>
            <div v-if="scope.row.type === 2">美容工位</div>
          </template>
        </el-table-column>
        <el-table-column prop="orderNumber" label="订单编号" width="170">
        </el-table-column>
        <el-table-column prop="carNumber" label="车牌号" width="100">
        </el-table-column>
        <el-table-column
          prop="orderServiceProjectVos"
          label="服务项目"
          width="170"
        >
          <template slot-scope="scope">
            <span
              v-for="(item, index) in scope.row.orderServiceProjectVos"
              :key="index"
            >
              {{ item.serviceProjectName }}&nbsp;&nbsp;&nbsp;
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="pickUpUserName" label="取钥匙员工" width="120">
        </el-table-column>
        <el-table-column prop="shopStaffName" label="洗车员工A" width="120">
          <template slot-scope="scope">
            <div v-if="scope.row.shopStaffVoList.length > 0">
              {{
                scope.row.shopStaffVoList[0].constructorMark === 1
                  ? scope.row.shopStaffVoList[0].shopStaffName
                  : scope.row.shopStaffVoList[1].shopStaffName
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="shopStaffName" label="洗车员工B" width="120">
          <template slot-scope="scope">
            <div v-if="scope.row.shopStaffVoList.length === 2">
              <span>{{
                scope.row.shopStaffVoList[1].constructorMark === 2
                  ? scope.row.shopStaffVoList[1].shopStaffName
                  : scope.row.shopStaffVoList[0].shopStaffName
              }}</span>
            </div>
            <div v-if="scope.row.shopStaffVoList.length === 1">
              <span>{{ scope.row.shopStaffVoList[0].shopStaffName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="giveKeyUserName" label="送钥匙员工" width="120">
        </el-table-column>
        <el-table-column prop="isDeleted" label="工位是否删除" width="180">
          <template slot-scope="scope">
            <div v-if="scope.row.isDeleted">是</div>
            <div v-else>否</div>
          </template>
        </el-table-column>
        <el-table-column prop="startTime" label="工位开始时间" width="180">
        </el-table-column>
        <el-table-column prop="endTime" label="工位结束时间" width="180">
        </el-table-column>
        <el-table-column prop="workTime" label="工位使用时长(分钟)" width="180">
        </el-table-column>
        <!-- <el-table-column prop="shopStaffJobno" label="工号">
            </el-table-column> -->
        <!-- <el-table-column fixed="right" label="操作">
              <template slot-scope="scope">
                <el-button
                  @click="add(scope.row.id, 2)"
                  type="text"
                  size="small"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="deleteSiteUse(scope.row.id)"
                  >删除</el-button
                >
              </template>
            </el-table-column> -->
      </el-table>
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage1"
          :page-size="8"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getSiteUseRecordsList, getSiteList } from '@/api/myshop/shop.js'
export default {
  name: 'usageRecord',
  components: {},
  props: {},
  data() {
    return {
      siteName: [],
      tableData: [],
      searchRecordsInfo: {
        endTime: "",
        page: 1,
        size: 10,
        shopId: null,
        startTime: "",
        stationName: "",
        userName: ""
      },//搜索工位使用记录参数
      searchSitesInfo: {
        name: '',
        type: null,
        status: null,
        shopId: null
      },//搜索工位参数
      total: 0,
      currentPage1: 1,//当前页码
      valueTime: ''//工位记录时间值
    }
  },
  computed: {},
  watch: {},
  created() {
    if (sessionStorage.getItem('shopId')) {
      this.getSiteUseList(parseInt(sessionStorage.getItem('shopId')))
      this.getSiteList(parseInt(sessionStorage.getItem('shopId')))
    }
  },
  methods: {
    // 获取工位列表信息
    async getSiteList(val) {
      this.searchSitesInfo.shopId = val
      const res = await getSiteList(this.searchSitesInfo)
      // console.log('工位列表信息', res);
      if (res.status === 200) {
        this.siteName = [];
        this.site = res.data
        res.data.map((item) => {
          this.siteName.push({
            label: item.name,
            value: item.name
          })
        })
        // console.log('xuanze', this.siteName);
      }
    },
    // 获取工位使用信息
    async getSiteUseList(val) {
      this.searchRecordsInfo.shopId = val
      const res = await getSiteUseRecordsList({ ...this.searchRecordsInfo })
      // console.log('工位使用记录', res);
      if (res.status === 200) {
        this.tableData = res.data.rows
        this.total = res.data.total
      }
    },
    //翻页
    handleCurrentChange(val) {
      // console.log('当前页', val);
      this.getShopList(val)
    },
    //时间选择框改变
    changeTime(val) {
      // console.log('changge', val);
      if (val !== null) {
        this.searchRecordsInfo.startTime = val[0]
        this.searchRecordsInfo.endTime = val[1]
      } else {
        this.searchRecordsInfo.startTime = ''
        this.searchRecordsInfo.endTime = ''
      }
    },
    // 条件搜索工位使用记录信息信息
    searchSitesRecords() {
      this.searchRecordsInfo.page = 1;
      // console.log('搜索工位使用记录信息', this.searchRecordsInfo);
      this.getSiteUseList(sessionStorage.getItem('shopId'))
    },
    // 删除工位使用记录
    async deleteSiteUse(val) {
      this.$confirm('是否删除当前工位使用记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await delSiteUseRecords({ ShopStationRecordId: val })
        // console.log('del', res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            type: 'success',
            message: '删除成功!'
          });
          this.getSiteUseList(sessionStorage.getItem('shopId'))
        } else {
          this.$message({
            showClose: true,
            type: 'info',
            message: '删除失败!' + res.msg
          });
        }
      }).catch(() => {
        this.$message({
          showClose: true,
          type: 'info',
          message: '取消删除'
        });
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.record {
  width: 100%;
  min-height: 300px;
  margin-top: 30px;
  .block {
    text-align: center;
    padding-top: 20px;
  }
  p {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #313131;
    line-height: 20px;
  }
  .recordsTable {
    width: 100%;
    height: 100%;
  }
  ::v-deep .el-button {
    width: 70px;
    height: 30px;
    line-height: 5px;
  }
  .searchBar {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    display: flex;

    .el-input {
      width: 160px !important;
      margin-right: 10px !important;
      margin-left: 10px;
    }
    .el-select {
      width: 160px !important;
      height: 30px !important;
      margin-right: 10px;
    }
    button {
      width: 70px;
      height: 30px;
      background: linear-gradient(
        270deg,
        #01df6e 0%,
        #2df891 50%,
        #01df6e 100%
      );
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      line-height: 30px;
      color: #ffffff;
      cursor: pointer;
      margin-right: 10px;
    }
  }
  .searchBar::v-deep .el-input__icon {
    line-height: 30px !important;
  }
  .searchBar::v-deep .el-input__inner {
    height: 30px !important;
  }
  .searchBar::v-deep .el-date-editor .el-range-separator {
    line-height: 21px !important;
  }
}
</style>
