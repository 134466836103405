<template>
  <!-- 机构客户详情 -->
  <div class="customerDetail">
    <div class="shopDetail">
      <el-form ref="form" :model="formData" label-width="100px">
        <el-form-item label="机构管理员">
          <el-select
            v-model="formData.adminId"
            placeholder="请选择机构管理员"
            clearable
            filterable
          >
            <el-option
              v-for="item in customerList"
              :key="item.id"
              :label="item.userName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机构名称">
          <el-input
            v-model="formData.name"
            placeholder="请输入机构名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="注册时间" v-if="checkEdit === 'edit'">
          <el-input v-model="formData.createTime" disabled></el-input>
        </el-form-item>
        <!-- <el-form-item label="车辆数" v-if="checkEdit === 'edit'">
          <el-input
            v-model="formData.carIds.length"
            placeholder="请输入车辆数"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="机构车辆数">
          <el-input
            v-model="formData.carCount"
            placeholder="请输入机构车辆数"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="titleBox">
            <div class="div1Box">车牌号</div>
            <div class="div2Box">车型</div>
            <el-button
              class="btnBox"
              type="warning"
              size="mini"
              @click="addSite"
              >添加</el-button
            >
          </div>
          <div v-if="this.formData.carIds.length !== 0">
            <div
              class="contentBox"
              v-for="(item, index) in this.formData.carIds"
              :key="index"
            >
              <div class="div1Box div1Box2">
                <el-input
                  v-model="item.carNumber"
                  placeholder="请输入车牌号"
                  maxlength="8"
                  style="border: none; width: 90%; height: 90%; padding: 0"
                />
              </div>
              <div class="div1Box2 div2Box">
                <el-cascader
                  v-model="item.carClassificationId"
                  :props="defaultProps"
                  :options="options"
                  @change="handleChange"
                  style="border: none; width: 90%; height: 90%; padding: 0"
                ></el-cascader>
              </div>
            </div>
          </div>
          <div class="contentBox1" v-else>暂无信息</div>
        </el-form-item>

        <!-- <el-form-item label="是否会员">
          <el-switch
            v-model="formData.isVip"
            active-color="#5F6679"
            :active-value="1"
            :inactive-value="2"
            inactive-color="#B3B3B3"
          ></el-switch>
        </el-form-item> -->

        <el-form-item label="关联会员">
          <el-select
            v-model="formData.memberId"
            placeholder="请选择关联会员"
            clearable
          >
            <el-option
              v-for="item in vipOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item
          label="开通会员时间"
          v-if="formData.isVip === 1 && checkEdit === 'edit'"
        >
          <el-input
            disabled
            v-model="formData.vipTime"
            placeholder="请输入开通会员时间"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="会员到期时间"
          v-if="formData.isVip === 1 && checkEdit === 'edit'"
        >
          <el-input
            disabled
            v-model="formData.vipTime"
            placeholder="请输入会员到期时间"
          ></el-input>
        </el-form-item> -->

        <el-form-item label="余额" v-if="checkEdit === 'edit'">
          <div class="moneySumBox">
            <el-input
              disabled
              type="number"
              v-model="formData.balance"
              placeholder="请输入余额"
            ></el-input>
            <el-button
              class="btnBox"
              type="warning"
              size="mini"
              @click="moneySumBtn"
              >余额变更</el-button
            >
          </div>
        </el-form-item>
        <!-- <el-form-item label="消费总金额" v-if="checkEdit === 'edit'">
          <el-input
            v-model="formData.moneySum"
            placeholder="请输入消费总金额"
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="积分余额" v-if="checkEdit === 'edit'">
          <div class="moneySumBox">
            <el-input
              disabled
              type="number"
              v-model="formData.integral"
              placeholder="请输入积分"
            ></el-input>
            <el-button
              class="btnBox"
              type="warning"
              size="mini"
              @click="moneySumBtn1"
              >积分变更</el-button
            >
          </div>
        </el-form-item> -->
        <el-form-item>
          <el-button class="cancelBox" @click="cancel">取消</el-button>
          <el-button type="primary" :loading="loading" @click="onSubmit"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <!-- 余额变更弹窗 -->
    <el-dialog
      title="余额变更"
      :visible.sync="moneySumShow"
      width="40%"
      :show-close="false"
    >
      <div class="new">
        <span>余额变更</span>
        <el-input
          type="text"
          maxlength="10"
          placeholder="请输入余额变更金额"
          v-model="balanceData.balance"
        ></el-input>
      </div>
      <div class="new">
        <span>变更原因</span>
        <el-input
          type="text"
          maxlength="50"
          placeholder="请输入变更原因"
          v-model="balanceData.applyReason"
        ></el-input>
      </div>
      <div class="new">
        <span>凭证号</span>
        <el-input
          type="text"
          maxlength="20"
          placeholder="请输入凭证号"
          v-model="balanceData.voucherNumber"
        ></el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="moneyCancel" size="small">取 消</el-button>
        <el-button type="primary" :loading="loading" size="small" @click="okBtn"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 积分变更弹窗 -->
    <!-- <el-dialog
      title="积分变更"
      :visible.sync="integralShow"
      width="40%"
      :show-close="false"
    >
      <div class="new">
        <span>积分变更</span>
        <el-input
          type="text"
          maxlength="10"
          placeholder="请输入积分变更数值"
          v-model="integralData.integral"
        ></el-input>
      </div>
      <div class="new">
        <span>变更原因</span>
        <el-input
          type="text"
          maxlength="50"
          placeholder="请输入变更原因"
          v-model="integralData.applyReason"
        ></el-input>
      </div>
      <div class="new">
        <span>凭证号</span>
        <el-input
          type="text"
          maxlength="20"
          placeholder="请输入凭证号"
          v-model="integralData.voucherNumber"
        ></el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="moneyCancel1" size="small">取 消</el-button>
        <el-button
          type="primary"
          :loading="loading"
          size="small"
          @click="okBtn1"
          >确 定</el-button
        >
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import {
  addMechanism, getSelectAllCustomer, getSelectCarClass, editMechanism, getMechanismDetails, mechanismBalance, getMember
} from '@/api/mechanismCustomerManagement/mechanismCustomerManagement.js'
export default {
  name: 'customerDetail',
  data() {
    return {
      moneySumShow: false,
      balanceData: {
        balance: null, // 余额
        id: parseInt(sessionStorage.getItem('mechanismId')),
        applyReason: '', // 申请原因
        voucherNumber: '', // 凭证号
      }, // 余额变更数据
      // integralShow: false,
      // integralData: {
      //   integral: null, // 积分
      //   mechanismId: parseInt(sessionStorage.getItem('mechanismId')),
      //   applyReason: '', // 申请原因
      //   voucherNumber: '', // 凭证号
      // }, // 积分变更数据

      // -----------------------------
      checkEdit: sessionStorage.getItem('checkEdit'),
      token: sessionStorage.getItem('token'),
      mechanismId: parseInt(sessionStorage.getItem('mechanismId')), // 机构id
      defaultProps: {
        value: 'id',
        children: 'children',
        label: 'name',
        emitPath: false
      },
      loading: false,
      formData: {
        carIds: [
          {
            carClassificationId: null,
            carNumber: "",
            isMechanism: 1,
            carId: null
          }
        ],
        balance: 0, // 余额
        adminId: null, // 机构管理人id，关联customer表
        memberId: null, // 关联会员表
        createTime: '', // 机构注册时间
        carCount: null, // 车辆数量
        name: "", // 公司名称
      },
      options: [], // 车型列表
      vipOptions: [],
      customerList: [],
    }
  },
  created() {
    this.getSelectCarClass();
    this.getSelectAllCustomer();
    this.getMember();
    console.log(this.mechanismId);
    if (this.mechanismId) {
      this.getMechanismDetails();
    }
  },
  methods: {
    // 机构详情
    async getMechanismDetails() {
      const res = await getMechanismDetails({ mechanismId: parseInt(this.mechanismId) });
      // console.log(res);
      if (res.status === 200) {
        this.formData = {
          carIds: res.data.mechanismCarVoList,
          adminId: res.data.mechanismAdminId,
          carCount: res.data.carCount,
          name: res.data.name,
          id: res.data.id,
          balance: res.data.balance ? res.data.balance : 0,
          memberId: res.data.memberId,
          createTime: res.data.createTime,
        };
      }
      console.log(this.formData);
    },
    // 获取客户列表数据 
    async getSelectAllCustomer() {
      const res = await getSelectAllCustomer();
      // console.log(res);
      if (res.status === 200) {
        this.customerList = res.data;
      }
    },

    // 车辆分类数据
    async getSelectCarClass() {
      const res = await getSelectCarClass();
      // console.log(res);
      if (res.status === 200) {
        this.options = res.data;
      }
      // console.log(this.options);
      this.options.forEach(item => {
        if (item.children.length === 0) {
          item.children = undefined;
        } else {
          item.children.forEach(item1 => {
            if (item1.children.length === 0) {
              item1.children = undefined;
            }
          })
        }
      });
    },
    // 获取会员类型列表
    async getMember() {
      const res = await getMember(2);
      console.log(res);
      if (res.status === 200) {
        this.vipOptions = res.data;
      }
    },
    // 级联选择
    handleChange(val) {
      console.log(val);
    },
    // 提交信息
    async onSubmit() {
      const newArr = this.formData.carIds.map(item => item.carNumber);
      const isRepeat = newArr.some((item, index, arr) => arr.indexOf(item) != index);
      if (isRepeat) {
        return this.$message({
          showClose: true,
          message: '不能添加相同的车牌！',
          type: 'warning'
        });
      }
      this.loading = true;
      if (this.checkEdit === 'add') {
        // console.log(this.formData);
        const res = await addMechanism(this.formData);
        // console.log(res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '新增成功',
            type: 'success'
          });
          this.loading = false;
          this.cancel()
          // sessionStorage.setItem('mechanismId', res.data);
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
          this.loading = false;
        }
      } else {
        const res = await editMechanism(this.formData);
        console.log(res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '编辑成功',
            type: 'success'
          });
          this.loading = false;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
          this.loading = false;
        }
      }
    },
    // 取消按钮
    cancel() {
      this.$router.push('/home/mechanismCustomerManagement')
    },
    // 添加车辆
    addSite() {
      if (this.formData.carIds.length >= this.formData.carCount) {
        return this.$message({
          showClose: true,
          message: '车辆数量超出上限！',
          type: 'warning'
        });
      }
      this.formData.carIds.push(
        {
          carClassificationId: null,
          carNumber: "",
          isMechanism: 1,
          carId: null
        }
      )
    },
    // 余额变更 
    moneySumBtn() {
      this.moneySumShow = true;
    },
    // 弹层取消按钮
    moneyCancel() {
      this.moneySumShow = false;
    },
    // 弹层确定按钮
    async okBtn() {
      console.log(this.balanceData);
      const res = await mechanismBalance(this.balanceData);
      console.log(res);
      if (res.status === 200) {
        this.$message({
          showClose: true,
          message: '余额变更成功',
          type: 'success'
        });
        this.getMechanismDetails();
        this.balanceData = {
          balance: null, // 余额
          id: parseInt(sessionStorage.getItem('mechanismId')),
          applyReason: '', // 申请原因
          voucherNumber: '', // 凭证号
        };
        this.moneySumShow = false;
      } else {
        this.$message({
          showClose: true,
          message: res.msg,
          type: 'error'
        });
      }
    },
    // 积分变更 
    // moneySumBtn1() {
    //   this.integralShow = true;
    // },
    // 弹层取消按钮
    // moneyCancel1() {
    //   this.integralShow = false;
    // },
    // 弹层确定按钮
    // async okBtn1() {
    //   const res = await customerIntegralChange(this.integralData);
    //   console.log(res);
    //   if (res.status === 200) {
    //     this.$message({
    //       showClose: true,
    //       message: '积分变更成功',
    //       type: 'success'
    //     });
    //     this.getMechanismDetails();
    //     this.integralData = {
    //       integral: null, // 积分
    //       mechanismId: parseInt(sessionStorage.getItem('mechanismId')),
    //       applyReason: '', // 申请原因
    //       voucherNumber: '', // 凭证号
    //     };
    //     this.integralShow = false;
    //   } else {
    //     this.$message({
    //       showClose: true,
    //       message: res.msg,
    //       type: 'error'
    //     });
    //   }
    // },

  }
}
</script>

<style lang='scss' scoped>
.customerDetail {
  width: 100%;
  min-height: 300px;
  ::v-deep .el-button {
    width: 70px;
    height: 30px;
    background: linear-gradient(270deg, #01df6e 0%, #2df891 50%, #01df6e 100%);
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    border: none;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 5px;
    margin-bottom: 20px;
  }
  ::v-deep .el-button--default {
    background: #f4fff5;
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    color: #01df6e;
    margin-right: 20px;
  }
  .new {
    // display: flex;
    // align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    span {
      display: inline-block;
      width: 58px;
      text-align: right;
      margin-right: 10px;
    }
    .newText {
      width: 100%;
      margin-bottom: 10px;
    }
    .el-input {
      width: 80% !important;
    }
    .conItem {
      display: flex;
      margin-bottom: 10px;

      .uploadBox {
        position: relative;
        width: 65px;
        height: 65px;
        background: #ffffff;
        border: 1px solid #979797;
        border-radius: 5px;
        margin-right: 21px;

        .avatar-uploader .el-upload {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }

        .avatar-uploader .el-upload:hover {
          border-color: #409eff;
        }

        .avatar-uploader-icon {
          display: block;
          font-style: normal;
          font-size: 24px;
          color: #8c939d;
          width: 65px;
          height: 65px;
          line-height: 65px;
          text-align: center;
        }

        .avatar {
          width: 65px;
          height: 65px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .colse {
          cursor: pointer;
          position: absolute;
          top: -9px;
          right: -9px;
          width: 18px;
          height: 18px;
          line-height: 18px;
          text-align: center;
          font-size: 12px;
          border-radius: 9px;
          background: #5f6679;
          color: #fff;
          z-index: 999;
        }
      }

      .inputBox {
        flex: 1;
        position: relative;
        // width: 380px;
        // height: 65px;
        // background: #ffffff;
        // border: 1px solid #cccccc;
        .delIconBox {
          cursor: pointer;
          position: absolute;
          top: 15px;
          right: 10px;
          width: 34px;
          height: 34px;
          border-radius: 17px;
          line-height: 34px;
          text-align: center;
          background: #f0f2f6;
        }

        ::v-deep.el-textarea {
          width: 92%;
          height: 65px !important;

          .el-textarea__inner {
            width: 92%;
            height: 65px !important;
            // border: none;
            // border-radius: 0;
            resize: none; /* 这个是去掉 textarea 下面拉伸的那个标志，如下图 */
            padding-right: 50px;
          }
        }
      }
    }
  }
  .shopDetail {
    width: 520px;
    padding-top: 20px;
    .el-myselect {
      width: 100%;
    }
    .el-mytime {
      width: 108px;
    }
    .el-select {
      width: 100%;
    }
    .carid {
      width: 100%;
      height: 40px;
      line-height: 40px;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7d7d7d;
      span {
        cursor: pointer;
        margin: 0 8px;
      }
    }
    .titleBox {
      position: relative;
    }
    .titleBox,
    .contentBox {
      display: flex;
      div {
        // flex: 1;
        // width: 260px;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #7d7d7d;
        background: #ffffff;
        padding-left: 10px;
        box-sizing: border-box;
        border: 1px solid #dddddd;
        ::v-deep.el-input__inner {
          width: 100%;
          height: 100%;
          border: none;
        }
      }
      .div1Box {
        flex: 1;
        border-right: none;
      }
      .div2Box {
        flex: 2;
      }
      .div1Box2 {
        border-top: none;
      }
      .btnBox {
        position: absolute;
        top: 3px;
        right: -90px;
        min-width: 80px;
        height: 30px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        background: linear-gradient(
          270deg,
          #e7710d 0%,
          #f6903a 50%,
          #e7710d 100%
        );
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
      }
    }
    .moneySumBox {
      position: relative;
      .btnBox {
        position: absolute;
        top: 5px;
        right: -90px;
        min-width: 80px;
        height: 30px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        background: linear-gradient(
          270deg,
          #e7710d 0%,
          #f6903a 50%,
          #e7710d 100%
        );
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
      }
    }
    .contentBox1 {
      // width: 520px;
      width: 100%;
      height: 36px;
      line-height: 36px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #dddddd;
      box-sizing: border-box;
      border: 1px solid #dddddd;
      border-top: none;
    }
  }
}
</style>