<template>
  <div class="menu">
    <!-- 菜单 -->
    <el-menu
      class="el-menu-vertical-demo"
      :default-active="routerPath()"
      :router="true"
      background-color="#232932"
      width="200px"
      :unique-opened="true"
      text-color="#fff"
      active-text-color="#01DF6E"
    >
      <template v-for="(item, index) in menuData">
        <el-submenu
          :key="index"
          v-if="item.children && item.children.length > 0"
          :index="item.title"
        >
          <template slot="title">
            <span class="menu-text">{{ item.title }}</span>
          </template>
          <el-menu-item
            v-for="(ele, index) in item.children"
            :key="index"
            :index="ele.path"
          >
            <span slot="title" class="menu-text">{{ ele.title }}</span>
          </el-menu-item>
        </el-submenu>
        <el-menu-item
          :key="index"
          :index="item.path"
          :disabled="!item.path"
          v-else
        >
          <span slot="title" @click="onSelect(item)" class="menu-text">{{
            item.title
          }}</span>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuData: [
        { id: 1, title: '店铺管理', path: '/home/shopManagement' },
        // {
        //   id: 2, title: '用户管理', path: '/home/staffManagement', children: [
        //     { id: 4, title: '店铺用户', path: '/home/shopUsers' },
        //     { id: 5, title: '系统用户', path: '/home/systemUsers' }
        //   ]
        // },
        {
          id: 2, title: '员工管理', path: '/home/shopUsers', children: [
            { id: 4, title: '员工列表', path: '/home/shopUsers' },
            { id: 5, title: '员工绩效', path: '/home/achievements' }
          ]
        },
        {
          id: 3, title: '车辆管理', path: '/home/carManagemengt', children: [
            { id: 4, title: '车辆列表', path: '/home/carCategary' },
            { id: 5, title: '车辆分类', path: '/home/carClassification' }
          ]
        },
        {
          id: 6, title: '订单管理', path: '/home/orderManagement'
        },
        { id: 7, title: '评价管理', path: '/home/evaluationManagement' },
        // {id: 8, title: '客户管理', path: '/home/customerManagement'},
        {
          id: 8, title: '客户管理', path: '/home/customerManagements', children: [
            { id: 81, title: '个人客户管理', path: '/home/customerManagement' },
            // {id: 82, title: '机构客户管理', path: '/home/mechanismCustomerManagement'},
          ]
        },
        {
          id: 9, title: '会员管理', path: '/home/memberManagement', children: [
            { id: 10, title: '会员列表', path: '/home/memberManagement' },
            { id: 11, title: '现金券管理', path: '/home/couponManagement' },
            { id: 12, title: '积分配置', path: '/home/integralConfigureManagement' },
            // {id: 17, title: '测试webSocket', path: '/home/testWebSocket'}
          ]
        },
        {
          id: 13, title: '服务管理', path: '/home/goodsManagement', children: [
            { id: 17, title: '商品管理', path: '/home/goodsManagement' },
            { id: 18, title: '商品类别管理', path: '/home/goodsCategoryManagement' },
            { id: 19, title: '附加商品管理', path: '/home/additionalGoodsManagement' },
          ]
        },
        {
          id: 14, title: '推广管理', path: '/home/extensionManagement', children: [
            { id: 20, title: '分享管理', path: '/home/extensionManagement' },
            { id: 21, title: '配置管理', path: '/home/sharConfiguration' },
          ]
        },
        // { id: 15, title: '统计分析', path: '/home/statisticalAnalysis' },
        // { id: 16, title: '操作日志', path: '/home/logs' },
      ]
    }
  },
  methods: {
    routerPath() {
      // console.log(this.$route.path)
      if ((this.$route.path).indexOf('addShop') !== -1) {
        return '/home/shopManagement'
      } else if ((this.$route.path).indexOf('addStaff') !== -1) {
        return '/home/shopUsers'
      } else if ((this.$route.path).indexOf('detailTabs') !== -1) {
        return '/home/customerManagement'
      } else if ((this.$route.path).indexOf('goodsManagement') !== -1) {
        return '/home/goodsManagement'
      } else if ((this.$route.path).indexOf('orderTabs') !== -1) {
        return '/home/orderManagement'
      } else {
        return this.$route.path
      }
    },
    onSelect() {
    }
  },
  created() {
    // console.log(this.routerPath('/shopManagement'));
  }
}
</script>

<style scoped lang="scss">
.menu {
  box-shadow: 2px 0px 2px 0px rgba(3, 31, 48, 0.17);

  .el-menu-item.is-active {
    background: #fff !important;
    // background-color: var(--primary);
  }

  ::v-deep.el-menu-vertical-demo.el-menu {
    border: 0px !important;
  }
  ::v-deep.el-menu-item:hover {
    background-color: #01df6e !important;
  }
  ::v-deep.el-submenu__title:hover {
    background-color: #01df6e !important;
  }
  ::v-deep.el-menu-item:hover.is-active {
    background-color: #fff !important;
  }

  ::v-deep.el-submenu__title i {
    color: white !important;
  }
}
</style>