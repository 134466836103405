<template>
  <div class="orderDetails">
    <div class="shopDetail">
      <div class="detailTop">
        <div class="topItem topItem1">
          <span class="itemName">订单编号：</span>
          <span class="itemContent">{{ orderInfo.orderNumber }}</span>
        </div>
        <div class="topItem topItem1">
          <span class="itemName">创建时间：</span>
          <span class="itemContent">{{ orderInfo.createTime }}</span>
        </div>
        <div class="topItem">
          <el-button @click="orderAbnormal">{{
            orderInfo.isTemporaryEnd === 1 ? "订单异常" : "订单正常"
          }}</el-button>
        </div>
      </div>

      <div class="courseTitle">
        <p class="p1">订单流程</p>
        <!-- <el-button style="margin-top: 12px" @click="next">下一步</el-button> -->
      </div>
      <div class="courseBox" :class="flow.length === 14 ? 'courseBox1' : ''">
        <div
          style="pointer-events: none"
          :class="flow.length === 15 ? 'courseBox2' : ''"
        >
          <el-steps :active="active" finish-status="success" align-center>
            <el-step
              v-for="(item, index) in flow"
              :key="index"
              :title="item.title"
              @click.native="show(index)"
              :dataindex="item.id"
              :ref="item.ref"
            ></el-step>
          </el-steps>
        </div>
        <!-- 1-1 到店洗-自提车-->
        <div class="content" v-if="flow && flow.length === 11">
          <div class="horn" ref="horn"></div>
          <div class="btnBox" v-if="active === 1 && orderInfo.status === 2">
            <el-button @click="orderReceiving">接单</el-button>
          </div>
          <div class="btnBox" v-if="active === 2 && orderInfo.status === 3">
            <el-button @click="customerConfirmsArrival">客户到达现场</el-button>
          </div>

          <div class="btnBox" v-if="active === 3 && orderInfo.status === 21">
            <el-button @click="vehicleInspectionBtn">车辆检查</el-button>
          </div>
          <div class="btnBox" v-if="active === 4">
            <el-button @click="customerConfirm">客户确认</el-button>
          </div>
          <div class="btnBox" v-if="active === 5">
            <el-button @click="takeKeyBtn(orderInfo)">洗车分配</el-button>
          </div>
          <!--          <div class="btnBox" v-if="active === 5 && orderInfo.status === 6">-->
          <!--            <el-button @click="carWashBtn">分配</el-button>-->
          <!--            &lt;!&ndash; <el-button v-else @click="serviceBtn">填写服务清单</el-button> &ndash;&gt;-->
          <!--          </div>-->
          <div class="btnBox" v-if="active === 6 && orderInfo.status === 5">
            <el-button @click="start">开工</el-button>
          </div>
          <div class="btnBox" v-if="active === 7 && orderInfo.status === 10">
            <el-button
              class="buttonBox"
              v-if="orderInfo.isAdditionalGoods"
              @click="additionalGoodsBtn"
              >附加商品</el-button
            >
            <el-button @click="end">完工</el-button>
          </div>
          <div class="btnBox" v-if="active === 8">
            <el-button @click="pickUp(orderInfo)">扫码取车</el-button>
          </div>
          <div class="btnBox" v-if="active === 9">
            <el-button @click="confirm(orderInfo)">客户车已开走</el-button>
          </div>
          <div class="btnBox" v-if="active === 10">
            <!-- <el-button @click="orderEnd">结束订单</el-button> -->
          </div>
          <!--          <div class="btnBox" v-if="active === 6">-->
          <!--            <el-button @click="giveKeyBtn">分配</el-button>-->
          <!--          </div>-->
          <!--          <div class="btnBox" v-if="active === 7">-->
          <!--            <el-button-->
          <!--                v-if="parkingAddressType === 1"-->
          <!--                @click="parkingAddressBtn"-->
          <!--            >填写停车地址-->
          <!--            </el-button-->
          <!--            >-->
          <!--            <el-button v-else>确定</el-button>-->
          <!--          </div>-->
        </div>

        <!-- 1-2 到店洗车-送钥匙-->
        <div class="content" v-if="flow && flow.length === 13">
          <div class="horn" ref="horn"></div>
          <div class="btnBox" v-if="active === 1 && orderInfo.status === 2">
            <el-button @click="orderReceiving">接单</el-button>
          </div>
          <div class="btnBox" v-if="active === 2 && orderInfo.status === 3">
            <el-button @click="customerConfirmsArrival">客户到达现场</el-button>
          </div>
          <div class="btnBox" v-if="active === 3 && orderInfo.status === 21">
            <el-button @click="vehicleInspectionBtn">车辆检查</el-button>
          </div>
          <div class="btnBox" v-if="active === 4">
            <el-button @click="customerConfirm">客户确认</el-button>
          </div>

          <div class="btnBox" v-if="active === 5">
            <el-button @click="takeKeyShow = true">洗车分配</el-button>
          </div>
          <div class="btnBox" v-if="active === 6">
            <el-button @click="start">开工</el-button>
          </div>
          <div class="btnBox" v-if="active === 7">
            <el-button
              class="buttonBox"
              v-if="orderInfo.isAdditionalGoods"
              @click="additionalGoodsBtn"
              >附加商品</el-button
            >
            <el-button @click="end">完工</el-button>
          </div>
          <div class="btnBox" v-if="active === 8">
            <el-button @click="giveKeyShow = true">送钥匙分配</el-button>
          </div>
          <div class="btnBox" v-if="active === 9">
            <el-button @click="parkingAddressShow = true"
              >上传停车地址</el-button
            >
          </div>
          <div class="btnBox" v-if="active === 10">
            <el-button @click="confirmArrival">确认到达</el-button>
          </div>
          <div class="btnBox" v-if="active === 11">
            <el-button @click="keyScanning(orderInfo)">送钥匙扫码</el-button>
          </div>
        </div>

        <!-- 2-1 取钥匙-到店提车-->
        <div class="content" v-if="flow && flow.length === 14">
          <div class="horn" ref="horn"></div>
          <div class="btnBox" v-if="active === 1 && orderInfo.status === 2">
            <el-button @click="orderReceiving">接单</el-button>
          </div>
          <div class="btnBox" v-if="active === 2 && orderInfo.status === 3">
            <el-button @click="takeKeyBtn(orderInfo)">分配</el-button>
          </div>
          <div class="btnBox" v-if="active === 3 && orderInfo.status === 4">
            <el-button @click="pickUpUserToTheStore">取钥匙到达</el-button>
          </div>
          <div class="btnBox" v-if="active === 4 && orderInfo.status === 6">
            <el-button @click="keyScanningCode(orderInfo)"
              >取钥匙扫码</el-button
            >
          </div>
          <div class="btnBox" v-if="active === 5 && orderInfo.status === 7">
            <el-button @click="vehicleInspectionBtn">车辆检查</el-button>
          </div>
          <div class="btnBox" v-if="active === 6">
            <el-button @click="customerConfirm">客户确认</el-button>
          </div>
          <div class="btnBox" v-if="active === 7">
            <el-button @click="start">开工</el-button>
          </div>
          <div class="btnBox" v-if="active === 8 && orderInfo.status === 10">
            <el-button
              class="buttonBox"
              v-if="orderInfo.isAdditionalGoods"
              @click="additionalGoodsBtn"
              >附加商品</el-button
            >
            <el-button @click="end">完工</el-button>
          </div>
          <div class="btnBox" v-if="active === 9 && orderInfo.status === 11">
            <el-button @click="waitPickUp">客户到店</el-button>
          </div>
          <div class="btnBox" v-if="active === 10">
            <el-button @click="pickUp(orderInfo)">扫码取车</el-button>
          </div>
          <div class="btnBox" v-if="active === 11 && orderInfo.status === 17">
            <el-button @click="confirm(orderInfo)">客户车已开走</el-button>
          </div>
          <div class="btnBox" v-if="active === 12 && orderInfo.status === 18">
            <el-button @click="orderEnd">订单结束</el-button>
          </div>
        </div>

        <!-- 2-2 取钥匙-送钥匙-->
        <div class="content" v-if="flow && flow.length === 15">
          <div class="horn" ref="horn"></div>
          <div class="btnBox" v-if="active === 1 && orderInfo.status === 2">
            <el-button @click="orderReceiving">接单</el-button>
          </div>
          <div class="btnBox" v-if="active === 2 && orderInfo.status === 3">
            <el-button @click="takeKeyBtn(orderInfo)">分配</el-button>
          </div>
          <div class="btnBox" v-if="active === 3 && orderInfo.status === 4">
            <el-button @click="pickUpUserToTheStore">取钥匙到达</el-button>
          </div>
          <div class="btnBox" v-if="active === 4 && orderInfo.status === 6">
            <el-button @click="keyScanningCode(orderInfo)"
              >取钥匙扫码</el-button
            >
          </div>
          <!--          <div class="btnBox" v-if="active === 4">-->
          <!--            <el-button @click="vehicleInspectionBtn">上传</el-button>-->
          <!--          </div>-->
          <div class="btnBox" v-if="active === 5 && orderInfo.status === 7">
            <el-button @click="vehicleInspectionBtn">车辆检查</el-button>
          </div>
          <div class="btnBox" v-if="active === 6 && orderInfo.status === 8">
            <el-button @click="customerConfirm">客户确认</el-button>
          </div>
          <div class="btnBox" v-if="active === 7">
            <el-button @click="start">开工</el-button>
          </div>
          <div class="btnBox" v-if="active === 8 && orderInfo.status === 10">
            <el-button
              class="buttonBox"
              v-if="orderInfo.isAdditionalGoods"
              @click="additionalGoodsBtn"
              >附加商品</el-button
            >
            <el-button @click="end">完工</el-button>
          </div>
          <div class="btnBox" v-if="active === 9 && orderInfo.status === 11">
            <el-button @click="giveKeyShow = true">送钥匙分配</el-button>
          </div>
          <div class="btnBox" v-if="active === 10 && orderInfo.status === 12">
            <el-button @click="parkingAddressShow = true"
              >上传停车地址</el-button
            >
          </div>
          <div class="btnBox" v-if="active === 11 && orderInfo.status === 13">
            <el-button @click="confirmArrival">确认到达</el-button>
          </div>
          <div class="btnBox" v-if="active === 12 && orderInfo.status === 14">
            <el-button @click="keyScanning(orderInfo)">送钥匙扫码</el-button>
          </div>
          <div class="btnBox" v-if="active === 13 && orderInfo.status === 15">
            <el-button @click="orderEnd">订单结束</el-button>
          </div>
          <!--          <div class="btnBox" v-if="active === 10">-->
          <!--            <el-button-->
          <!--                v-if="parkingAddressType === 1"-->
          <!--                @click="parkingAddressBtn"-->
          <!--            >填写停车地址-->
          <!--            </el-button-->
          <!--            >-->
          <!--            <el-button v-else>确定</el-button>-->
          <!--          </div>-->
        </div>
      </div>

      <!-- 基础信息 -->
      <div class="courseBot">
        <div class="botLeft">
          <div class="text">
            <p class="p1">服务信息</p>
            <div class="basic">
              <span class="title">门店：</span>
              <span class="info">{{ orderInfo.shopName }}</span>
            </div>
            <div class="basic">
              <span class="title">服务项目：</span>
              <span
                class="info"
                v-for="(item, index) in orderInfo.orderServiceProjectVos"
                :key="index"
                >{{ item.serviceProjectName }}&nbsp;&nbsp;&nbsp;</span
              >
            </div>
            <div class="basic">
              <span class="title">服务方式：</span>
              <span class="info" v-if="orderInfo.serviceModel === 1"
                >到店洗车</span
              >
              <span class="info" v-if="orderInfo.serviceModel === 2"
                >上门取钥匙</span
              >
            </div>
            <!-- <div class="basic">
              <span class="title">消费金额：</span>
              <span class="info">￥{{ orderInfo.fineMoney }}.00</span>
            </div> -->
          </div>

          <div class="text">
            <p class="p1">取钥匙信息</p>
            <div class="basic">
              <span class="title">服务方式：</span>
              <span class="info" v-if="orderInfo.serviceModel === 1"
                >到店洗车</span
              >
              <span class="info" v-if="orderInfo.serviceModel === 2"
                >上门取钥匙</span
              >
            </div>
            <!-- <div class="basic">
              <span class="title">取车时间：</span>
              <span class="info">{{ orderInfo.takeKeyTime }}</span>
            </div> -->
            <div class="basic">
              <span class="title">取车地址：</span>
              <span class="info" v-if="orderInfo.takeKeyAddress !== null">{{
                orderInfo.takeKeyAddress
              }}</span>
              <span class="info" v-else>暂无</span>
            </div>
            <div class="basic">
              <span class="title">停车位信息：</span>
              <span class="info" v-if="orderInfo.parkingSpace !== null">{{
                orderInfo.parkingSpace
              }}</span>
              <span class="info" v-else>暂无</span>
            </div>
          </div>

          <div class="text">
            <div class="p1">
              车辆检查
              <!-- <el-button type="primary">编辑</el-button> -->
            </div>
            <div class="basic">
              <div
                class="basicBox"
                v-if="
                  orderInfo.orderPhotoDtoList &&
                  orderInfo.orderPhotoDtoList.length > 0
                "
              >
                <div
                  class="basicItem"
                  v-for="(item, index) in this.orderInfo.orderPhotoDtoList"
                  :key="index"
                >
                  <div class="itemLeft">
                    <img :src="item.photoUrl" alt="" />
                  </div>
                  <div class="itemRight">{{ item.photoExplain }}</div>
                </div>
              </div>
              <div class="basicBox" v-else>暂无</div>
            </div>
          </div>

          <div class="text">
            <p class="p1">送钥匙信息</p>
            <div class="basic">
              <span class="title">取车方式：</span>
              <span class="info">{{
                orderInfo.pickUp === 1 ? "到店自取" : "完工送钥匙"
              }}</span>
            </div>
            <div class="basic">
              <span class="title">送钥匙时间：</span>
              <span class="info" v-if="orderInfo.giveKeyTimeType === 1"
                >洗完通知</span
              >
              <span class="info" v-else>{{ orderInfo.giveKeyTime }}</span>
              <!--              <span class="changeBox">用户已更改</span>-->
            </div>
            <div class="basic">
              <span class="title">送钥匙地址：</span>
              <span class="info" v-if="orderInfo.giveKeyAddress !== null">{{
                orderInfo.giveKeyAddress
              }}</span>
              <span class="info" v-else>暂无</span>
            </div>
          </div>

          <!--          <div class="text">-->
          <!--            <p class="p1">绿钻会员权益</p>-->
          <!--            <div class="basic">-->
          <!--              <span class="info">绿钻价格¥58.00</span>-->
          <!--            </div>-->
          <!--          </div>-->

          <div class="text">
            <div class="p1">
              附加商品
              <!-- <el-button type="primary">编辑</el-button> -->
            </div>
            <div class="basic">
              <div
                class="basicBox basicBox1"
                v-if="
                  orderInfo.orderdditionalGoodsVos &&
                  orderInfo.orderdditionalGoodsVos.length > 0
                "
              >
                <div class="basicItem1">
                  <div class="itemLeft itemLeft1">商品名称</div>
                  <div class="itemRight itemRight1">数量</div>
                </div>
                <div
                  class="basicItem1"
                  v-for="(item, index) in this.orderInfo.orderdditionalGoodsVos"
                  :key="index"
                >
                  <div class="itemLeft">
                    {{ item.name }}
                  </div>
                  <div class="itemRight">{{ item.number }}</div>
                </div>
                <!-- <div
                  class="basicItem"
                  v-for="(item, index) in this.orderInfo.orderdditionalGoodsVos"
                  :key="index"
                >
                  <div class="itemLeft">
                    {{ item.name }}
                  </div>
                  <div class="itemRight">{{ item.number }}</div>
                </div> -->
              </div>
              <div class="basicBox" v-else>暂无</div>
            </div>
          </div>

          <div class="text">
            <p class="p1">价格明细</p>
            <!-- <div class="basic">
              <span class="info">服务金额：</span>
              <span class="money money1"
                ><span class="icon icon1">￥</span
                >{{ orderInfo.orderPrice }}</span
              >
            </div>
            <div class="basic">
              <span class="info">附加商品金额：</span>
              <span class="money money1"
                ><span class="icon icon1">￥</span
                >{{
                  orderInfo.additionalGoodsPrice
                    ? orderInfo.additionalGoodsPrice
                    : 0
                }}</span
              >
            </div> -->
            <div
              class="basic"
              v-for="(item, index) in orderInfo.orderServiceProjectVos"
              :key="index"
            >
              <span class="info">{{ item.serviceProjectName }}：</span>
              <span class="money money1 textBox" v-if="orderInfo.isVipCar === 1"
                ><span class="icon icon1">￥</span
                >{{ item.originalPrice }}</span
              >
              <span class="money money1" v-if="orderInfo.isVipCar === 1"
                ><span class="icon icon1">￥</span>{{ item.memberPrice }}</span
              >
              <span class="money money1" v-else
                ><span class="icon icon1">￥</span
                >{{ item.originalPrice }}</span
              >
            </div>
            <div class="basic">
              <span class="info">现金权益：</span>
              <span class="money money1">{{
                orderInfo.discountMoney && orderInfo.discountMoney !== "0.00"
                  ? "-" + orderInfo.discountMoney
                  : "暂无权益"
              }}</span>
            </div>
            <div class="basic" v-if="orderInfo.additionalGoodsPrice > 0">
              <span class="info">附加商品：</span>
              <span class="money money1"
                ><span class="icon icon1">￥</span
                >{{ orderInfo.additionalGoodsPrice }}</span
              >
            </div>
            <div class="basic">
              <span class="info">实付金额：</span>
              <span class="money"
                ><span class="icon">￥</span>{{ orderInfo.fineMoney }}</span
              >
            </div>
          </div>
        </div>
        <div class="botright">
          <div class="text">
            <p class="p1">个人信息</p>
            <div class="basic">
              <span class="title">姓名：</span>
              <span class="info">{{ orderInfo.userName }}</span>
              <span
                v-if="orderInfo.gender === 1"
                style="margin-left: 10px; color: #7d7d7d"
                >先生</span
              >
              <span
                v-if="orderInfo.gender === 2"
                style="margin-left: 10px; color: #7d7d7d"
                >女士</span
              >
            </div>
            <div class="basic">
              <span class="title">手机号：</span>
              <span class="info">{{ orderInfo.telephone }}</span>
            </div>
            <div class="basic">
              <span class="title">车牌号：</span>
              <span class="info">{{ orderInfo.carNumber }}</span>
            </div>
          </div>

          <div class="text">
            <p class="p1">
              取钥匙分配
              <!-- <el-button type="primary">编辑</el-button> -->
            </p>
            <!--            <div class="basic">-->
            <!--              <span class="title">工位：</span>-->
            <!--              <span class="info">{{ orderInfo.shopStationName }}</span>-->
            <!--            </div>-->
            <!-- <div class="basic">
              <span class="title">服务时间：</span>
              <span class="info"
                >{{ orderInfo.pickUpUserTimeStart }} ——
                {{ orderInfo.pickUpUserTimeEnd }}</span
              >
            </div> -->
            <div class="basic">
              <span class="title">取钥匙员工：</span>
              <span class="info" v-if="orderInfo.pickUpUserName !== null">{{
                orderInfo.pickUpUserName
              }}</span>
              <span class="info" v-else>暂无</span>
            </div>

            <!-- <div class="basic">
              <span class="title">服务时间：</span>
              <span
                class="info"
                v-if="
                  orderInfo.pickUpUserTimeStart !== null &&
                  orderInfo.pickUpUserTimeEnd !== null
                "
                >{{ orderInfo.pickUpUserTimeStart }} ——
                {{ orderInfo.pickUpUserTimeEnd }}</span
              >
              <span class="info" v-else>暂无</span>
            </div> -->
          </div>

          <div class="text">
            <div class="p1">
              工位
              <div
                class="editBox"
                v-if="flow.length === 14 || flow.length === 15"
              >
                <el-button
                  type="primary"
                  v-if="
                    orderInfo.status === 4 ||
                    orderInfo.status === 6 ||
                    orderInfo.status === 7 ||
                    orderInfo.status === 8 ||
                    orderInfo.status === 9
                  "
                  @click="editShopStation"
                  >编辑</el-button
                >
              </div>
            </div>
            <div class="basic">
              <span class="title">工位：</span>
              <span class="info" v-if="orderInfo.shopStationName !== null">{{
                orderInfo.shopStationName
              }}</span>
              <span class="info" v-else>暂无</span>
            </div>
          </div>

          <div class="text">
            <p class="p1">
              洗车分配
              <!-- <el-button type="primary">编辑</el-button> -->
            </p>
            <div
              class="basic"
              v-for="(item, index) in orderInfo.washCarUserList"
              :key="index"
            >
              <span class="title"
                >洗车员工（<span v-if="item.constructorMark === 1">A</span
                ><span v-if="item.constructorMark === 2">B</span>）：</span
              >
              <span class="info" v-if="item">{{ item.userName }} </span>
              <span class="info" v-else>暂无 </span>
              <span class="title">服务时间：</span>
              <span class="info" v-if="item">{{ item.timeStart }}----</span>
              <span class="info" v-else>暂无</span>
              <span class="info" v-if="item">{{ item.timeEnd }}</span>
            </div>
          </div>
          <!--          <div class="basic">-->
          <!--            <span class="title">服务时间：</span>-->
          <!--            <span class="info"-->
          <!--            >{{ orderInfo.serviceUserTimeStart }} ——-->
          <!--                {{ orderInfo.serviceUserTimeEnd }}</span-->
          <!--            >-->
          <!--          </div>-->
          <div class="text">
            <p class="p1">
              送钥匙分配
              <!-- <el-button type="primary">编辑</el-button> -->
            </p>
            <div class="basic">
              <span class="title">服务时间：</span>
              <span
                class="info"
                v-if="
                  orderInfo.giveKeyUserTimeStart !== null &&
                  orderInfo.giveKeyUserTimeEnd != null
                "
                >{{
                  orderInfo.giveKeyUserTimeStart +
                  "---" +
                  orderInfo.giveKeyUserTimeEnd
                }}</span
              >
              <span class="info" v-else>暂无</span>
            </div>
            <div class="basic">
              <span class="title">送钥匙员工：</span>
              <span class="info" v-if="orderInfo.giveKeyUserName != null">{{
                orderInfo.giveKeyUserName
              }}</span>
              <span class="info" v-else>暂无</span>
            </div>
            <div class="basic">
              <span class="title">送钥匙停车位信息：</span>
              <span class="info" v-if="orderInfo.giveKeyAddress != null">{{
                orderInfo.giveKeyAddress
              }}</span>
              <span class="info" v-else>暂无</span>
            </div>
          </div>

          <div
            class="text"
            v-if="
              orderInfo.parkingPhotoList &&
              orderInfo.parkingPhotoList.length > 0
            "
          >
            <div class="p1">
              停车信息
              <!-- <el-button type="primary">编辑</el-button> -->
            </div>
            <div class="basic">
              <div
                class="basicBox"
                v-if="
                  orderInfo.parkingPhotoList &&
                  orderInfo.parkingPhotoList.length > 0
                "
              >
                <div
                  class="basicItem"
                  v-for="(item, index) in this.orderInfo.parkingPhotoList"
                  :key="index"
                >
                  <div class="itemLeft">
                    <img :src="item.photoUrl" alt="" />
                  </div>
                  <div class="itemRight">{{ item.photoExplain }}</div>
                </div>
              </div>
              <div class="basicBox" v-else>暂无</div>
            </div>
          </div>

          <div class="text">
            <p class="p1">备注信息</p>
            <div class="basic">
              <span class="info">{{
                orderInfo.remarks ? orderInfo.remarks : "暂无"
              }}</span>
            </div>
          </div>

          <div class="text">
            <p class="p1">
              服务清单
              <!--              <el-button type="primary">编辑</el-button>-->
            </p>
            <div
              class="basic"
              v-for="(item, index) in orderInfo.orderServiceProjectVos"
              :key="index"
            >
              <div>
                <span class="title">{{ item.serviceProjectName }}：</span>
                <el-popover placement="top-start" width="500" trigger="hover">
                  <div style="width: 500px; white-space: normal">
                    <div style="color: #06206b">项目效果：</div>
                    <div v-if="item.effect && item.effect !== null">
                      {{ item.effect }}
                    </div>
                    <div style="color: #06206b">毛巾分类：</div>
                    <div>{{ item.towel }}</div>
                    <div style="color: #06206b">产品清单：</div>
                    <div>{{ item.product }}</div>
                    <div style="color: #06206b">工具清单：</div>
                    <div>{{ item.toll }}</div>
                    <div style="color: #06206b">施工人数：</div>
                    <div>{{ item.people }}人</div>
                  </div>
                  <el-link :underline="false" slot="reference"
                    >查看<i class="el-icon-view el-icon--right"></i
                  ></el-link>
                </el-popover>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 附加商品弹层 -->
    <el-dialog title="附加商品" :visible.sync="additionalGoodsShow" width="35%">
      <div class="additionalGoodsBox">
        <div class="searchBox">
          <el-input
            placeholder="请输入附加商品名称"
            prefix-icon="el-icon-search"
            v-model="searchName"
            clearable
            @change="searchChange"
          >
          </el-input>
        </div>
        <div
          class="additionalGoodsContentBox"
          v-if="additionalGoodsList && additionalGoodsList.length > 0"
        >
          <div
            class="additionalGoodsItemBox"
            :class="index === 0 ? 'additionalGoodsItemBox1' : ''"
            v-for="(item, index) in additionalGoodsList"
            :key="index"
          >
            <div class="leftBox">
              {{ item.name }}
            </div>
            <div class="rightBox">
              <el-input-number
                v-model="item.number"
                @change="handleChange"
                size="small"
                :min="0"
                :max="10"
              ></el-input-number>
            </div>
          </div>
        </div>
        <div class="additionalGoodsContentBox1" v-else>暂无数据</div>
      </div>
      <div slot="footer">
        <el-button @click="additionalGoodsclose">取消</el-button>
        <el-button type="primary" @click="additionalGoodsConfirm"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <!-- 取钥匙分配弹层 -->
    <el-dialog title="取钥匙分配" :visible.sync="takeKeyShow" width="35%">
      <el-form
        ref="elForm"
        :model="updateShopStaffAllocationData"
        :rules="rules"
        size="medium"
        label-width="100px"
        label-position="right"
      >
        <el-form-item label="工位" prop="shopStationId">
          <el-select
            v-model="updateShopStaffAllocationData.shopStationId"
            placeholder="请选择工位"
            clearable
            :style="{ width: '100%' }"
          >
            <el-option
              v-for="(item, index) in stationList"
              :key="index"
              :label="item.name"
              :value="item.id"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="取钥匙员工"
          prop="pickUpUserId"
          v-show="pickUpUserShow"
        >
          <el-select
            v-model="updateShopStaffAllocationData.pickUpUserId"
            placeholder="请选择取钥匙员工"
            clearable
            :style="{ width: '100%' }"
          >
            <el-option
              v-for="(item, index) in staffList"
              :key="index"
              :label="item.userName"
              :value="item.userId"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item
          label="开始时间"
          prop="pickUpUserTimeStart"
          v-show="pickUpUserShow"
        >
          <el-date-picker
            type="datetime"
            v-model="updateShopStaffAllocationData.pickUpUserTimeStart"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            :style="{ width: '100%' }"
            placeholder="请选择开始服务时间"
            clearable
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item
          label="结束时间"
          prop="pickUpUserTimeEnd"
          v-show="pickUpUserShow"
        >
          <el-date-picker
            type="datetime"
            v-model="updateShopStaffAllocationData.pickUpUserTimeEnd"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            :style="{ width: '100%' }"
            placeholder="请选择结束服务时间"
            clearable
          >
          </el-date-picker>
        </el-form-item> -->

        <el-form-item
          label="送钥匙员工"
          prop="giveKeyUserId"
          v-show="giveKeyUserShow"
        >
          <el-select
            v-model="updateShopStaffAllocationData.giveKeyUserId"
            placeholder="请选择取钥匙员工"
            clearable
            :style="{ width: '100%' }"
          >
            <el-option
              v-for="(item, index) in staffList"
              :key="index"
              :label="item.userName"
              :value="item.userId"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="开始时间"
          prop="giveKeyUserTimeStart"
          v-show="giveKeyUserShow"
        >
          <el-date-picker
            type="datetime"
            v-model="updateShopStaffAllocationData.giveKeyUserTimeStart"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            :style="{ width: '100%' }"
            placeholder="请选择开始服务时间"
            clearable
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item
          label="结束时间"
          prop="giveKeyUserTimeEnd"
          v-show="giveKeyUserShow"
        >
          <el-date-picker
            type="datetime"
            v-model="updateShopStaffAllocationData.giveKeyUserTimeEnd"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            :style="{ width: '100%' }"
            placeholder="请选择结束服务时间"
            clearable
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="洗车员工" prop="washCarUserList">
          <el-select
            v-model="washCarUserList"
            multiple
            placeholder="请选择洗车员工"
            clearable
            :style="{ width: '100%' }"
          >
            <el-option
              v-for="(item, index) in staffList"
              :key="index"
              :label="item.userName"
              :value="item.userId"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="close1">取消</el-button>
        <el-button type="primary" @click="handelConfirm1">确定</el-button>
      </div>
    </el-dialog>

    <!-- 车辆检查上传弹层 -->
    <el-dialog
      title="车辆检查"
      :visible.sync="vehicleInspectionShow"
      width="526px"
    >
      <div class="vehicleInspectionContent">
        <div class="conItem" v-for="(item, index) in uploadData" :key="index">
          <div class="uploadBox">
            <!--          action="https://ledianchemei.zhihanyixing.com/api/file/upload"   -->
            <!--     action="https://manage.lediangroup.cn/api/file/upload"     -->
            <el-upload
              class="avatar-uploader"
              action="https://manage.lediangroup.cn/api/file/upload"
              :show-file-list="false"
              :data="{
                userId: userLoginfo.id,
              }"
              :headers="{ token: token }"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <div v-if="item.photoUrl" class="avatar">
                <img :src="item.photoUrl" />
              </div>
              <i v-else class="avatar-uploader-icon">拍照上传</i>
            </el-upload>
            <div v-if="item.photoUrl" @click="colseImg(item)" class="colse">
              X
            </div>
          </div>
          <div class="inputBox">
            <el-input
              v-model="item.photoExplain"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="请输入内容"
            ></el-input>
            <div
              class="delIconBox"
              v-if="item.photoUrl"
              @click="delItemBtn(item)"
            >
              <i class="el-icon-delete"></i>
            </div>
          </div>
        </div>
        <div>
          <el-select
            v-model="inspect.carIsException"
            placeholder="请选择车辆是否异常"
            style="width: 100%"
          >
            <el-option key="1" label="是" value="1"> </el-option>
            <el-option key="2" label="否" value="2"> </el-option>
          </el-select>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="close2">取消</el-button>
        <el-button type="primary" v-loading="isLoading" @click="handelConfirm2"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <!-- 洗车分配弹层 -->
    <el-dialog title="洗车分配" :visible.sync="carWashShow" width="35%">
      <el-form
        ref="elForm"
        :model="formData1"
        :rules="rules1"
        size="medium"
        label-width="100px"
        label-position="right"
      >
        <el-form-item label="服务时间" prop="field105">
          <el-date-picker
            type="datetime"
            v-model="formData1.field105"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            :style="{ width: '100%' }"
            placeholder="请选择服务时间"
            clearable
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="洗车员工" prop="field106">
          <el-select
            v-model="formData1.field106"
            placeholder="请选择洗车员工"
            multiple
            clearable
            :style="{ width: '100%' }"
          >
            <el-option
              v-for="(item, index) in staffList"
              :key="index"
              :label="item.userName"
              :value="item.userId"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="close3">取消</el-button>
        <el-button type="primary" @click="handelConfirm3">确定</el-button>
      </div>
    </el-dialog>

    <!-- 服务清单弹层 -->
    <!-- <el-dialog title="洗车分配" :visible.sync="serviceShow" width="35%">
      <div class="serviceList">
        <div class="serviceName">普洗</div>
        <div class="serviceItem">
          <el-checkbox-group
            v-model="checkedCities"
            @change="handleCheckedCitiesChange"
          >
            <el-checkbox v-for="city in cities" :label="city" :key="city">{{
              city
            }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="close4">取消</el-button>
        <el-button type="primary" @click="handelConfirm4">确定</el-button>
      </div>
    </el-dialog> -->

    <!-- 送钥匙分配弹层 -->
    <el-dialog title="送钥匙分配" :visible.sync="giveKeyShow" width="35%">
      <el-form
        ref="elForm"
        :model="formData2"
        :rules="rules2"
        size="medium"
        label-width="100px"
        label-position="right"
      >
        <el-form-item label="开始时间" prop="timeStart">
          <el-date-picker
            type="datetime"
            v-model="formData2.timeStart"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            :style="{ width: '100%' }"
            placeholder="请选择服务开始时间"
            clearable
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="结束时间" prop="timeEnd">
          <el-date-picker
            type="datetime"
            v-model="formData2.timeEnd"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            :style="{ width: '100%' }"
            placeholder="请选择服务结束时间"
            clearable
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="送钥匙员工" prop="userId">
          <el-select
            v-model="formData2.userId"
            placeholder="请选择送钥匙员工"
            clearable
            :style="{ width: '100%' }"
          >
            <el-option
              v-for="(item, index) in staffList"
              :key="index"
              :label="item.userName"
              :value="item.userId"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="close5">取消</el-button>
        <el-button type="primary" @click="handelConfirm5">确定</el-button>
      </div>
    </el-dialog>

    <!-- 送钥匙停车信息弹层 -->
    <el-dialog
      title="送钥匙停车信息"
      :visible.sync="parkingAddressShow"
      width="35%"
    >
      <div class="vehicleInspectionContent">
        <div class="conItem" v-for="(item, index) in uploadData" :key="index">
          <div class="uploadBox">
            <!--          action="https://ledianchemei.zhihanyixing.com/api/file/upload"   -->
            <!--     action="https://manage.lediangroup.cn/api/file/upload"     -->
            <el-upload
              class="avatar-uploader"
              action="https://manage.lediangroup.cn/api/file/upload"
              :show-file-list="false"
              :data="{
                userId: userLoginfo.id,
              }"
              :headers="{ token: token }"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <div v-if="item.photoUrl" class="avatar">
                <img :src="item.photoUrl" />
              </div>
              <i v-else class="avatar-uploader-icon">拍照上传</i>
            </el-upload>
            <div v-if="item.photoUrl" @click="colseImg(item)" class="colse">
              X
            </div>
          </div>
          <div class="inputBox">
            <el-input
              v-model="item.photoExplain"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="请输入内容"
            ></el-input>
            <div
              class="delIconBox"
              v-if="item.photoUrl"
              @click="delItemBtn(item)"
            >
              <i class="el-icon-delete"></i>
            </div>
          </div>
        </div>
        <div>
          <el-input
            v-model="parkingSpaceAddressData.userCarAddress"
            placeholder="请输入停车地址"
          ></el-input>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="close6">取消</el-button>
        <el-button type="primary" v-loading="isLoading1" @click="handelConfirm6"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <!-- 订单异常信息弹层 -->
    <el-dialog title="订单异常信息" :visible.sync="abnormalShow" width="35%">
      <div class="vehicleInspectionContent">
        <div style="margin-bottom: 15px">订单异常原因:</div>
        <el-input
          v-model="abnormalData.temporaryEndReason"
          placeholder="请输入订单异常状态修改原因"
        ></el-input>
      </div>
      <div slot="footer">
        <el-button @click="abnormalClose">取消</el-button>
        <el-button
          type="primary"
          v-loading="isLoading2"
          @click="abnormalConfirm"
          >{{ abnormalData.isTemporaryEnd === 1 ? "撤销" : "确定" }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getOrderBasicInfo,
  getSelectOrderProcess,
  editOrder,
  getSelectByStationIdBystatus,
  getSelectShopStaffByShopId,
  deleteFile,
  updateOrderStatus,
  shopStaffAllocation,
  editDistribution,
  carToTheStore,
  inspect,
  start,
  updateOrderStatusEleven,
  updateOrderStatusFourteen,
  updateOrderStatusSeventeen,
  updateOrderStatusEighteen,
  updateOrderStatusNineteen,
  shopMeetOrder,
  pickUpUserToTheStore,
  keyScanningCode,
  customerConfirm,
  updateOrderStatusTwelve,
  updateOrderStatusThirteen,
  confirmArrival,
  keyScanning,
  getDistribution,
  customerConfirmsArrival,
  updateOrderStatusSixteen,
  orderTemporaryEnd,
  getAdditionalGoods,
  getOrderAdditionalGoods,
  addOrderAdditionalGoods,
  editOrderAdditionalGoods
} from '@/api/myorder/myorder.js'

export default {
  name: 'orderDetails',
  data() {
    return {
      additionalGoodsShow: false,
      searchName: '',
      isEditAdditionalGoods: 2, // 是否是修改附加商品 1是2否
      additionalGoodsData: [], // 附加商品数据
      additionalGoodsList: [], // 附加商品列表
      parkingSpaceAddressData: {
        orderId: parseInt(sessionStorage.getItem('orderId')),
        userCarAddress: '',
        orderParkingDtoList: '',
      },
      serviceModel: '',
      pickUpStatus: '',
      pickUpItem: {
        orderId: parseInt(sessionStorage.getItem('orderId')),
        qrcode: ""
      },
      pickUpUserShow: false,
      giveKeyUserShow: false,
      orderId: parseInt(sessionStorage.getItem('orderId')),
      token: sessionStorage.getItem('token'),
      userLoginfo: JSON.parse(sessionStorage.getItem('userLoginfo')),
      ordersReceivedShow: false, // 接单弹层控制
      ordersReceivedData: {
        orderId: '',
        shopId: ''
      },
      inspect: {
        orderId: parseInt(sessionStorage.getItem('orderId')),
        orderPhotoDtoList: [],
        carIsException: ''
      },
      ordersReceivedRules: {
        shopId: [{
          required: true,
          message: '请选择店铺',
          trigger: 'change'
        }],
      },
      isLoading: false,
      isLoading1: false,
      takeKeyShow: false, // 取钥匙分配弹层控制
      isEditShopStation: 2, // 是否是编辑工位 1是 2否
      formData: {
        shopStationId: null,
        timeStart: null,
        timeEnd: null,
        userId: undefined,
      },
      rules: {
        shopStationId: [{
          required: true,
          message: '请选择工位',
          trigger: 'change'
        }],
        // washCarUserList: [{
        //   required: true,
        //   message: '请选择洗车员工',
        //   trigger: 'change'
        // }],
      },
      stationList: [], // 根据店铺id查看空闲工位
      staffList: [], // 根据店铺id查看所有员工
      vehicleInspectionShow: false, // 车辆检查上传弹层控制
      uploadData: [
        {
          id: null,
          photoUrl: '',
          photoExplain: '',
        }
      ],
      carWashShow: false,  // 洗车分配弹层控制
      formData1: {
        field105: null,
        field106: undefined,
      },
      rules1: {
        field105: [{
          required: true,
          message: '请选择服务时间',
          trigger: 'change'
        }],
        field106: [{
          required: true,
          message: '请选择洗车员工',
          trigger: 'change'
        }],
      },
      serviceShow: false, // 服务清单弹层控制
      checkedCities: ['上海', '北京'],
      cities: ['上海', '北京', '广州', '深圳'],
      giveKeyShow: false, // 送钥匙员工弹层控制
      formData2: {
        orderId: parseInt(sessionStorage.getItem('orderId')),
        userId: '',
        timeStart: '',
        timeEnd: '',
      },
      rules2: {
        timeStart: [{
          required: true,
          message: '请选择服务开始时间',
          trigger: 'change'
        }],
        timeEnd: [{
          required: true,
          message: '请选择服务结束时间',
          trigger: 'change'
        }],
        userId: [{
          required: true,
          message: '请选择送钥匙员工',
          trigger: 'change'
        }],
      },
      field108Options: [{
        "label": "选项一",
        "value": 1
      }, {
        "label": "选项二",
        "value": 2
      }],
      parkingAddressType: 1,
      parkingAddressShow: false, // 送钥匙停车信息弹层控制
      formData3: {
        orderId: parseInt(sessionStorage.getItem('orderId')),
        userCarAddress: '',
        orderParkingDtoList: '',
      },
      rules3: {
        userCarAddress: [{
          required: true,
          message: '请输入停车地址',
          trigger: 'blur'
        }]
        , orderParkingDtoList: [{
          required: true,
          message: '请选择停车照片',
          trigger: 'blur'
        }],
      },
      flow: [], // 订单流程
      orderProcessList: [], // 订单流程数据
      orderInfo: [],//订单信息
      active: 0,//流程步骤
      washCarUserList: [], // 洗车员工
      updateShopStaffAllocationData: {
        orderId: parseInt(sessionStorage.getItem('orderId')),
        shopStationId: '',
        pickUpUserId: '',
        pickUpUserTimeStart: '',
        pickUpUserTimeEnd: '',
        giveKeyUserId: '',
        giveKeyUserTimeStart: '',
        giveKeyUserTimeEnd: '',
        washCarUserList: [],
      },
      updateOrderStatusData: {
        orderId: parseInt(sessionStorage.getItem('orderId')),
        orderPhotoDtoList: [],
        serviceDetailedIdList: [],
        shopStationId: null,
        qrcode: "",
        status: null,
        timeEnd: "",
        timeStart: "",
        userCarAddress: "",
        userId: null,
        washCarUserList: []
      }, // 修改订单状态数据


      checkAll: false,//服务项全选状态
      abnormalData: {
        isTemporaryEnd: 1, // 是否暂时结束订单 1 是2否
        orderId: parseInt(sessionStorage.getItem('orderId')),
        temporaryEndReason: '', // 暂时结束订单原因
      },
      abnormalShow: false,
      isLoading2: false

    }
  },
  created() {
    // 获取订单详情
    this.getSelectOrderProcess();
  },
  methods: {
    // 订单异常按钮
    orderAbnormal() {
      this.abnormalData.temporaryEndReason = this.orderInfo.temporaryEndReason;
      this.abnormalShow = true;
    },
    // 异常订单弹层确定
    async abnormalConfirm() {
      this.isLoading2 = true
      if (this.orderInfo.isTemporaryEnd === 1) {
        this.abnormalData.isTemporaryEnd = 2;
        this.abnormalData.temporaryEndReason = '';
      } else {
        this.abnormalData.isTemporaryEnd = 1;
      }
      const res = await orderTemporaryEnd(this.abnormalData);
      // console.log(res);
      if (res.status === 200) {
        this.$message({
          type: 'success',
          message: '订单异常状态修改成功!',
          showClose: true
        })
        this.init();
        this.abnormalShow = false;
        this.isLoading2 = false;
      } else {
        this.isLoading2 = false;
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true
        })
      }
    },
    // 异常订单弹层取消
    abnormalClose() {
      this.abnormalData.temporaryEndReason = '';
      this.abnormalShow = false;
      this.isLoading2 = false;
    },
    // 等待客户到店取车
    async waitPickUp() {
      const res = await updateOrderStatusSixteen(parseInt(sessionStorage.getItem('orderId')));
      if (res.status === 200) {
        this.$message({
          type: 'success',
          message: '等待客户到达',
          showClose: true
        })
        this.init()
      } else {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true
        })
      }
    },
    // 车到店洗车分配
    async carToTheStore() {

    },
    // 客户确定到达
    async customerConfirmsArrival() {
      const res = await customerConfirmsArrival(parseInt(sessionStorage.getItem('orderId')));
      if (res.status === 200) {
        this.$message({
          type: 'success',
          message: '客户确认到达',
          showClose: true
        })
        this.init()
      } else {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true
        })
      }
    },
    // 订单结束
    async orderEnd() {
      const res = await updateOrderStatusNineteen(parseInt(sessionStorage.getItem('orderId')))
      if (res.status === 200) {
        this.$message({
          type: 'success',
          message: '订单结束',
          showClose: true
        })
        this.init()
      } else {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true
        })
      }
    },
    // 送钥匙扫码
    async keyScanning(item) {
      var qrcode = '';
      for (let i = 0; i < item.orderQrcodeList.length; i++) {
        if (item.orderQrcodeList[i].type === 2 && item.orderQrcodeList[i].status === 1) {
          qrcode = item.orderQrcodeList[i].qrcode;
        }
      }
      const data = {
        "orderId": parseInt(sessionStorage.getItem('orderId')),
        "qrcode": qrcode
      }
      const res = await keyScanning(data);
      if (res.status === 200) {
        this.$message({
          type: 'success',
          message: '扫码成功',
          showClose: true
        })
        this.orderEnd();
        this.init()
      } else {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true
        })
      }
    },
    // 确认到达
    async confirmArrival() {
      const res = await confirmArrival(parseInt(sessionStorage.getItem('orderId')))
      if (res.status === 200) {
        this.$message({
          type: 'success',
          message: '确认到达',
          showClose: true
        })
        this.init()
      } else {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true
        })
      }
    },
    // 客户确认
    async customerConfirm() {
      const res = await customerConfirm(parseInt(sessionStorage.getItem('orderId')))
      if (res.status === 200) {
        this.$message({
          type: 'success',
          message: '客户确认',
          showClose: true
        })
        this.init()
      } else {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true
        })
      }
    },
    // 取钥匙扫码
    async keyScanningCode(item) {
      var qrcode = '';
      for (let i = 0; i < item.orderQrcodeList.length; i++) {
        if (item.orderQrcodeList[i].type === 1 && item.orderQrcodeList[i].status === 1) {
          qrcode = item.orderQrcodeList[i].qrcode;
        }
      }
      // console.log("item==<" + qrcode)
      const keyScanningCodeData = {
        "orderId": parseInt(sessionStorage.getItem('orderId')),
        "qrcode": qrcode
      }
      const res = await keyScanningCode(keyScanningCodeData);
      if (res.status === 200) {
        this.$message({
          type: 'success',
          message: '确认到达',
          showClose: true
        })
        this.init()
      } else {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true
        })
      }
    },
    // 取车员工到达
    async pickUpUserToTheStore() {
      const orderId = parseInt(sessionStorage.getItem('orderId'));
      const res = await pickUpUserToTheStore(orderId)
      if (res.status === 200) {
        this.$message({
          type: 'success',
          message: '确认到达',
          showClose: true
        })
        this.init()
      } else {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true
        })
      }
    },
    // 店铺接单
    async orderReceiving() {
      const orderId = parseInt(sessionStorage.getItem('orderId'));
      const res = await shopMeetOrder(orderId)
      if (res.status === 200) {
        this.$message({
          type: 'success',
          message: '接单成功',
          showClose: true
        })
        this.init();
      } else {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true
        })
      }
    },
    // 确认 车开走
    async confirm(item) {
      const orderId = parseInt(sessionStorage.getItem('orderId'))
      const status = item.status
      const res = await updateOrderStatusEighteen(orderId)
      if (res.status === 200) {
        // this.$message({
        //   type: 'success',
        //   message: '车已开走!',
        //   showClose: true
        // })
        this.orderEnd()
        this.init()
      } else {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true
        })
      }
    },
    // 扫码取车
    async pickUp(item) {
      var qrcode = '';
      for (let i = 0; i < item.orderQrcodeList.length; i++) {
        if (item.orderQrcodeList[i].type === 3 && item.orderQrcodeList[i].status === 1) {
          qrcode = item.orderQrcodeList[i].qrcode;
        }
      }
      // console.log("item==<" + qrcode)
      this.pickUpItem.qrcode = qrcode
      const res = await updateOrderStatusSeventeen(this.pickUpItem)
      if (res.status === 200) {
        this.$message({
          type: 'success',
          message: '取车成功!',
          showClose: true
        })
        this.init()
      } else {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true
        })
      }
    },
    // 客户到达
    async arrive() {
      const orderId = parseInt(sessionStorage.getItem('orderId'))
      const res = await updateOrderStatusFourteen(orderId)
      if (res.status === 200) {
        this.$message({
          type: 'success',
          message: '客户到达!',
          showClose: true
        })
      } else {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true
        })
      }
    },
    // 获取所有附加商品
    async getAdditionalGoods() {
      const res = await getAdditionalGoods({ name: this.searchName })
      if (res.status === 200) {
        // console.log(res);
        this.additionalGoodsList = res.data;
        // console.log(this.additionalGoodsList);
        if (this.additionalGoodsList.length > 0) {
          this.additionalGoodsList.forEach(item => {
            item.number = 0;
            item.isEditId = null;
          })
        }
        this.getOrderAdditionalGoods();
      }
    },
    // 获取订单附加商品
    async getOrderAdditionalGoods() {
      const res = await getOrderAdditionalGoods({ orderNumber: this.orderInfo.orderNumber })
      if (res.status === 200) {
        if (res.data.length > 0) {
          // console.log('2', this.additionalGoodsList, res1.data.data);
          this.isEditAdditionalGoods = 1;
          this.additionalGoodsList.forEach(item => {
            res.data.forEach(item1 => {
              if (item.id === item1.additionalGoodsId) {
                item.number = item1.number;
                item.isEditId = item1.id;
                // console.log('1', item, item1);
              }
            })
          })
          // console.log('3', this.additionalGoodsList);
          this.$forceUpdate();
        } else {
          this.isEditAdditionalGoods = 2;
          this.$forceUpdate();
        }
      }
    },
    // 附加商品按钮
    additionalGoodsBtn() {
      this.getAdditionalGoods();
      this.additionalGoodsShow = true;
    },
    // 搜索
    searchChange() {
      this.getAdditionalGoods();
    },
    // 
    handleChange(val) {
      // console.log(val, this.additionalGoodsList);
      this.$forceUpdate();
    },
    // 附加商品弹层取消
    additionalGoodsclose() {
      this.additionalGoodsShow = false;
    },
    // 附加商品弹层确定
    async additionalGoodsConfirm() {
      this.additionalGoodsData = [];
      this.additionalGoodsList.forEach(item => {
        if (item.number > 0) {
          this.additionalGoodsData.push({
            additionalGoodsId: item.id,
            id: item.isEditId,
            number: item.number,
            orderNumber: this.orderInfo.orderNumber,
            shopId: this.orderInfo.shopId
          })
        }
      })
      if (this.additionalGoodsData.length === 0) {
        return this.$message({
          type: 'warning',
          message: '请选择附加商品!',
          showClose: true
        })
      }
      // console.log(this.additionalGoodsData);
      if (this.isEditAdditionalGoods === 2) {
        const res = await addOrderAdditionalGoods(this.additionalGoodsData)
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: '添加附加商品成功!',
            showClose: true
          })
          this.init()
          this.additionalGoodsShow = false;
        } else {
          this.$message({
            type: 'error',
            message: res.msg,
            showClose: true
          })
        }
      } else {
        const res = await editOrderAdditionalGoods(this.additionalGoodsData)
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: '修改附加商品成功!',
            showClose: true
          })
          this.init()
          this.additionalGoodsShow = false;
        } else {
          this.$message({
            type: 'error',
            message: res.msg,
            showClose: true
          })
        }
      }
    },
    // 完工 按钮
    async end() {
      const orderId = parseInt(sessionStorage.getItem('orderId'))
      const res = await updateOrderStatusEleven(orderId)
      if (res.status === 200) {
        this.$message({
          type: 'success',
          message: '完工!',
          showClose: true
        })
        if (this.flow.length === 14 || this.flow.length === 11) {
          this.waitPickUp()
        }
        this.init()
      } else {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true
        })
      }
    },
    // 开工按钮
    async start() {
      const orderId = parseInt(sessionStorage.getItem('orderId'))
      const res = await start(orderId)
      if (res.status === 200) {
        this.$message({
          type: 'success',
          message: '开工!',
          showClose: true
        })
        this.init()
      } else {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true
        })
      }
    },
    // 查询该订单所有流程
    async getSelectOrderProcess() {
      const res = await getSelectOrderProcess({ orderId: this.orderId });
      // console.log(res);
      if (res.status === 200) {
        this.orderProcessList = res.data.process;
        // 1- 1 到店洗车，自提
        if (res.data.process.toString() === [1, 2, 3, 21, 8, 5, 10, 11, 16, 17, 18, 19].toString()) {
          this.flow = [
            {
              id: 1,
              title: '已下单',
              ref: 'order1',
            },
            {
              id: 2,
              title: '已支付',
              ref: 'order2',
            },
            {
              id: 3,
              title: '已接单',
              ref: 'order3',
            }, {
              id: 21,
              title: '客户已到达',
              ref: 'order21',
            },
            {
              id: 8,
              title: '车辆检查',
              ref: 'order8',
            },
            {
              id: 9,
              title: '洗车分配',
              ref: 'order9',
            },
            {
              id: 5,
              title: '开工',
              ref: 'order5',
            },
            {
              id: 10,
              title: '完工',
              ref: 'order10',
            },
            {
              id: 16,
              title: '取车扫码',
              ref: 'order16',
            },
            {
              id: 17,
              title: '车已开走',
              ref: 'order18',
            }, {
              id: 19,
              title: '订单结束',
              ref: 'order19',
            }
          ]
        }
        // 1-2 到店洗车，送钥匙
        else if (res.data.process.toString() === [1, 2, 3, 21, 8, 5, 10, 11, 12, 13, 14, 15, 19].toString()) {
          this.flow = [
            {
              id: 1,
              title: '已下单',
              ref: 'order1',
            },
            {
              id: 2,
              title: '已支付',
              ref: 'order2',
            },
            {
              id: 3,
              title: '已接单',
              ref: 'order3',
            },
            {
              id: 21,
              title: '车辆检查',
              ref: 'order21',
            },
            {
              id: 8,
              title: '客户确认中',
              ref: 'order8',
            },
            {
              id: 9,
              title: '洗车分配',
              ref: 'order9',
            },
            {
              id: 5,
              title: '开工',
              ref: 'order5',
            },
            {
              id: 10,
              title: '完工',
              ref: 'order10',
            },
            {
              id: 11,
              title: '送钥匙分配',
              ref: 'order11',
            }, {
              id: 12,
              title: '停车地址',
              ref: 'order12',
            }, {
              id: 13,
              title: '确定到达',
              ref: 'order13',
            }, {
              id: 14,
              title: '送钥匙扫码',
              ref: 'order14',
            }, {
              id: 19,
              title: '订单结束',
              ref: 'order19',
            },
          ]
        }
        // 2-1 取钥匙，自提
        else if (res.data.process.toString() === [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 16, 17, 18, 19].toString()) {
          this.flow = [
            {
              id: 1,
              title: '已下单',
              ref: 'order1',
            },
            {
              id: 2,
              title: '已支付',
              ref: 'order2',
            },
            {
              id: 3,
              title: '已接单',
              ref: 'order3',
            },
            {
              id: 4,
              title: '取钥匙分配',
              ref: 'order4',
            },
            {
              id: 6,
              title: '到达现场',
              ref: 'order6',
            },
            {
              id: 7,
              title: '取钥匙扫码',
              ref: 'order7',
            }, {
              id: 8,
              title: '车辆检查',
              ref: 'order8',
            }, {
              id: 9,
              title: '客户确认',
              ref: 'order9',
            }, {
              id: 10,
              title: '开工',
              ref: 'order10',
            }, {
              id: 11,
              title: '完工',
              ref: 'order11',
            }, {
              id: 16,
              title: '到店取车',
              ref: 'order16',
            }, {
              id: 17,
              title: '取车扫码',
              ref: 'order17',
            }, {
              id: 18,
              title: '车已开走',
              ref: 'order18',
            }, {
              id: 19,
              title: '订单结束',
              ref: 'order19',
            },
          ]
        }
        // 2-2 取钥匙，送车
        else if (res.data.process.toString() === [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 19].toString()) {
          this.flow = [
            {
              id: 1,
              title: '已下单',
              ref: 'order1',
            },
            {
              id: 2,
              title: '已支付',
              ref: 'order2',
            },
            {
              id: 3,
              title: '已接单',
              ref: 'order3',
            },
            {
              id: 4,
              title: '取钥匙分配',
              ref: 'order4',
            },
            {
              id: 6,
              title: '到达现场',
              ref: 'order6',
            },
            {
              id: 7,
              title: '取钥匙扫码',
              ref: 'order7',
            },
            {
              id: 8,
              title: '车辆检查',
              ref: 'order8',
            }, {
              id: 9,
              title: '客户确认',
              ref: 'order9',
            }, {
              id: 10,
              title: '开工',
              ref: 'order10',
            }, {
              id: 11,
              title: '完工',
              ref: 'order11',
            }, {
              id: 12,
              title: '送钥匙分配',
              ref: 'order12',
            }, {
              id: 13,
              title: '停车位地址',
              ref: 'order13',
            }, {
              id: 14,
              title: '确定到达',
              ref: 'order14',
            }, {
              id: 15,
              title: '送钥匙扫码',
              ref: 'order15',
            }, {
              id: 19,
              title: '订单结束',
              ref: 'order19',
            },
          ]
        }
        this.init();
      }
    },
    // 根据店铺id查看空闲工位
    async getSelectByStationIdBystatus(val) {
      const res = await getSelectByStationIdBystatus({ shopId: val });
      // console.log(res);
      if (res.status === 200) {
        this.stationList = res.data;
      }
    },
    // 根据店铺id查询员工信息
    async getSelectShopStaffByShopId(val) {
      const res = await getSelectShopStaffByShopId({ shopId: val });
      // console.log(res);
      if (res.status === 200) {
        this.staffList = res.data;
      }
    },
    // 获取订单信息
    async init() {
      const res = await getOrderBasicInfo({ orderId: this.orderId });
      // console.log("状态" + res.data.status);
      // console.log("data" + res.data);
      if (res.status === 200) {
        this.orderInfo = res.data;
        // console.log(this.flow);

        for (let i = 0; i < this.flow.length; i++) {
          if (this.flow[i].id === res.data.status) {
            this.active = i
          }
        }
        // if (res.data.status === 13) {
        //   this.active = 5
        // } else {
        //   for (let i = 0; i < this.flow.length; i++) {
        //     if (this.flow[i].id === res.data.status) {
        //       this.active = i
        //     }
        //   }
        // }
        if (this.flow.length < 14) {
          if (res.data.status === 8 && res.data.carIsException === 2) {
            this.active = 5
          };
        } else {
          if (res.data.status === 8 && res.data.carIsException === 2) {
            this.active = 7
          };
        }
        // console.log('active', this.active);
        this.next(this.active);
        this.getSelectByStationIdBystatus(res.data.shopId);
        this.getSelectShopStaffByShopId(res.data.shopId);
        this.abnormalData.isTemporaryEnd = this.orderInfo.isTemporaryEnd;
        // console.log(this.abnormalData.isTemporaryEnd);
      }
      // console.log('init', this.active);
    },
    next(val) {
      // if (this.active++ > 8) this.active = 0;
      // console.log('val', val);
      this.active = val;
      // console.log('激活', this.active);
      if (this.flow.length === 11) {
        if (this.active === 0) {
          this.$refs.horn.style.left = 92 + 'px'
        } else if (this.active === 1) {
          this.$refs.horn.style.left = 188 + 'px'
        } else if (this.active === 2) {
          this.$refs.horn.style.left = 285 + 'px'
        } else if (this.active === 3) {
          this.$refs.horn.style.left = 382 + 'px'
        } else if (this.active === 4) {
          this.$refs.horn.style.left = 480 + 'px'
        } else if (this.active === 5) {
          this.$refs.horn.style.left = 575 + 'px'
        } else if (this.active === 6) {
          this.$refs.horn.style.left = 670 + 'px'
        } else if (this.active === 7) {
          this.$refs.horn.style.left = 770 + 'px'
        } else if (this.active === 8) {
          this.$refs.horn.style.left = 862 + 'px'
        } else if (this.active === 9) {
          this.$refs.horn.style.left = 960 + 'px'
        } else if (this.active === 10) {
          this.$refs.horn.style.left = 1058 + 'px'
        }
      }
      else if (this.flow.length === 13) {
        if (this.active === 0) {
          this.$refs.horn.style.left = 85 + 'px'
        } else if (this.active === 1) {
          this.$refs.horn.style.left = 167 + 'px'
        } else if (this.active === 2) {
          this.$refs.horn.style.left = 250 + 'px'
        } else if (this.active === 3) {
          this.$refs.horn.style.left = 333 + 'px'
        } else if (this.active === 4) {
          this.$refs.horn.style.left = 412 + 'px'
        } else if (this.active === 5) {
          this.$refs.horn.style.left = 495 + 'px'
        } else if (this.active === 6) {
          this.$refs.horn.style.left = 575 + 'px'
        } else if (this.active === 7) {
          this.$refs.horn.style.left = 658 + 'px'
        } else if (this.active === 8) {
          this.$refs.horn.style.left = 738 + 'px'
        } else if (this.active === 9) {
          this.$refs.horn.style.left = 820 + 'px'
        } else if (this.active === 10) {
          this.$refs.horn.style.left = 903 + 'px'
        } else if (this.active === 11) {
          this.$refs.horn.style.left = 983 + 'px'
        } else if (this.active === 12) {
          this.$refs.horn.style.left = 1065 + 'px'
        }
      }
      else if (this.flow.length === 14) {
        if (this.active === 0) {
          this.$refs.horn.style.left = 55 + 'px'
        } else if (this.active === 1) {
          this.$refs.horn.style.left = 135 + 'px'
        } else if (this.active === 2) {
          this.$refs.horn.style.left = 215 + 'px'
        } else if (this.active === 3) {
          this.$refs.horn.style.left = 295 + 'px'
        } else if (this.active === 4) {
          this.$refs.horn.style.left = 375 + 'px'
        } else if (this.active === 5) {
          this.$refs.horn.style.left = 455 + 'px'
        } else if (this.active === 6) {
          this.$refs.horn.style.left = 535 + 'px'
        } else if (this.active === 7) {
          this.$refs.horn.style.left = 614 + 'px'
        } else if (this.active === 8) {
          this.$refs.horn.style.left = 695 + 'px'
        } else if (this.active === 9) {
          this.$refs.horn.style.left = 775 + 'px'
        } else if (this.active === 10) {
          this.$refs.horn.style.left = 855 + 'px'
        } else if (this.active === 11) {
          this.$refs.horn.style.left = 935 + 'px'
        } else if (this.active === 12) {
          this.$refs.horn.style.left = 1005 + 'px'
        } else if (this.active === 13) {
          this.$refs.horn.style.left = 1095 + 'px'
        }
      }
      else if (this.flow.length === 15) {
        if (this.active === 0) {
          this.$refs.horn.style.left = 24 + 'px'
        } else if (this.active === 1) {
          this.$refs.horn.style.left = 105 + 'px'
        } else if (this.active === 2) {
          this.$refs.horn.style.left = 185 + 'px'
        } else if (this.active === 3) {
          this.$refs.horn.style.left = 265 + 'px'
        } else if (this.active === 4) {
          this.$refs.horn.style.left = 345 + 'px'
        } else if (this.active === 5) {
          this.$refs.horn.style.left = 425 + 'px'
        } else if (this.active === 6) {
          this.$refs.horn.style.left = 505 + 'px'
        } else if (this.active === 7) {
          this.$refs.horn.style.left = 585 + 'px'
        } else if (this.active === 8) {
          this.$refs.horn.style.left = 665 + 'px'
        } else if (this.active === 9) {
          this.$refs.horn.style.left = 745 + 'px'
        } else if (this.active === 10) {
          this.$refs.horn.style.left = 825 + 'px'
        } else if (this.active === 11) {
          this.$refs.horn.style.left = 907 + 'px'
        } else if (this.active === 12) {
          this.$refs.horn.style.left = 987 + 'px'
        } else if (this.active === 13) {
          this.$refs.horn.style.left = 1065 + 'px'
        } else if (this.active === 14) {
          this.$refs.horn.style.left = 1147 + 'px'
        }
      }
    },
    show(val) {
      this.next(val);
    },
    // 2-1\2-2接单按钮
    async orderReceBtn() {
      this.updateOrderStatusData.status = 2;
      const res = await updateOrderStatus(this.updateOrderStatusData);
      // console.log("接单按钮===>" + this.updateOrderStatusData)
      // console.log(res);
      if (res.status === 200) {
        this.$message({
          type: 'success',
          message: '接单成功!',
          showClose: true
        })
        this.updateOrderStatusData.status = 3;
        const res = await updateOrderStatus(this.updateOrderStatusData);
        // console.log(res);
        this.init()
      } else {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true
        })
      }
      // this.init()
      // this.$refs.horn.style.left = 394 + 'px'
    },
    // 1-1\1-2接单按钮
    async orderReceBtn1() {
      this.updateOrderStatusData.status = 6;
      const res = await updateOrderStatus(this.updateOrderStatusData);
      // console.log(res);
      if (res.status === 200) {
        this.$message({
          type: 'success',
          message: '接单成功!',
          showClose: true
        })
        this.init()
      } else {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true
        })
      }

    },
    // 获取分配员工信息
    async getDistribution() {
      const res = await getDistribution(parseInt(sessionStorage.getItem('orderId')));
      if (res.status === 200) {
        this.updateShopStaffAllocationData.shopStationId = res.data.shopStationId;
        if (res.data.userList.length === 1) {
          this.washCarUserList.push(res.data.userList[0].userId);
        }
        if (res.data.userList.length === 2) {
          this.washCarUserList.push(res.data.userList[0].userId, res.data.userList[1].userId);
        }
        // console.log(this.updateShopStaffAllocationData);
      }
    },
    // 编辑工位
    editShopStation() {
      this.isEditShopStation = 1;
      this.pickUpUserShow = false;
      this.updateShopStaffAllocationData.washCarUserList = [];
      this.getDistribution();
      this.takeKeyShow = true;
    },
    // 取钥匙分配按钮
    takeKeyBtn(orderInfo) {
      // console.log(orderInfo)
      const serviceModel = orderInfo.serviceModel
      const pickUpStatus = orderInfo.pickUp
      this.serviceModel = serviceModel
      this.pickUpStatus = pickUpStatus
      // console.log("serviceModel" + serviceModel)
      // console.log("pickUp" + pickUpStatus)
      if (serviceModel === 2) {
        this.pickUpUserShow = true;
      }
      // if (pickUp === 2) {
      //   this.giveKeyUserShow = true;
      // }
      this.isEditShopStation = 2;
      this.takeKeyShow = true;
    },
    // 取钥匙分配取消
    close1() {
      this.isEditShopStation = 2;
      this.takeKeyShow = false;
    },
    // 取钥匙分配确定
    handelConfirm1() {
      this.$refs['elForm'].validate(async valid => {
        if (!valid) return
        console.log(this.updateShopStaffAllocationData);
        if (this.serviceModel === 2) {
          if (!this.updateShopStaffAllocationData.pickUpUserId) {
            this.$message({
              type: 'warning',
              message: '请选择取钥匙员工！',
              showClose: true
            })
            return;
          }
        }
        if (this.washCarUserList.length === 0) {
          this.$message({
            type: 'warning',
            message: '请选择洗车员工！',
            showClose: true
          })
          return;
        }
        if (this.washCarUserList.length > 2) {
          this.$message({
            type: 'warning',
            message: '洗车人数不得大于2位！',
            showClose: true
          })
          return;
        }
        if (this.washCarUserList.length === 2) {
          const staffA = this.washCarUserList[0];
          const staffB = this.washCarUserList[1];
          this.updateShopStaffAllocationData.washCarUserList = [];
          this.updateShopStaffAllocationData.washCarUserList.push({
            constructorMark: 1,
            id: staffA
          }, {
            constructorMark: 2,
            id: staffB
          })
        }
        if (this.washCarUserList.length === 1) {
          const staffA = this.washCarUserList[0];
          const staffB = this.washCarUserList[0];
          this.updateShopStaffAllocationData.washCarUserList = [];
          this.updateShopStaffAllocationData.washCarUserList.push({
            constructorMark: 1,
            id: staffA
          }, {
            constructorMark: 2,
            id: staffB
          })
        }
        // console.log(this.updateShopStaffAllocationData);
        // return
        if (this.isEditShopStation === 1) {
          const res = await editDistribution(this.updateShopStaffAllocationData)
          if (res.status === 200) {
            this.$message({
              type: 'success',
              message: '修改分配成功',
              showClose: true
            })
            this.init()
            this.close1()
          } else {
            this.$message({
              type: 'error',
              message: res.msg,
              showClose: true
            })
          }
        } else {
          if (this.serviceModel === 2) {
            const res = await shopStaffAllocation(this.updateShopStaffAllocationData)
            if (res.status === 200) {
              this.$message({
                type: 'success',
                message: '分配成功',
                showClose: true
              })
              this.init()
              this.close1()
            } else {
              this.$message({
                type: 'error',
                message: res.msg,
                showClose: true
              })
            }
          } else {
            const res = await carToTheStore(this.updateShopStaffAllocationData);
            if (res.status === 200) {
              this.$message({
                type: 'success',
                message: '分配成功',
                showClose: true
              })
              this.init()
              this.close1()
            } else {
              this.$message({
                type: 'error',
                message: res.msg,
                showClose: true
              })
            }
          }
        }
      })
    },
    // 取钥匙确定按钮
    async determineBtn() {
      this.updateOrderStatusData.status = 5;
      this.updateOrderStatusData.qrcode = this.orderInfo.takeKeyQrcode;
      // console.log(this.updateOrderStatusData);
      const res = await updateOrderStatus(this.updateOrderStatusData);
      // console.log(res);
      if (res.status === 200) {
        this.$message({
          type: 'success',
          message: '取钥匙完成!',
          showClose: true
        })
        this.init()
      } else {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true
        })
      }
    },
    // 车辆检查上传弹层按钮
    vehicleInspectionBtn() {
      this.vehicleInspectionShow = true;
    },
    handleAvatarSuccess(res, file, fileList) {
      // console.log(res);
      // console.log(fileList);
      if (res.status === 200) {
        this.$message({
          showClose: true,
          type: "success",
          message: "上传成功!",
        });
        this.uploadData[this.uploadData.length - 1].photoUrl = res.data.url;
        this.uploadData[this.uploadData.length - 1].id = res.data.id;
        this.uploadData.push({
          id: null,
          photoUrl: '',
          photoExplain: '',
        });
      } else {
        this.$message({
          showClose: true,
          type: "error",
          message: res.msg,
        });
      }
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isLt2M;
    },
    // 删除图片
    colseImg(val) {
      this.$confirm("是否删除该图片?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        // console.log(val);
        const res = await deleteFile({ fileId: val.id });
        // console.log(res);
        if (res.status === 200) {
          this.uploadData.forEach(item => {
            if (item.photoUrl === val.photoUrl) {
              item.photoUrl = '';
            }
          })
          this.$message({
            showClose: true,
            type: "success",
            message: "删除成功!",
          });
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.msg,
          });
        }
      })
    },
    // 删除检查项
    delItemBtn(val) {
      this.$confirm("是否删除该项检查结果?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        // console.log(val);
        for (let i = 0; i < this.uploadData.length; i++) {
          if (this.uploadData[i].id === val.id) {
            const res = await deleteFile({ fileId: val.id });
            // console.log(res);
            if (res.status === 200) {
              this.uploadData.splice(i, 1);
              this.$message({
                showClose: true,
                type: "success",
                message: "删除成功!",
              });
            } else {
              this.$message({
                showClose: true,
                type: "error",
                message: res.msg,
              });
            }
          }
        }
      })
    },
    // 车辆检查上传取消
    close2() {
      this.vehicleInspectionShow = false;
    },
    // 车辆检查上传确定
    async handelConfirm2() {
      this.isLoading = true;
      for (let i = 0; i < this.uploadData.length; i++) {
        if (this.uploadData[i].id === null) {
          this.uploadData.splice(i, 1)
        }
      }
      this.inspect.orderPhotoDtoList = this.uploadData
      // console.log("车辆上传信息==>" + this.inspect)
      const res = await inspect(this.inspect);
      // console.log(res);
      if (res.status === 200) {
        this.$message({
          type: 'success',
          message: '车辆检查成功!',
          showClose: true
        })
        this.isLoading = false;
        this.init()
        this.close2()
      } else {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true
        })
        this.isLoading = false;
      }
    },
    // 洗车分配按钮
    carWashBtn() {
      this.carWashShow = true;
    },
    // 洗车分配取消
    close3() {
      this.carWashShow = false;
    },
    // 洗车分配确定
    handelConfirm3() {
      this.$refs['elForm'].validate(valid => {
        // console.log(this.formData1);
        if (!valid) return
        this.close3()
      })
    },
    // 服务清单按钮
    serviceBtn() {
      this.serviceShow = true;
    },
    // 服务清单取消
    close4() {
      this.serviceShow = false;
    },
    // 服务清单确定
    handelConfirm4() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return
        this.close4()
      })
    },
    // 服务清单选择
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
    },
    // 送钥匙员工分配按钮
    giveKeyBtn() {
      this.giveKeyShow = true;
    },
    // 送钥匙员工分配取消
    close5() {
      this.giveKeyShow = false;
    },
    // 送钥匙员工分配确定
    handelConfirm5() {
      this.$refs['elForm'].validate(async valid => {
        if (!valid) return
        // console.log("this.formData2==>=" + this.formData2)
        const res = await updateOrderStatusTwelve(this.formData2)
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: '送钥匙分配成功!',
            showClose: true
          })
          this.init()
          this.close5()
        } else {
          this.$message({
            type: 'error',
            message: res.msg,
            showClose: true
          })
        }
      })
    },
    // 填写停车地址按钮
    parkingAddressBtn() {
      this.parkingAddressShow = true;
    },
    // 填写停车地址取消
    close6() {
      this.parkingAddressShow = false;
    },
    // 填写停车地址确定
    async handelConfirm6() {
      this.isLoading1 = true;
      this.parkingSpaceAddressData.orderParkingDtoList = this.uploadData
      for (let i = 0; i < this.parkingSpaceAddressData.orderParkingDtoList.length; i++) {
        if (this.parkingSpaceAddressData.orderParkingDtoList[i].photoUrl === '') {
          this.parkingSpaceAddressData.orderParkingDtoList.splice(i, 1)
        }
      }
      const res = await updateOrderStatusThirteen(this.parkingSpaceAddressData)
      if (res.status === 200) {
        this.$message({
          type: 'success',
          message: '停车成功!',
          showClose: true
        })
        this.isLoading1 = false;
        this.init()
        this.close6()
      } else {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true
        })
        this.isLoading1 = false;
      }
    },
  }
}
</script>

<style lang='scss' scoped>
.orderDetails {
  width: 100%;
  min-height: 300px;

  .shopDetail {
    // width: 320px;
    padding-top: 20px;
    padding-bottom: 30px;

    .detailTop {
      display: flex;
      align-items: center;
      margin-bottom: 40px;

      .topItem {
        .itemName {
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #313131;
          line-height: 20px;
        }

        .itemContent {
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #7d7d7d;
          line-height: 20px;
        }

        ::v-deep .el-button {
          // width: 70px;
          height: 30px;
          background: linear-gradient(
            270deg,
            #01df6e 0%,
            #2df891 50%,
            #01df6e 100%
          );
          box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
          border-radius: 2px;
          border: none;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          line-height: 5px;
          // margin-bottom: 20px;
        }
        ::v-deep .el-button--default {
          background: #f4fff5;
          box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
          border-radius: 3px;
          color: #01df6e;
          // margin-right: 20px;
        }
      }

      .topItem1 {
        margin-right: 100px;
      }
    }

    .p1 {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #313131;
      line-height: 20px;
      margin: 0;

      .editBox {
        display: inline-block;
      }

      ::v-deep .el-button {
        width: 70px;
        height: 30px;
        background: linear-gradient(
          270deg,
          #01df6e 0%,
          #2df891 50%,
          #01df6e 100%
        );
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        border: none;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        line-height: 5px;
        // margin-bottom: 20px;
        margin-left: 10px;
      }
    }

    .courseTitle {
      display: flex;
      margin-bottom: 12px;

      .p1 {
        line-height: 30px;
      }

      ::v-deep .el-button {
        width: 70px;
        height: 30px;
        background: linear-gradient(
          270deg,
          #01df6e 0%,
          #2df891 50%,
          #01df6e 100%
        );
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        border: none;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        line-height: 5px;
        margin-bottom: 20px;
        margin-left: 10px;
      }
    }

    ::v-deep.courseBox {
      min-width: 960px;
      height: 178px;
      background: #ffffff;
      border-radius: 8px;
      border: 1px solid #e3e3e3;
      //pointer-events: none;
      .el-steps {
        width: 1060px;
        margin-right: 60px;
        margin-left: 60px;
      }

      .el-step__line {
        margin: 0 30px !important;
      }

      .el-step__head.is-process {
        border-color: #36cb0a;
      }

      .is-process {
        color: #fff !important;

        .el-step__icon.is-text {
          background-color: #36cb0a;
        }
      }

      .el-step__title.is-process {
        color: #36cb0a !important;
      }
    }

    ::v-deep.courseBox1 {
      .el-steps {
        width: 1120px;
        margin-right: 30px;
        margin-left: 30px;
      }
    }

    ::v-deep.courseBox2 {
      .el-steps {
        width: 1200px;
        margin-right: 0px;
        margin-left: 0px;
      }
    }

    .content {
      position: relative;
      padding-top: 30px;

      .horn {
        // width: 0;
        // height: 0;
        // border: 30px solid transparent;
        // border-bottom: 30px solid #aaaaaa;
        width: 29px;
        height: 9px;
        background: url("../../assets/horn.png") no-repeat center center;
        background-size: 100% 100%;
        position: absolute;
        top: 0;
        left: 125px;
      }

      .btnBox {
        position: relative;
        height: 30px;

        ::v-deep .el-button {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, 0);
          min-width: 70px;
          height: 30px;
          background: linear-gradient(
            270deg,
            #01df6e 0%,
            #2df891 50%,
            #01df6e 100%
          );
          box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
          border-radius: 2px;
          border: none;
          color: #fff;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          line-height: 5px;
          margin-bottom: 20px;
        }
        .buttonBox {
          top: 0;
          left: 40%;
          transform: translate(-40%, 0);
        }
      }

      .mysteps {
        width: 949px;
        height: 377px;
        background: #ffffff;
        border: 1px solid #aaaaaa;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        .area {
          width: 859px;
          height: 337px;
          position: relative;

          p {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #313131;
            line-height: 25px;
            margin: 0;
          }

          .getOrder {
            position: absolute;
            margin: 0;
            bottom: 0;
            left: 44%;
          }

          .siteForm {
            margin-top: 20px;
            width: 320px;
          }

          .resetSite {
            width: 90px;
            height: 30px;
            background: #e3620b;
            border-radius: 2px;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 30px;
            text-align: center;
            position: absolute;
            top: 50px;
            left: 38%;
            cursor: pointer;
          }

          .service {
            display: inline-block;
            width: 110px;
            height: 30px;
            background: #e3620b;
            border-radius: 2px;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 30px;
            text-align: center;
            margin-top: 20px;
            cursor: pointer;
          }
        }
      }
    }

    .courseBot {
      display: flex;

      .botLeft {
        margin-right: 80px;
      }

      .text {
        min-width: 200px;
        min-height: 40px;
        margin: 40px 0;

        .basic {
          width: auto;
          margin-top: 5px;

          .basicBox {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7d7d7d;

            .basicItem {
              display: flex;
              margin-bottom: 15px;

              .itemLeft {
                width: 55px;
                height: 55px;
                margin-right: 10px;

                img {
                  width: 55px;
                  height: 55px;
                }
              }

              .itemRight {
                width: 291px;
                height: 55px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #7d7d7d;
                line-height: 20px;
              }
            }
            .basicItem1 {
              display: flex;
              // margin-bottom: 15px;

              .itemLeft {
                width: 180px;
                // height: 55px;
                // margin-right: 10px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #7d7d7d;
                line-height: 20px;
                padding: 5px;
                border-right: 1px solid #7d7d7d;
                border-top: 1px solid #7d7d7d;
              }
              .itemLeft1 {
                border-top: none;
              }

              .itemRight {
                flex: 1;
                // width: 50px;
                // height: 55px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #7d7d7d;
                line-height: 20px;
                text-align: center;
                padding: 5px;
                border-top: 1px solid #7d7d7d;
              }
              .itemRight1 {
                border-top: none;
              }
            }
          }
          .basicBox1 {
            border: 1px solid #7d7d7d;
          }

          .title {
            // display: inline-block;
            width: 70px;
            text-align: right;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #313131;
            line-height: 20px;
          }

          .info {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7d7d7d;
            line-height: 20px;
          }

          .info1 {
            margin-right: 10px;
          }

          .changeBox {
            // width: 93px;
            height: 25px;
            background: #eb5050;
            border-radius: 2px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 25px;
            padding: 3px 8px;
            margin-left: 15px;
          }

          .money {
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #d23131;
            line-height: 28px;
          }
          .money1 {
            font-size: 16px;
            color: #7d7d7d;
          }

          .textBox {
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7d7d7d;
            text-decoration: line-through;
            margin-right: 10px;
          }

          .icon {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 600;
            color: #d23131;
            line-height: 20px;
          }
          .icon1 {
            font-size: 12px;
            color: #7d7d7d;
          }
        }
      }
    }

    .el-myselect {
      width: 100%;
    }

    .el-mytime {
      width: 108px;
    }
  }

  .additionalGoodsBox {
    .additionalGoodsContentBox {
      min-height: 48px;
      padding: 10px 15px;
      border: 1px solid #eeeeee;
      margin-top: 10px;
      .additionalGoodsItemBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #eeeeee;
        .leftBox {
          height: 48px;
          color: #7d7d7d;
          line-height: 48px;
        }
        .rightBox {
          color: #7d7d7d;
        }
      }

      .additionalGoodsItemBox1 {
        border-top: none;
      }
    }
    .additionalGoodsContentBox1 {
      height: 48px;
      color: #ccc;
      line-height: 48px;
      text-align: center;
      padding: 0 15px;
      border: 1px solid #eeeeee;
      margin-top: 10px;
    }
  }

  // 步骤条样式
  .el-steps {
    width: 750px;
    margin-left: 100px;
    margin-top: 20px;

    ::v-deep .el-step__icon {
      width: 39px !important;
      height: 39px !important;
      font-size: 26px;
      // color: #fff;
    }

    ::v-deep .el-step.is-horizontal .el-step__line {
      top: 19px;
    }
  }

  // 服务清单样式
  .el-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  .vehicleInspectionContent {
    .conItem {
      display: flex;
      margin-bottom: 10px;

      .uploadBox {
        position: relative;
        width: 65px;
        height: 65px;
        background: #ffffff;
        border: 1px solid #979797;
        border-radius: 5px;
        margin-right: 21px;

        .avatar-uploader .el-upload {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }

        .avatar-uploader .el-upload:hover {
          border-color: #409eff;
        }

        .avatar-uploader-icon {
          display: block;
          font-size: 12px;
          color: #8c939d;
          width: 65px;
          height: 65px;
          line-height: 65px;
          text-align: center;
        }

        .avatar {
          width: 65px;
          height: 65px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .colse {
          cursor: pointer;
          position: absolute;
          top: -9px;
          right: -9px;
          width: 18px;
          height: 18px;
          line-height: 18px;
          text-align: center;
          font-size: 12px;
          border-radius: 9px;
          background: #5f6679;
          color: #fff;
          z-index: 999;
        }
      }

      .inputBox {
        position: relative;
        // width: 380px;
        // height: 65px;
        // background: #ffffff;
        // border: 1px solid #cccccc;
        .delIconBox {
          cursor: pointer;
          position: absolute;
          top: 15px;
          right: 10px;
          width: 34px;
          height: 34px;
          border-radius: 17px;
          line-height: 34px;
          text-align: center;
          background: #f0f2f6;
        }

        ::v-deep.el-textarea {
          width: 380px;
          height: 65px !important;

          .el-textarea__inner {
            width: 380px;
            height: 65px !important;
            // border: none;
            // border-radius: 0;
            resize: none; /* 这个是去掉 textarea 下面拉伸的那个标志，如下图 */
            padding-right: 50px;
          }
        }
      }
    }
  }

  .serviceList {
    margin-bottom: 20px;

    .serviceName {
      // width: 28px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #313131;
      line-height: 20px;
      border-bottom: 1px solid #dddddd;
      padding-bottom: 10px;
    }

    .serviceItem {
      .el-checkbox {
        border-bottom: 1px solid #dddddd;
        margin-top: 10px;
        margin-right: 0;
        padding-bottom: 10px;
      }
    }
  }
}
</style>