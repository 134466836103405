<template>
  <div class="shopBasicInfo">
    <!-- 新增编辑店铺 -->
    <div class="shopDetail">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="店铺名称">
          <el-input
            v-model.trim="form.name"
            placeholder="请输入店铺名称，限制15字"
            maxlength="15"
          ></el-input>
        </el-form-item>
        <el-form-item label="店铺地址">
          <el-input
            v-model.trim="form.address"
            placeholder="请输入店铺地址，限制25字"
            maxlength="25"
            @blur="getGPS(form.address)"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="titleBox">
            <div class="div1Box">店铺地址前缀</div>
            <el-button
              class="btnBox"
              type="warning"
              size="mini"
              @click="addSite"
              >添加</el-button
            >
          </div>
          <div v-if="this.shopAddressPrefixes.length !== 0">
            <div
              class="contentBox"
              v-for="(item, index) in this.shopAddressPrefixes"
              :key="index"
            >
              <div class="div1Box div1Box2">
                <el-input
                  v-model="item.addressPrefix"
                  placeholder="请输入店铺地址前缀"
                  style="border: none; width: 90%; height: 90%; padding: 0"
                />
              </div>
            </div>
          </div>
          <div class="contentBox1" v-else>暂无信息</div>
        </el-form-item>
        <el-form-item label="店铺装饰">
          <!--          action="https://ledianchemei.zhihanyixing.com/api/file/upload"   -->
          <!--     action="https://manage.lediangroup.cn/api/file/upload"     -->
          <el-upload
            multiple
            style="display: flex"
            :before-upload="beforeUpload"
            :headers="{ token: token }"
            action="https://manage.lediangroup.cn/api/file/upload"
            list-type="picture-card"
            :on-success="handleSuccess"
            :data="{
              userId: 1,
            }"
          >
            <div slot="tip" class="el-upload__tip">
              ( 1.上传图片只能是 JPG、JPEG、PNG
              格式。2.上传文件大小不能超过1M。3.图片数量限制9张)
            </div>
            <i class="el-icon-plus"></i>
          </el-upload>
          <div class="myphoto" v-for="(item, index) in photoList" :key="index">
            <img :src="item.decorateUrl" alt="" srcset="" />
            <span v-show="item.isCover === 0" @click="toCover(item.decorateUrl)"
              >设为封面</span
            >
            <span v-show="item.isCover === 1" class="myCover">封面图</span>
            <div class="delimg" @click="delimg(item.decorateUrl)">
              <img src="../../assets/delGoods.png" alt="" />
            </div>
            <!-- <el-image
              style="width: 148px; height: 148px"
              :src="item.url"
              fit="contain"
            ></el-image> -->
          </div>

          <!-- <el-upload
            action="http://192.168.31.136:8991/file/upload"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload> -->
        </el-form-item>
        <el-form-item label="营业时间">
          <el-time-select
            @blur="selendTime = false"
            class="el-mytime"
            placeholder="请选择"
            v-model="form.businessTimeStart"
            :picker-options="{
              start: '06:30',
              step: '00:15',
              end: '23:30',
              maxTime: form.businessTimeEnd,
            }"
          >
          </el-time-select>
          至
          <el-time-select
            class="el-mytime"
            placeholder="请选择"
            v-model="form.businessTimeEnd"
            :picker-options="{
              start: '06:30',
              step: '00:15',
              end: '23:30',
              minTime: form.businessTimeStart,
            }"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item label="工位数量">
          <el-input v-model="form.count" disabled></el-input> </el-form-item
        ><el-form-item label="联系电话">
          <el-input
            v-model="form.telephone"
            placeholder="请输入联系电话"
            type="number"
            oninput="if(value.length > 5) value = value.slice(0,11)"
          ></el-input>
        </el-form-item>
        <el-form-item label="店铺状态">
          <el-select
            v-model="form.status"
            placeholder="请选择"
            clearable
            class="el-myselect"
          >
            <el-option
              v-for="item in shopStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="店铺介绍">
          <el-input
            type="textarea"
            autosize
            :rows="4"
            placeholder="请输入店铺介绍，限制150字"
            v-model="form.introduce"
            maxlength="150"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <!-- <el-button>取消</el-button> -->
          <el-button type="primary" @click="onSubmit">确定</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div
      id="container"
      style="width: 400px; height: 300px; display: none"
    ></div>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
window._AMapSecurityConfig = {
  securityJsCode: 'a0862136af5d8959ef4b4e681854e096'//在这里填写你的安全密钥
}

import { getShopBasicInfo, newShop, editShop } from '@/api/myshop/shop.js'
export default {
  name: 'shopBasicInfo',
  data() {
    return {
      map: null,//初始化 map 对象
      token: sessionStorage.getItem('token'),
      userId: sessionStorage.getItem('shopId'),
      selendTime: true,//营业时间选择
      form: {
        address: "",
        businessTimeEnd: "",
        businessTimeStart: "",
        count: 0,
        createTime: "",
        id: 0,
        introduce: "",
        name: "",
        status: null,
        telephone: "",
        longitude: '',//经度
        latitude: '',//维度
      },
      shopAddressPrefixes: [
        {
          addressPrefix: "",
        }
      ],
      photoList: [
        // {
        //   url: 'https://wap.cyberxauto.com/imessage/微信图片_20220516161934.jpg'
        // },
        // {
        //   url: 'https://wap.cyberxauto.com/imessage/IMG_4023(20220515-014854).JPG'
        // },
        // {
        //   url: 'https://wap.cyberxauto.com/imessage/微信图片_20220516161934.jpg'
        // },
        // {
        //   url: 'https://wap.cyberxauto.com/imessage/微信图片_20220516161934.jpg'
        // }
      ],
      shopStatus: [{
        value: 1,
        label: '营业中'
      }
        ,
      // {
      //   value: 2,
      //   label: '已打烊'
      // },
      {
        value: 3,
        label: '关店'
      },
      {
        value: 5,
        label: '开业在即，敬请期待'
      }]
    }
  },
  created() {
    // 获取店铺信息
    if (sessionStorage.getItem('shopId')) {
      this.getShopInfo(sessionStorage.getItem('shopId'))
    }

  },
  mounted() {
    //挂载阶段调用，DOM初始化完成进行地图初始化
    // this.initMap()
  },
  methods: {
    // 添加地址前缀
    addSite() {
      this.shopAddressPrefixes.push(
        {
          addressPrefix: ""
        }
      )
    },
    // 获得店铺信息
    async getShopInfo(val) {
      const res = await getShopBasicInfo({ shopId: val, userLatitude: 0, userLongitude: 0 })
      // console.log('res', res);
      if (res.status === 200) {
        this.form = res.data.shop
        this.photoList = res.data.photoList
        this.shopAddressPrefixes = res.data.shopAddressPrefixes
      }
    },
    // 计算地址经纬度
    getGPS(val) {
      // console.log('经纬度', this.map);
      var _this = this;								//指定this
      this.$axios({								//创建接口
        methods: 'get',							//类型为get请求
        url: `https://restapi.amap.com/v3/geocode/geo?address=${val}&output=JSON&key=4e9edd1cd51746f4f2d61bf01ff8d0b9`,	//请求的接口地址
      })
        .then((res) => {					//请求成功返回
          // console.log(res);
          if (res.data.info === 'OK') {
            this.form.longitude = res.data.geocodes[0].location.split(',')[0]//经度
            this.form.latitude = res.data.geocodes[0].location.split(',')[1]//纬度
            // console.log(this.form.longitude, this.form.latitude);
          } else {
            this.$message({
              type: 'warning',
              message: '请输入正确的地址',
              showClose: true
            })
            this.form.address = ''
            this.form.longitude = ''
            this.form.latitude = ''
          }      			//打印请求的数据
        })

      // this.map(val, function (status, result) {
      //   console.log(val, status, result);
      // })
      // AMap.Geocoder(val, function (status, result) {
      //   console.log(val, status, result);

      // })
      // Geocoder.getLocation(address, function (status, result) {
      //   console.log(val, status, result);

      // })
    },
    beforeUpload(file) {
      let sizeByMb = file.size / 1024 / 1024;
      if (sizeByMb > 1) {
        this.$message(
          {
            type: 'warning',
            message: '上传文件不能超过1M',
            showClose: true
          }
        );
        return false;
      }
      let types = ['image/jpeg', 'image/jpg', 'image/png'];
      const isImage = types.includes(file.type);
      if (!isImage) {
        // this.$message.error('');
        this.$message(
          {
            type: 'warning',
            message: '上传图片只能是 JPG、JPEG、PNG 格式!',
            showClose: true
          }
        );
        return false;
      }

      // if (file.type != 'image/jpeg' || 'image/jpg' || 'image/png') {
      //   this.$message(
      //     {
      //       type: 'warning',
      //       message: '只能上传图片',
      //       showClose: true
      //     }
      //   );
      //   return false;
      // }
      return true;
    },

    // 提交店铺装饰图
    handleSuccess(res, value, fileList) {
      // console.log('12312313', res);
      if (this.photoList.length < 9) {
        if (res.status === 200) {
          this.$message({
            showClose: true,
            type: 'success',
            message: '选择成功，请点击确定保存操作！'
          });
          this.photoList.push({ decorateUrl: res.data.url, isCover: 0 })
        } else {
          this.$message({
            showClose: true,
            type: 'warning',
            message: '上传失败' + res.msg
          });
        }
      } else {
        this.$message({
          type: 'warining',
          message: '最多上传9张图片',
          showClose: true
        })
      }

      // console.log('photo', fileList);
      // 重新加载数据
      // this.init();
    },
    //删除店铺装饰图
    delimg(val) {
      // console.log('del', val);
      const res = this.photoList.filter(item => {
        // console.log(val, item.decorateUrl);
        return item.decorateUrl !== val
      })
      this.photoList = res
      this.$message(
        {
          showClose: true,
          type: 'info',
          message: '已删除，请点击确定保存操作'
        }
      )
      // console.log('photo', this.photoList);
    },
    // 设为店铺封面
    toCover(val) {
      // console.log('tocover', val);
      this.$message({
        type: 'success',
        showClose: true,
        message: '已设为封面，请点击确定保存操作！'
      })
      this.photoList.map((item) => {
        if (item.decorateUrl === val) {
          item.isCover = 1

        } else {
          item.isCover = 0
        }
      })
      // console.log('photo', this.photoList);
    },
    //提交店铺信息
    async onSubmit() {
      // console.log(this.shop.businessTimeEnd, this.shop.businessTimeStart);
      // console.log('1');

      if (sessionStorage.getItem('shopId')) {
        this.$confirm('是否确认修改?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          console.log('2');

          if (!this.form.businessTimeEnd || !this.form.businessTimeStart) {
            console.log('shijian', this.form.businessTimeEnd, this.form.businessTimeStart);
            this.$message({
              showClose: true,
              type: 'warning',
              message: '请检查营业时间!'
            });
            return
          }
          if (this.photoList.length > 0) {
            const result = this.photoList.findIndex((item) => {
              return item.isCover === 1
            })
            if (result === -1) {
              this.photoList[0].isCover = 1
            }

          }
          const res = await editShop({ photoList: this.photoList, shop: this.form, shopAddressPrefixes: this.shopAddressPrefixes })
          // console.log('del', res);
          console.log('3');

          if (res.status === 200) {
            this.$message({
              showClose: true,
              type: 'success',
              message: '修改成功!'
            });

            // console.log('result', result);
          } else {
            this.$message({
              showClose: true,
              type: 'info',
              message: '修改失败!，请检查信息是否填写正确'
            });
          }
        }).catch(() => {
          // this.$message({
          //   showClose: true,
          //   type: 'info',
          //   message: '取消修改'
          // });
        });
      } else {
        // console.log('4');

        this.$confirm('是否确认新增?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          // console.log('5');

          if (this.photoList.length > 0) {
            const result = this.photoList.findIndex((item) => {
              return item.isCover === 1
            })
            if (result === -1) {
              this.photoList[0].isCover = 1
            }
          }
          const res = await newShop({ photoList: this.photoList, shop: this.form, shopAddressPrefixes: this.shopAddressPrefixes })
          // console.log('del', res);
          // console.log('6');

          if (res.status === 200) {
            this.$message({
              showClose: true,
              type: 'success',
              message: '新增成功!'
            });

            sessionStorage.setItem('shopId', res.data)
            this.$router.push('/home/addShop/shopBasicInfo')

          } else {
            this.$message({
              showClose: true,
              type: 'info',
              message: '新增失败!，请检查信息是否填写完整'
            });
          }
        }).catch(() => {
          // this.$message({
          //   showClose: true,
          //   type: 'info',
          //   message: '取消新增'
          // });
        });
      }
    },
  }
}
</script>

<style lang='scss' scoped>
.shopBasicInfo {
  width: 100%;
  min-height: 300px;
  ::v-deep .el-button {
    width: 70px;
    height: 30px;
    background: linear-gradient(270deg, #01df6e 0%, #2df891 50%, #01df6e 100%);
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    border: none;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 5px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .shopDetail {
    width: 800px;
    padding-top: 20px;
    .titleBox {
      position: relative;
    }
    .titleBox,
    .contentBox {
      width: 288px;
      display: flex;
      div {
        // flex: 1;
        width: 288px;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #7d7d7d;
        background: #ffffff;
        padding-left: 10px;
        box-sizing: border-box;
        border: 1px solid #dddddd;
        ::v-deep.el-input__inner {
          width: 100%;
          height: 100%;
          border: none;
        }
      }
      .div1Box {
        flex: 1;
        // border-right: none;
      }
      .div2Box {
        flex: 2;
      }
      .div1Box2 {
        border-top: none;
      }
      .btnBox {
        position: absolute;
        top: -27px;
        right: -90px;
        min-width: 80px;
        height: 30px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        background: linear-gradient(
          270deg,
          #e7710d 0%,
          #f6903a 50%,
          #e7710d 100%
        );
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
      }
    }
    .el-myselect {
      width: 40%;
    }
    .el-mytime {
      width: 128px;
    }
    .myphoto {
      display: inline-block;
      width: 100px;
      height: 100px;
      margin-right: 10px;
      margin-bottom: 10px;
      position: relative;
      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
      }
      span {
        display: inline-block;
        width: 100px;
        height: 20px;
        background: #fff4eb;
        border-radius: 4px;
        border: 1px solid #ee8539;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ee8539;
        line-height: 20px;
        text-align: center;
        cursor: pointer;
        opacity: 0.8;
        position: absolute;
        bottom: 0;
        left: 0;
        display: none;
      }
      .myCover {
        display: inline-block;
        width: 100px;
        height: 20px;
        background: #fff4eb;
        border-radius: 4px;
        border: 1px solid #ee8539;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ee8539;
        line-height: 20px;
        text-align: center;
        cursor: pointer;
        opacity: 0.8;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      .delimg {
        position: absolute;
        right: -12px;
        top: -10px;
        display: none;
        img {
          width: 25px;
          height: 25px;
          cursor: pointer;
        }
      }
    }
    .myphoto:hover .delimg {
      display: inline-block;
    }
    .myphoto:hover span {
      display: inline-block;
    }
  }
}
.el-input {
  width: 40%;
}
.el-textarea {
  width: 40% !important;
}
// 隐藏上传文件列表
::v-deep .el-upload-list--picture-card {
  display: none;
}
::v-deep .el-upload--picture-card {
  width: 45px !important;
  height: 45px !important;
  line-height: 55px !important;
  margin-bottom: 10px;
}
::v-deep .el-upload__tip {
  margin-top: 0px;
  margin-left: 10px;
  color: #bbb;
}
</style>