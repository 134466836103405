<template>
  <!-- 新增附加商品 -->
  <div class="additionalGoods">
    <!-- <el-menu
      :default-active="$route.path"
      text-color="#7D7D7D"
      active-text-color="#E3620B"
      mode="horizontal"
      :router="true"
    >
      <el-menu-item :index="addMenuList[0].path">基础信息</el-menu-item>
    </el-menu> -->
    <div class="additional">
      <el-form
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="medium"
        label-width="135px"
        label-position="right"
      >
        <el-form-item label="商品名称" prop="name">
          <el-input
            v-model="formData.name"
            placeholder="请输入商品名称"
            clearable
            :style="{ width: '30%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label="品牌" prop="brand">
          <el-input
            v-model="formData.brand"
            placeholder="请输入品牌"
            clearable
            :style="{ width: '30%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label="单价" prop="price">
          <el-input
            v-model="formData.price"
            placeholder="请输入单价"
            clearable
            :style="{ width: '30%' }"
          ></el-input>
        </el-form-item>
        <el-form-item size="large">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" :loading="loading" @click="submitForm"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { addAdditionalGoods, editAdditionalGoods, getAdditionalGoodsDetil } from '@/api/additionalGoodsManagement/index.js'
export default {
  name: 'additionalGoods',
  components: {},
  props: {},
  data() {
    return {
      additionalGoodsType: sessionStorage.getItem('additionalGoodsType'),
      additionalGoodsId: parseInt(sessionStorage.getItem('additionalGoodsId')), // 附加商品id
      addMenuList: [{ id: 1, title: '员工信息', name: 'first', path: '/home/additionalGoods' }],
      formData: {
        name: '',
        brand: '',
        price: ''
      },
      rules: {
        name: [{
          required: true,
          message: '请输入商品名称',
          trigger: 'blur'
        }],
        brand: [{
          required: true,
          message: '请输入品牌',
          trigger: 'blur'
        }],
        price: [{
          required: true,
          message: '请输入单价',
          trigger: 'blur'
        }],
      },
      loading: false,
    }
  },
  computed: {},
  watch: {},
  created() {
    if (this.additionalGoodsType === 'edit') {
      this.getAdditionalGoodsDetil();
    }
  },
  methods: {
    // 获取附加商品详情
    async getAdditionalGoodsDetil() {
      const res = await getAdditionalGoodsDetil(this.additionalGoodsId);
      // console.log(res);
      if (res.status === 200) {
        this.formData.name = res.data.name;
        this.formData.brand = res.data.brand;
        this.formData.price = res.data.price;
        this.formData.id = this.additionalGoodsId;
      }
    },
    // 确定按钮
    submitForm() {
      this.$refs['elForm'].validate(async valid => {
        if (!valid) return
        this.loading = true;
        // TODO 提交表单
        if (this.additionalGoodsType === 'add') {
          const res = await addAdditionalGoods(this.formData);
          // console.log(res);
          if (res.status === 200) {
            this.$message({
              showClose: true,
              message: '新增成功！',
              type: 'success'
            });
            this.loading = false;
            this.cancel()
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: 'error'
            });
            this.loading = false;
          }
        } else {
          const res = await editAdditionalGoods(this.formData);
          // console.log(res);
          if (res.status === 200) {
            this.$message({
              showClose: true,
              message: '编辑成功！',
              type: 'success'
            });
            this.loading = false;
            this.cancel()
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: 'error'
            });
            this.loading = false;
          }
        }
      })
    },
    // 取消按钮
    cancel() {
      this.$router.push('/home/additionalGoodsManagement')
    },
  }
}
</script>

<style lang="scss" scoped>
.additionalGoods {
  padding: 0 30px;
  .additional {
    // width: 320px;
    padding-top: 40px;
    min-height: 300px;
    ::v-deep .el-button {
      width: 70px;
      height: 30px;
      background: linear-gradient(
        270deg,
        #01df6e 0%,
        #2df891 50%,
        #01df6e 100%
      );
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      line-height: 5px;
      margin-top: 30px;
      margin-bottom: 20px;
    }
    .el-button--default {
      background: #f4fff5;
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      color: #01df6e;
      margin-right: 20px;
    }
    .el-myselect {
      width: 100%;
    }
    .el-mytime {
      width: 108px;
    }
  }
}
</style>
