<template>
  <!-- 权益朋友 -->
  <div class="myfriend">
    <div class="staff">
      <el-button type="primary" @click="addShow">新增</el-button>

      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="friendName" label="姓名"> </el-table-column>
        <el-table-column prop="telephone" label="联系电话"> </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- <span @click="edit(scope.row)" style="margin-right: 20px"
              >编辑</span
            > -->
            <span @click="del(scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 编辑弹窗 -->
    <el-dialog
      :title="diaTitle === 1 ? '新增权益朋友' : '编辑权益朋友'"
      :visible.sync="editShow"
      width="40%"
      :show-close="false"
    >
      <div class="new">
        <span>姓名</span>
        <el-input
          type="text"
          placeholder="请输入姓名"
          v-model="newStaff.customerName"
        ></el-input>
      </div>
      <div class="new">
        <span>联系电话</span>
        <el-input
          type="text"
          placeholder="请输入联系电话"
          v-model="newStaff.telephone"
        ></el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="small">取 消</el-button>
        <el-button type="primary" :loading="loading" size="small" @click="okBtn"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { addFriend, updateFriend, getSelectFriendByCustomerId, deleteFriend } from '@/api/customerManagement/customerManagement.js'
export default {
  name: 'myfriend',
  data() {
    return {
      customerId: parseInt(sessionStorage.getItem('customerId')),

      tableData: [], // 权益朋友列表数据
      diaTitle: 1,
      editShow: false,// 新增弹窗状态
      loading: false,
      newStaff: {
        customerId: parseInt(sessionStorage.getItem('customerId')),
        telephone: '',
        customerName: ''
      },// 新增权益朋友数据
    }
  },
  created() {
    if (this.customerId) {
      this.getSelectFriendByCustomerId();
    }
  },
  methods: {
    // 权益朋友列表数据
    async getSelectFriendByCustomerId() {
      const res = await getSelectFriendByCustomerId({ customerId: this.customerId });
      console.log(res);
      if (res.status === 200) {
        this.tableData = res.data;
      }
    },
    // 新增
    addShow() {
      this.diaTitle = 1;
      this.editShow = true;
    },
    // 编辑
    edit(val) {
      this.diaTitle = 2;
      this.editShow = true;
    },
    //删除
    del(val) {
      console.log('删除', val);
      this.$confirm('确认删除该地址?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // console.log('删除分类', val);
        const res = await deleteFriend({ id: parseInt(val.id) });
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '删除成功',
            type: 'success'
          });
          this.editShow = false;
          this.getSelectFriendByCustomerId();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 取消按钮
    cancel() {
      this.editShow = false;
    },
    // 确定按钮
    async okBtn() {
      this.loading = true;
      if (this.diaTitle === 1) {
        // console.log(this.newStaff);
        const res = await addFriend(this.newStaff);
        // console.log(res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '新增成功',
            type: 'success'
          });
          this.loading = false;
          this.editShow = false;
          this.getSelectFriendByCustomerId();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
          this.loading = false;
        }
      } else {
        const res = await updateFriend(this.newStaff);
        // console.log(res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '编辑成功',
            type: 'success'
          });
          this.loading = false;
          this.editShow = false;
          this.getSelectFriendByCustomerId();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
          this.loading = false;
        }
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.myfriend {
  width: 100%;
  .staff {
    width: 100%;
    margin-top: 40px;
    overflow: hidden;
    span {
      cursor: pointer;
    }
  }
  ::v-deep .el-button {
    width: 70px;
    height: 30px;
    background: linear-gradient(270deg, #01df6e 0%, #2df891 50%, #01df6e 100%);
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    border: none;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 5px;
    margin-bottom: 20px;
  }
  ::v-deep .el-button--default {
    background: #f4fff5;
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    color: #01df6e;
    margin-right: 20px;
  }
  .new {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    span {
      display: inline-block;
      width: 58px;
      text-align: right;
      margin-right: 10px;
    }
    .el-input {
      width: 80% !important;
    }
  }
}
</style>