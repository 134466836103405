<template>
  <!-- 积分使用记录 -->
  <div class="money">
    <div class="searchBar">
      <el-date-picker
        v-model="integralData.date"
        type="daterange"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd HH:mm:ss"
        :default-time="['00:00:00', '23:59:59']"
      >
      </el-date-picker>
      <button @click="queryBtn">查询</button>
      <!-- <button @click="add">新增</button> -->
    </div>

    <div class="staff">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="customerName" label="客户名称"> </el-table-column>
        <el-table-column prop="orderNumber" label="订单编号"> </el-table-column>
        <el-table-column prop="createdTime" label="获取时间"> </el-table-column>
        <el-table-column prop="primitiveIntegral" label="原积分">
        </el-table-column>
        <el-table-column prop="getIntegral" label="获取积分数">
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        v-model="integralData"
        @current-change="handleCurrentChange"
        :current-page.sync="integralData.page"
        :page-size="integralData.size"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getIntegralList } from '@/api/customerManagement/customerManagement.js'
export default {
  name: 'money',
  data() {
    return {
      integralData: {
        customerId: parseInt(sessionStorage.getItem('customerId')),
        page: 1,
        size: 3,
        date: [],
        orderNumber: ''
      },
      total: 0,
      tableData: [],// 积分使用记录列表数据
    }
  },
  created() {
    this.getIntegralList();
  },
  methods: {
    // 获取个人消费充值记录列表
    async getIntegralList() {
      const res = await getIntegralList(this.integralData);
      console.log(res);
      if (res.status === 200) {
        this.tableData = res.data.rows;
        this.total = res.data.total;
      }
    },
    // 查询
    queryBtn() {
      console.log(this.integralData);
      this.getIntegralList();
    },
    // 翻页
    handleCurrentChange(currentPage) {
      this.integralData.page = currentPage;
      this.getIntegralList();
    },
  }
}
</script>

<style lang='scss' scoped>
.money {
  width: 100%;
  .searchBar {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    button {
      width: 70px;
      height: 30px;
      background: linear-gradient(
        270deg,
        #01df6e 0%,
        #2df891 50%,
        #01df6e 100%
      );
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 30px;
      cursor: pointer;
      margin-top: 5px;
      margin-left: 15px;
    }
  }
  .block {
    text-align: center;
    padding-top: 20px;
  }
  .staff {
    width: 100%;
    margin-top: 40px;
    overflow: hidden;
    span {
      cursor: pointer;
    }
  }
  ::v-deep .el-button {
    width: 70px;
    height: 30px;
    background: linear-gradient(270deg, #01df6e 0%, #2df891 50%, #01df6e 100%);
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    border: none;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 5px;
    margin-bottom: 20px;
  }
  ::v-deep .el-button--default {
    background: #f4fff5;
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    color: #01df6e;
    margin-right: 20px;
  }
  .new {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    span {
      display: inline-block;
      width: 58px;
      text-align: right;
      margin-right: 10px;
    }
    .el-input {
      width: 80% !important;
    }
  }
}
</style>