<template>
  <div class="orderLogs">
    <div class="shopDetail">
      <div class="item" v-for="(item, index) in log" :key="index">
        <span class="t1" v-if="item.type === '客户'">客户</span>
        <span class="t4" v-else>商家</span>
        <span class="t2">客户评价了订单</span>
        <span class="t3">2022-2-22 18:00</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'orderLogs',
  data() {
    return {
      log: [
        {
          type: '客户',
          operate: '确认',
          action: '取车',
        },
        {
          type: '商家',
          operate: '确认',
          action: '取车',
        },
        {
          type: '客户',
          operate: '确认',
          action: '取车',
        }
      ]


    }
  },
  methods: {
    //提交店铺信息
    onSubmit() {
      console.log('submit!');
    },
  }
}
</script>

<style lang='scss' scoped>
.orderLogs {
  width: 100%;
  min-height: 300px;
  ::v-deep .el-button {
    width: 70px;
    height: 30px;
    line-height: 5px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .shopDetail {
    width: 320px;
    padding-top: 40px;
    .el-myselect {
      width: 100%;
    }
    .el-mytime {
      width: 108px;
    }
  }
  .t1 {
    display: inline-block;
    width: 35px;
    height: 19px;
    background: #15b0d4;
    border-radius: 2px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #fff;
    line-height: 19px;
    text-align: center;
    margin-right: 4px;
    margin-bottom: 30px;
  }
  .t4 {
    display: inline-block;
    width: 35px;
    height: 19px;
    background: rgb(255, 59, 0);
    border-radius: 2px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #fff;
    line-height: 19px;
    text-align: center;
    margin-right: 4px;
    margin-bottom: 30px;
  }
  .t2 {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #7d7d7d;
    line-height: 20px;
    margin-right: 40px;
  }
  .t3 {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #7d7d7d;
    line-height: 20px;
  }
}
</style>