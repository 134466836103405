<template>
  <div class="editCar">
    <!-- <el-menu
      :default-active="$route.path"
      text-color="#7D7D7D"
      active-text-color="#E3620B"
      mode="horizontal"
      :router="true"
    >
      <el-menu-item>车辆信息</el-menu-item>
    </el-menu> -->
    <div class="editContent">
      <el-form
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="medium"
        label-width="120px"
        label-position="right"
      >
        <el-form-item label="车牌号" prop="carNumber">
          <el-input
            v-model="formData.carNumber"
            placeholder="请输入车牌号"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="车辆分类" prop="carClassificationId">
          <el-cascader
            v-model="formData.carClassificationId"
            :options="carClassificationIdOptions"
            :props="carClassificationIdProps"
            placeholder="请选择车辆分类"
            @change="handleChange"
            clearable
          >
          </el-cascader>
        </el-form-item>
        <!-- <el-form-item label="客户" prop="customerIdList">
          <el-select
            v-model="formData.customerIdList"
            placeholder="请选择客户"
            multiple
            clearable
          >
            <el-option
              v-for="(item, index) in customerIdListOptions"
              :key="index"
              :label="item.userName"
              :value="item.id"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <div class="titleBox">
            <div class="div1Box">客户</div>
            <div class="div2Box">手机号</div>
            <div class="div2Box div3Box" v-if="carId">客户编号</div>
            <el-button
              class="btnBox"
              type="warning"
              size="mini"
              @click="addSite"
              >添加</el-button
            >
          </div>
          <div v-if="this.formData.customerIdList.length !== 0">
            <div
              class="contentBox"
              v-for="(item, index) in this.formData.customerIdList"
              :key="index"
            >
              <div class="div1Box div1Box2">
                <el-select
                  v-model="item.customerId"
                  placeholder="请选择客户"
                  clearable
                  @change="customerChange(item)"
                >
                  <el-option
                    v-for="(item1, index) in customerIdListOptions"
                    :key="index"
                    :label="item1.userName"
                    :value="item1.id"
                  ></el-option>
                </el-select>
              </div>
              <div class="div1Box2 div2Box">
                <el-input
                  v-model="item.telephone"
                  placeholder="请输入手机号"
                  maxlength="8"
                  disabled
                  style="border: none; width: 90%; height: 90%; padding: 0"
                />
              </div>
              <div class="div1Box2 div2Box div3Box" v-if="carId">
                {{ item.number }}
                <el-button
                  v-if="carId"
                  class="btnBox1"
                  type="warning"
                  size="mini"
                  @click="seeSite(item)"
                  >查看</el-button
                >
              </div>
            </div>
          </div>
          <div class="contentBox1" v-else>暂无信息</div>
        </el-form-item>

        <el-form-item label="驾驶证信息："></el-form-item>
        <el-form-item label="号牌号码" prop="numberPlate">
          <el-input
            v-model="formData.license.numberPlate"
            placeholder="请输入号牌号码"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="车辆类型" prop="type">
          <el-select
            v-model="formData.license.type"
            placeholder="请选择车辆类型"
            clearable
          >
            <el-option
              v-for="(item, index) in typeOptions"
              :key="index"
              :label="item.name"
              :value="item.name"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所有人" prop="licenseAll">
          <el-input
            v-model="formData.license.licenseAll"
            placeholder="请输入所有人"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="住址" prop="licenseAddress">
          <el-input
            v-model="formData.license.licenseAddress"
            placeholder="请输入住址"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="使用性质" prop="natureOfUse">
          <el-select
            v-model="formData.license.natureOfUse"
            placeholder="请选择使用性质"
            clearable
          >
            <el-option
              v-for="(item, index) in natureOfUseOptions"
              :key="index"
              :label="item.name"
              :value="item.name"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车辆识别代码" prop="carCode">
          <el-input
            v-model="formData.license.carCode"
            placeholder="请输入车辆识别代码"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="发动机号码" prop="engineNumber">
          <el-input
            v-model="formData.license.engineNumber"
            placeholder="请输入发动机号码"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="注册日期" prop="dateOfRegistration">
          <el-date-picker
            v-model="formData.license.dateOfRegistration"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="请选择注册日期"
            clearable
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="发证日期" prop="dateOfIssue">
          <el-date-picker
            v-model="formData.license.dateOfIssue"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="请选择发证日期"
            clearable
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="档案编号" prop="fileNo">
          <el-input
            v-model="formData.license.fileNo"
            placeholder="请输入档案编号"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="核定载人数" prop="authorizedSeatingCapacity">
          <el-input
            v-model="formData.license.authorizedSeatingCapacity"
            placeholder="请输入核定载人数"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="总质量" prop="totalMass">
          <el-input
            v-model="formData.license.totalMass"
            placeholder="请输入总质量"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="整备质量" prop="curbWeight">
          <el-input
            v-model="formData.license.curbWeight"
            placeholder="请输入整备质量"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="核定载质量" prop="approvedLoadCapacity">
          <el-input
            v-model="formData.license.approvedLoadCapacity"
            placeholder="请输入核定载质量"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="外廓尺寸">
          <el-input
            v-model="formData.license.lengthOfVeranda"
            clearable
            placeholder="长"
            :style="{ width: '28%' }"
          >
          </el-input>
          <span class="shBox">&nbsp; X &nbsp;</span>
          <el-input
            v-model="formData.license.widthOfVeranda"
            clearable
            placeholder="宽"
            :style="{ width: '28%' }"
          >
          </el-input>
          <span class="shBox">&nbsp; X &nbsp;</span>
          <el-input
            v-model="formData.license.heightOfVeranda"
            clearable
            placeholder="高"
            :style="{ width: '29%' }"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="准牵引总质量" prop="totalQuasiTractionMass">
          <el-input
            v-model="formData.license.totalQuasiTractionMass"
            placeholder="请输入准牵引总质量"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input
            v-model="formData.license.remarks"
            placeholder="请输入备注"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="检验记录" prop="inspectionRecord">
          <el-input
            v-model="formData.license.inspectionRecord"
            placeholder="请输入检验记录"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item class="btnBox" size="large">
          <el-button class="resBox" @click="resetForm">取消</el-button>
          <el-button
            type="primary"
            class="subBox"
            @click="submitForm"
            :loading="loading"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { addCar, getSelectCarClass, getSelectAllCustomer, getSelectById, updateCar, getSelectCustomerById } from '@/api/carManagement/carManagement.js'
export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
      carId: parseInt(sessionStorage.getItem('carId')),
      addMenuList: [{ id: 1, title: '车辆信息', name: 'first', path: '/home/editCar' }],
      loading: false,
      formData: {
        carClassificationId: null,
        carNumber: "",
        customerIdList: [],
        license: {
          licenseAddress: "",
          licenseAll: "",
          approvedLoadCapacity: "",
          authorizedSeatingCapacity: "",
          brandModel: "",
          carCode: "",
          carId: null,
          createTime: "",
          curbWeight: "",
          dateOfIssue: "",
          dateOfRegistration: "",
          engineNumber: "",
          fileNo: "",
          heightOfVeranda: "",
          id: null,
          inspectionRecord: "",
          lengthOfVeranda: "",
          natureOfUse: "",
          numberPlate: "",
          remarks: "",
          totalMass: "",
          totalQuasiTractionMass: "",
          type: "",
          widthOfVeranda: ""
        }
      },
      rules: {
        carNumber: [{
          required: true,
          message: '请输入车牌号',
          trigger: 'blur'
        }, {
          pattern: /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-HJ-NP-Z][A-HJ-NP-Z0-9]{4,5}[A-HJ-NP-Z0-9挂学警港澳]$/,
          message: '请输入正确的车牌号格式',
          trigger: 'blur'
        }],
        customerIdList: [{
          required: true,
          type: 'array',
          message: '请选择客户',
          trigger: 'change'
        }],
        carClassificationId: [{
          required: true,
          message: '请选择车辆分类',
          trigger: 'change'
        }],
        // numberPlate: [{
        //   required: true,
        //   message: '请输入号牌号码',
        //   trigger: 'blur'
        // }],
        // type: [{
        //   required: true,
        //   message: '请选择车辆类型',
        //   trigger: 'change'
        // }],
        // licenseAll: [{
        //   required: true,
        //   message: '请输入所有人',
        //   trigger: 'blur'
        // }],
        // licenseAddress: [{
        //   required: true,
        //   message: '请输入住址',
        //   trigger: 'blur'
        // }],
        // natureOfUse: [{
        //   required: true,
        //   message: '请选择使用性质',
        //   trigger: 'change'
        // }],
        // carCode: [{
        //   required: true,
        //   message: '请输入车辆识别代码',
        //   trigger: 'blur'
        // }],
        // engineNumber: [{
        //   required: true,
        //   message: '请输入发动机号码',
        //   trigger: 'blur'
        // }],
        // dateOfRegistration: [{
        //   required: true,
        //   message: '请选择注册日期',
        //   trigger: 'change'
        // }],
        // dateOfIssue: [{
        //   required: true,
        //   message: '请选择发证日期',
        //   trigger: 'change'
        // }],
        // fileNo: [{
        //   required: true,
        //   message: '请输入档案编号',
        //   trigger: 'blur'
        // }],
        // authorizedSeatingCapacity: [{
        //   required: true,
        //   message: '请输入核定载人数',
        //   trigger: 'blur'
        // }],
        // totalMass: [{
        //   required: true,
        //   message: '请输入总质量',
        //   trigger: 'blur'
        // }],
        // curbWeight: [{
        //   required: true,
        //   message: '请输入整备质量',
        //   trigger: 'blur'
        // }],
        // approvedLoadCapacity: [{
        //   required: true,
        //   message: '请输入核定载质量',
        //   trigger: 'blur'
        // }],
        // totalQuasiTractionMass: [{
        //   required: true,
        //   message: '请输入准牵引总质量',
        //   trigger: 'blur'
        // }],
        // inspectionRecord: [{
        //   required: true,
        //   message: '请输入检验记录',
        //   trigger: 'blur'
        // }],
      },
      carClassificationIdOptions: [], // 车辆分类数据
      customerIdListOptions: [], // 客户数据
      carClassificationIdProps: {
        value: 'id',
        children: 'children',
        label: 'name',
        emitPath: false
      },
      typeOptions: [
        {
          id: 1,
          name: '微型载货（客）汽车'
        },
        {
          id: 2,
          name: '小型载货（客）汽车'
        },
        {
          id: 3,
          name: '中型载货（客）汽车'
        },
        {
          id: 4,
          name: '大型/重型载货（客）汽车'
        },
        {
          id: 5,
          name: '挂车'
        },
        {
          id: 6,
          name: '三轮汽车'
        },
        {
          id: 7,
          name: '低速货车'
        },
        {
          id: 8,
          name: '摩托车'
        },
      ], // 车辆类型数据
      natureOfUseOptions: [
        {
          id: 1,
          name: '家庭自用汽车'
        },
        {
          id: 2,
          name: '非营业客车'
        },
        {
          id: 3,
          name: '营业客车'
        },
        {
          id: 4,
          name: '非营业货车'
        },
        {
          id: 5,
          name: '营业货车'
        },
      ], // 使用性质数据
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getSelectCarClass();
    this.getSelectAllCustomer();
    if (this.carId) {
      this.getSelectById();
    }
  },
  methods: {
    // 根据id查看详情
    async getSelectById() {
      const res = await getSelectById({ carId: this.carId });
      // console.log(res);
      if (res.status === 200) {
        this.formData = {
          carClassificationId: res.data.carClassId,
          carNumber: res.data.carNumber,
          carId: this.carId,
          customerIdList: res.data.userVoList,
          license: {
            licenseAddress: res.data.licenseAddress,
            licenseAll: res.data.licenseAll,
            approvedLoadCapacity: res.data.approvedLoadCapacity,
            authorizedSeatingCapacity: res.data.authorizedSeatingCapacity,
            brandModel: res.data.brandModel,
            carCode: res.data.carCode,
            carId: this.carId,
            createTime: res.data.createTime,
            curbWeight: res.data.curbWeight,
            dateOfIssue: res.data.dateOfIssue,
            dateOfRegistration: res.data.dateOfRegistration,
            engineNumber: res.data.engineNumber,
            fileNo: res.data.fileNo,
            heightOfVeranda: res.data.heightOfVeranda,
            id: res.data.licenseId,
            inspectionRecord: res.data.inspectionRecord,
            lengthOfVeranda: res.data.lengthOfVeranda,
            natureOfUse: res.data.natureOfUse,
            numberPlate: res.data.numberPlate,
            remarks: res.data.remarks,
            totalMass: res.data.totalMass,
            totalQuasiTractionMass: res.data.totalQuasiTractionMass,
            type: res.data.type,
            widthOfVeranda: res.data.widthOfVeranda
          }
        };
        // res.data.userVoList.forEach(item => {
        //   if (item.gender === 1) {
        //     item.userName = item.userName + '  先生'
        //   } else if (item.gender === 2) {
        //     item.userName = item.userName + '  女士'
        //   }
        // })
        // console.log(this.formData);
      }
    },
    // 获取客户手机号
    async customerChange(val) {
      // console.log(val);
      if (val.customerId) {
        const res = await getSelectCustomerById({ customerId: val.customerId });
        // console.log(res);
        if (res.status === 200) {
          val.telephone = res.data.telephone;
          val.number = res.data.number;
          this.$forceUpdate();
        }
      } else {
        // console.log('1');
        val.telephone = '';
        this.$forceUpdate();
      }
      // console.log(this.formData.customerIdList);
    },
    // 添加车辆
    addSite() {
      this.formData.customerIdList.push(
        {
          carClassificationId: 0,
          carNumber: "",
          number: ''
        }
      )
    },
    // 查看客户详情
    seeSite(val) {
      // console.log(val);
      sessionStorage.setItem('checkEdit', 'edit');
      sessionStorage.setItem('customerId', val.customerId);
      this.$router.push('/home/detailTabs/customerDetail');
    },
    // 车辆分类数据
    async getSelectCarClass() {
      const res = await getSelectCarClass();
      // console.log(res);
      if (res.status === 200) {
        this.carClassificationIdOptions = res.data;
      }
      // console.log(this.carClassificationIdOptions);
      this.carClassificationIdOptions.forEach(item => {
        if (item.children.length === 0) {
          item.children = undefined;
        } else {
          item.children.forEach(item1 => {
            if (item1.children.length === 0) {
              item1.children = undefined;
            }
          })
        }
      });
    },
    // 级联选择
    handleChange(val) {
      // console.log(val);
    },
    // 所有客户数据
    async getSelectAllCustomer() {
      const res = await getSelectAllCustomer();
      // console.log(res);
      if (res.status === 200) {
        this.customerIdListOptions = res.data;
        this.customerIdListOptions.forEach(item => {
          if (item.gender === 1) {
            item.userName = item.userName + '  先生'
          } else if (item.gender === 2) {
            item.userName = item.userName + '  女士'
          }
        })
      }
    },
    submitForm() {
      this.$refs['elForm'].validate(async valid => {
        if (!valid) return
        this.loading = true;
        // TODO 提交表单
        const carIdList = [];
        this.formData.customerIdList.forEach(item => {
          if (item.customerId) {
            carIdList.push(item.customerId)
          }
        })
        this.formData.customerIdList = carIdList;
        // console.log(this.formData);
        // 新增
        if (!this.carId) {
          const res = await addCar(this.formData);
          // console.log(res);
          if (res.status === 200) {
            this.$message({
              showClose: true,
              message: '新增成功',
              type: 'success'
            });
            this.loading = false;
            this.$router.push('/home/carCategary')
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: 'error'
            });
            this.loading = false;
          }
        } else {
          // 编辑
          const res = await updateCar(this.formData);
          // console.log(res);
          if (res.status === 200) {
            this.$message({
              showClose: true,
              message: '编辑成功',
              type: 'success'
            });
            this.loading = false;
            this.$router.push('/home/carCategary');
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: 'error'
            });
            this.loading = false;
          }

        }
      })
    },
    // 取消
    resetForm() {
      this.$router.push('/home/carCategary');
    },
  }
}
</script>

<style lang="scss" scoped>
.editCar {
  padding: 0px 30px;
  // padding-top: 10px;
  box-sizing: border-box;
  height: 100%;
  ::v-deep.el-cascader,
  .el-select,
  .el-date-editor {
    width: 100%;
  }
  .titleBox {
    position: relative;
  }
  .titleBox,
  .contentBox {
    display: flex;
    div {
      // flex: 1;
      // width: 260px;
      height: 36px;
      line-height: 36px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #7d7d7d;
      background: #ffffff;
      padding-left: 10px;
      box-sizing: border-box;
      border: 1px solid #dddddd;
      ::v-deep.el-input__inner {
        width: 100%;
        height: 100%;
        border: none;
      }
    }
    .div1Box {
      flex: 1;
      border-right: none;
      ::v-deep.el-select {
        border: none;
        height: 90%;
        .el-input {
          height: 100%;
          .el-input__inner {
            height: 100% !important;
          }
        }
      }
    }
    .div2Box {
      flex: 1;
    }
    .div3Box {
      border-left: none;
    }
    .div1Box2 {
      position: relative;
      border-top: none;
      .btnBox1 {
        position: absolute;
        top: 3px;
        right: -90px;
        min-width: 80px;
        height: 30px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        background: linear-gradient(
          270deg,
          #e7710d 0%,
          #f6903a 50%,
          #e7710d 100%
        );
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
      }
    }
    .btnBox {
      position: absolute;
      top: 3px;
      right: -90px;
      min-width: 80px;
      height: 30px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      background: linear-gradient(
        270deg,
        #e7710d 0%,
        #f6903a 50%,
        #e7710d 100%
      );
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      margin-top: 0 !important;
    }
  }
  .contentBox1 {
    // width: 520px;
    width: 100%;
    height: 36px;
    line-height: 36px;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #dddddd;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    border-top: none;
  }
  ::v-deep.el-tabs__header {
    margin: 0 !important;
  }
  .el-menu {
    position: sticky;
    top: 0;
    z-index: 6;
  }
  .el-menu--horizontal > .el-menu-item {
    padding: 0;
    margin-right: 45px;
  }
  .editContent {
    width: 520px;
    margin-top: 40px;
    padding-bottom: 40px;
    .shBox {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7d7d7d;
    }
    .btnBox {
      margin-top: 50px;
      ::v-deep .el-form-item__content {
        margin-left: 14% !important;
        .el-button {
          width: 70px;
          height: 30px;
          background: linear-gradient(
            270deg,
            #01df6e 0%,
            #2df891 50%,
            #01df6e 100%
          );
          box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
          border-radius: 2px;
          border: none;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          line-height: 5px;
          margin-bottom: 20px;
        }
        .resBox {
          margin-left: 80px;
        }
        .el-button--default {
          background: #f4fff5;
          box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
          border-radius: 3px;
          color: #01df6e;
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
