<template>
  <!-- 员工信息 -->
  <div class="staffInfo">
    <div class="staff">
      <el-button type="primary" @click="mybutton">新增</el-button>

      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="userName" label="姓名"> </el-table-column>
        <el-table-column prop="idNumber" label="身份证号"> </el-table-column>
        <el-table-column prop="jobNo" label="工号"> </el-table-column>
        <el-table-column prop="roleName" label="职位">
          <template slot-scope="scope">
            <div v-if="scope.row.roleVoList && scope.row.roleVoList.length > 0">
              {{ scope.row.roleVoList[0].roleName }}
            </div>
            <div v-else style="color: #ccc;">暂无职位</div>
          </template>
        </el-table-column>
        <el-table-column prop="telephone" label="联系电话"> </el-table-column>
        <!-- <el-table-column label="权限状态">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              active-color="#5F6679"
              inactive-color="#B3B3B3"
            >
            </el-switch>
          </template>
        </el-table-column> -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span @click="del(scope.row.shopStaffId)">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 新增员工弹窗 -->
    <el-dialog
      title="新增店铺员工"
      :visible.sync="add"
      width="30%"
      :show-close="false"
    >
      <div class="new">
        <span>姓名</span>
        <el-select
          v-model="newStaff.userId"
          filterable
          placeholder="请输入名称"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="`${item.name}--身份证号（${item.idNumber}）`"
            :value="item.userId"
          >
          </el-option>
        </el-select>
      </div>
      <!-- <div class="new">
        <span>工位号</span>
        <el-input
          type="text"
          placeholder="请输入工位号"
          v-model="newStaff.jobNum"
        ></el-input>
      </div> -->
      <!-- <div class="new">
        <span>职位</span>
        <el-select
          v-model="newStaff.roleId"
          placeholder="请选择员工职位"
          clearable
          class="el-myselect"
        >
          <el-option
            v-for="item in positions"
            :key="item.userId"
            :label="item.roleName"
            :value="item.roleId"
          >
          </el-option>
        </el-select>
      </div> -->
      <!-- <div class="new">
        <span>权限状态</span>
        <el-switch
          v-model="newStaff.status"
          active-color="#5F6679"
          inactive-color="#B3B3B3"
        >
        </el-switch>
      </div> -->

      <span slot="footer" class="dialog-footer">
        <el-button @click="close" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="confirm"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getShopStaffs, getAllShopStaffs, getRole, newStaff, delStaff } from '@/api/myshop/shop.js'
export default {
  name: 'staffInfo',
  data() {
    return {
      //职位列表
      tableData: [
      ],//员工列表数据
      add: false,//新增员工弹窗状态
      newStaff: {
        userId: '',
        roleId: '',
        position: '',
        status: false
      },//新增员工数据
      positions: [

      ],
      options: [

      ]
    }
  },
  created() {
    // 获取店铺店员列表
    if (sessionStorage.getItem('shopId')) {
      this.getStaffs(sessionStorage.getItem('shopId'))
      this.getAllShopStaff()//获取可选择员工
      this.getRole()//获取可选择职位
    }
  },
  methods: {
    // 获取该店铺店员列表
    async getStaffs(val) {
      const res = await getShopStaffs({ shopId: val })
      // console.log('res1111', res);
      if (res.status === 200) {
        this.tableData = res.data
      }
    },
    // 获取新增员工列表
    async getAllShopStaff() {
      const data = {
        data: {
          roleId: null,
          shopName: "",
          userName: ""
        },
        page: 1,
        size: 1000000,
        start: 0
      }
      const res = await getAllShopStaffs(data)
      // console.log('res1', res);
      this.options = res.data.rows

    },
    // 获取可选择角色
    async getRole() {
      const res = await getRole()
      // console.log('role', res);
      this.positions = res.data
    },
    //新增店员
    mybutton() {
      if (!sessionStorage.getItem('shopId')) {
        this.$message({
          showClose: true,
          type: 'info',
          message: '请先完善店铺基础信息'
        })
      } else {
        this.add = true
        this.newStaff = {
          userId: '',
          roleId: '',
          position: '',
          status: false
        }
      }
    },
    //删除店员
    async del(val) {
      this.$confirm("是否从该店铺删除该员工?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const res = await delStaff({ shopStaffId: val })
        if (res.status === 200) {
          this.$message({
            showClose: true,
            type: "success",
            message: "删除成功!",
          });
          this.getStaffs(sessionStorage.getItem('shopId'))
        } else {
          this.$message({
            showClose: true,
            type: "info",
            message: "删除失败!" + res.msg,
          });
        }
      })
      // console.log('删除', res);
    },
    // 取消弹窗
    close() {
      this.add = false
      this.newStaff = {
        userId: '',
        roleId: '',
        position: '',
        status: false
      }
    },
    // 提交新增信息
    async confirm() {
      const info = {
        shopId: Number(sessionStorage.getItem('shopId')),
        userId: this.newStaff.userId
      }
      const res = await newStaff(info)
      // console.log('11111', res);
      if (res.status === 200) {
        this.$message({
          type: 'success',
          message: '新增成功！',
          showClose: true
        })
        this.add = false
        this.newStaff = {
          userId: '',
          roleId: '',
          position: '',
          status: false
        }
        this.getStaffs(sessionStorage.getItem('shopId'))

      } else {
        this.$message({
          type: 'warning',
          message: '新增失败！' + res.msg,
          showClose: true
        })
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.staffInfo {
  width: 100%;
  .staff {
    width: 100%;
    margin-top: 40px;
    overflow: hidden;
    span {
      cursor: pointer;
    }
  }
  ::v-deep .el-button {
    width: 70px;
    height: 30px;
    background: linear-gradient(270deg, #01df6e 0%, #2df891 50%, #01df6e 100%);
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    border: none;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 5px;
    margin-bottom: 20px;
  }
  .new {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    span {
      display: inline-block;
      width: 58px;
      text-align: right;
      margin-right: 10px;
    }
    .el-select {
      width: 100%;
    }
    .el-input {
      width: 80% !important;
    }
  }
}
</style>