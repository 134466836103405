<template>
  <!-- 附加商品记录 -->
  <div class="additionalGoodsRecord">
    <div class="searchBar">
      <el-input
        v-model="recordData.additionalGoodsName"
        clearable
        placeholder="请输入商品名称"
      />
      <el-select
        v-model="recordData.shopId"
        placeholder="请选择店铺"
        clearable
        @change="queryBtn"
      >
        <el-option
          v-for="item in shopOptions"
          :key="item.shopId"
          :label="item.shopName"
          :value="item.shopId"
        >
        </el-option>
      </el-select>
      <el-date-picker
        v-model="recordData.time"
        type="daterange"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd HH:mm:ss"
        :default-time="['00:00:00', '23:59:59']"
      >
      </el-date-picker>
      <button @click="queryBtn">查询</button>
      <!-- <button @click="add">新增</button> -->
    </div>

    <div class="staff">
      <el-table :data="recordList" border style="width: 100%">
        <el-table-column prop="orderNumber" label="订单编号" width="180">
        </el-table-column>
        <el-table-column prop="name" label="商品名称"> </el-table-column>
        <el-table-column prop="shopName" label="店铺名称" width="150">
        </el-table-column>
        <el-table-column prop="userName" label="操作人名称"> </el-table-column>
        <el-table-column prop="number" label="数量"> </el-table-column>
        <el-table-column prop="createdTime" label="消费时间"> </el-table-column>
        <!-- <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <el-button @click="edit(scope.row)" type="text" size="small"
              >编辑</el-button
            >
            <el-button type="text" size="small" @click="del(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column> -->
      </el-table>
      <div class="block">
        <el-pagination
          v-model="recordData"
          @current-change="handleCurrentChange"
          :current-page.sync="recordData.page"
          :page-size="recordData.size"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getAdditionalGoodsRecordList, getSelectAllShop } from '@/api/additionalGoodsManagement/index.js'
export default {
  name: 'carConsumption',
  data() {
    return {
      recordData: {
        page: 1,
        size: 10,
        additionalGoodsId: parseInt(sessionStorage.getItem('additionalGoodsId')),
        additionalGoodsName: '',
        orderNumber: '',
        shopId: null,
        time: [],
        userId: null,
        userName: ''
      },
      total: 0,
      recordList: [],// 记录列表数据
      shopOptions: [], // 店铺列表
    }
  },
  created() {
    this.getAdditionalGoodsRecordList();
    this.getSelectAllShop();
  },
  methods: {
    // 查看所有店铺不分页
    async getSelectAllShop() {
      const res = await getSelectAllShop({
        object: ''
      });
      // console.log(res);
      if (res.status === 200) {
        this.shopOptions = res.data;
      }
    },
    // 获取车辆消费记录列表
    async getAdditionalGoodsRecordList() {
      const res = await getAdditionalGoodsRecordList(this.recordData);
      // console.log(res);
      if (res.status === 200) {
        this.recordList = res.data.rows;
        this.total = res.data.total;
      }
    },
    // 查询
    queryBtn() {
      this.recordData.page = 1;
      // console.log(this.recordData);
      this.getAdditionalGoodsRecordList();
    },
    // 翻页
    handleCurrentChange(currentPage) {
      this.recordData.page = currentPage;
      this.getAdditionalGoodsRecordList();
    },
    // 新增
    add() { },
    // 编辑
    edit() { },
    // 删除
    del() {

    }
  }
}
</script>

<style lang='scss' scoped>
.additionalGoodsRecord {
  width: 100%;
  .searchBar {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    // 输入框和选择框大小
    .el-input {
      width: 210px !important;
      margin-right: 10px !important;
    }
    .el-select {
      margin-right: 15px;
    }
    button {
      width: 70px;
      height: 30px;
      background: linear-gradient(
        270deg,
        #01df6e 0%,
        #2df891 50%,
        #01df6e 100%
      );
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 30px;
      cursor: pointer;
      margin-top: 5px;
      margin-left: 15px;
    }
  }
  .block {
    text-align: center;
    padding-top: 20px;
  }
  .staff {
    width: 100%;
    margin-top: 40px;
    overflow: hidden;
    span {
      cursor: pointer;
    }
    .mycell {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      // justify-content: end;
      img {
        width: 19px;
        height: 16px;
        margin-right: 3px;
      }
      span {
        display: inline-block;
      }
      .isEdit {
        display: inline-block;
        width: 24px;
        height: 16px;
        background: #eb5050;
        border-radius: 2px;

        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 17px;
        text-align: center;
        margin-left: 10px;
      }
      .orderStatusIng {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4995f5;
        line-height: 20px;
      }
      .orderStatusIng::before {
        content: "";
        display: inline-block;
        width: 14px;
        height: 14px;
        background: #4995f5;
        border-radius: 50%;
        vertical-align: top;
        margin-right: 2px;
        margin-top: 3px;
      }
      .orderStatusFinish {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #36cb0a;
        line-height: 20px;
      }
      .orderStatusFinish::before {
        content: "";
        display: inline-block;
        width: 14px;
        height: 14px;
        background: #36cb0a;
        border-radius: 50%;
        vertical-align: top;
        margin-right: 2px;
        margin-top: 3px;
      }
      .orderStatusCancel {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #acacac;
        line-height: 20px;
      }
      .orderStatusCancel::before {
        content: "";
        display: inline-block;
        width: 14px;
        height: 14px;
        background: #acacac;
        border-radius: 50%;
        vertical-align: top;
        margin-right: 2px;
        margin-top: 3px;
      }
    }
    .mycell1 {
      justify-content: end;
    }
  }
  .new {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    span {
      display: inline-block;
      width: 58px;
      text-align: right;
      margin-right: 10px;
    }
    .el-input {
      width: 80% !important;
    }
  }
}
</style>