<template>
  <!-- 工位管理 -->
  <div class="siteManagement">
    <div class="sites">
      <div class="searchBar">
        <el-select
          v-model="searchSitesInfo.status"
          placeholder="请选择工位状态"
          clearable
        >
          <el-option
            v-for="item in siteStatus"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="searchSitesInfo.type"
          placeholder="请选择工种"
          clearable
        >
          <el-option
            v-for="item in type"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          placeholder="请输入工位名"
          prefix-icon="el-icon-search"
          v-model="searchSitesInfo.name"
          clearable
        >
        </el-input>

        <button @click="searchSites">查询</button>
        <button @click="add()">新增</button>
      </div>

      <div class="siteArea" v-if="site.length > 0">
        <div class="item" v-for="(item, index) in site" :key="index">
          <div class="siteStatus">
            <div class="order" v-if="item.type === 1">洗车工位</div>
            <div class="order" v-if="item.type === 2">美容工位</div>
            <div class="order">
              {{ item.name }}
            </div>
            <div class="using" v-if="item.status == 1">使用中</div>
            <div class="free" v-if="item.status == 2">空闲中</div>
            <div class="free" v-if="item.status == 3">关闭</div>
            <div class="edit" @click="editSite(item)">
              <img src="../../assets/siteEdit.png" alt="" />
            </div>
            <div class="del" @click="delSite(item)">
              <img src="../../assets/cancel.png" alt="" />
            </div>
          </div>
          <div class="detail">
            <div class="des">
              <div class="left">服务项目：</div>
              <div class="right">{{ item.serviceProjectName }}</div>
            </div>
            <div class="des">
              <div class="left">服务方式：</div>
              <div class="right" v-if="item.serviceModel === 1">到店洗车</div>
              <div class="right" v-if="item.serviceModel === 2">上门取钥匙</div>
              <div v-if="item.serviceModel">-</div>
              <div class="right" v-if="item.pickUp === 1">到店自取</div>
              <div class="right" v-if="item.pickUp === 2">完工送钥匙</div>
            </div>
            <div class="des">
              <div class="left">订单号：</div>
              <div class="right">{{ item.orderNumber }}</div>
            </div>
            <div class="des">
              <div class="left">车牌：</div>
              <div class="right">
                <img
                  src="../../assets/vipicon.png"
                  alt=""
                  v-if="item.isVip"
                />{{ item.carNumber }}
              </div>
            </div>
            <div class="des">
              <div class="left">洗车员工：</div>
              <div class="right" v-if="item.shopStaffVoList[0]">
                {{ item.shopStaffVoList[0].shopStaffName }} &nbsp;&nbsp;<span
                  v-if="
                    item.shopStaffVoList[1] &&
                    item.shopStaffVoList[0].shopStaffName !==
                      item.shopStaffVoList[1].shopStaffName
                  "
                  >{{ item.shopStaffVoList[1].shopStaffName }}</span
                >
                <!-- <div class="resetStaff" v-if="item.orderNumber">重新分配</div> -->
                <!-- <div class="resetStaff" @click="resStaff()">重新分配</div> -->
              </div>
            </div>
            <!-- <div class="des">
              <div class="left">服务时间：</div>
              <div class="right">{{ item.startTime }} <span v-if="item.startTime">-</span> {{ item.endTime }}</div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="siteArea" v-else>
        <div class="none">无相关工位</div>
      </div>
      <!-- 新增工位弹窗 -->
      <el-dialog
        :title="isEditSite === 2 ? '新增工位' : '编辑工位'"
        :visible.sync="addSite"
        width="380px"
        :show-close="false"
      >
        <div class="new">
          <span>工位号</span>
          <el-input
            type="number"
            placeholder="请输入工位名(限制8位)"
            v-model="name"
            oninput="if(value.length > 5) value = value.slice(0,8)"
          ></el-input>
        </div>
        <!-- <div class="new">
          <span>订单号</span>
          <el-input
            type="text"
            placeholder="请输入订单号"
            v-model="newOrderId"
          ></el-input>
        </div> -->
        <div class="new">
          <span>工位类型</span>
          <el-select v-model="value" placeholder="请选择工位类型" clearable>
            <el-option
              v-for="item in type"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="new">
          <span>工位状态</span>
          <el-select
            v-model="newSiteStatus"
            placeholder="请选择工位状态"
            clearable
          >
            <el-option
              v-for="item in siteStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addSite = false" size="small">取 消</el-button>
          <el-button type="primary" size="small" @click="newSite"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <!-- 新增工位记录弹窗 -->
      <!-- 新增工位记录弹窗 -->
      <!-- 新增工位记录弹窗 -->
    </div>
    <!-- 编辑工位记录组件 -->
    <!-- <editSiteRecords ref="editSR"></editSiteRecords> -->
  </div>
</template>

<script>
// import editSiteRecords from '@/components/siteEdit/editSiteRecords'
import { getSiteList, delSite, updateShopStation, addSite, delSiteUseRecords } from '@/api/myshop/shop.js'
export default {
  name: 'siteManagement',
  data() {
    return {
      isEditSite: 2, // 是否是编辑工位 1是2否
      addSite: false,//新增工位弹窗状态,
      addRecord: false,//新增记录弹窗状态,
      searchSitesInfo: {
        name: '',
        type: null,
        status: null,
        shopId: null
      },//搜索工位参数
      value: '',
      siteStatus: [
        {
          value: 1,
          label: '使用中',
        },
        {
          value: 2,
          label: '空闲中',
        }
        ,
        {
          value: 3,
          label: '停用',
        }
      ],
      newSiteStatus: null,//新建工位状态值
      newOrderId: null,//新建工位时的订单号
      siteId: null, // 工位id
      type: [
        {
          value: 1,
          label: '洗车工位',
        },
        {
          value: 2,
          label: '美容工位',
        }
      ],
      name: '',//新工位号
      site: [],

    }
  },
  components: {
    // editSiteRecords
  },
  created() {
    // 获取工位页面信息
    if (sessionStorage.getItem('shopId')) {
      this.getSiteList(sessionStorage.getItem('shopId'))
    }
  },
  methods: {
    // 条件搜索工位信息
    searchSites() {
      // console.log('搜索工位信息', this.searchSitesInfo);
      this.getSiteList(sessionStorage.getItem('shopId'))
    },
    // 获取工位列表信息
    async getSiteList(val) {
      this.searchSitesInfo.shopId = val
      const res = await getSiteList(this.searchSitesInfo)
      // console.log('工位列表信息', res);
      if (res.status === 200) {
        this.siteName = [];
        this.site = res.data
        res.data.map((item) => {
          this.siteName.push({
            label: item.name,
            value: item.name
          })
        })
        // console.log('xuanze', this.siteName);
      }
    },
    // 编辑工位
    editSite(val) {
      this.isEditSite = 1;
      this.name = val.name;
      this.value = val.type;
      this.newSiteStatus = val.status;
      this.addSite = true;
      this.siteId = val.id;
      // console.log(val);
    },
    // 删除工位
    delSite(val) {
      // console.log('删除工位', val);
      this.$confirm('是否删除当前工位?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await delSite({ shopStationId: val.id })
        // console.log('del', res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            type: 'success',
            message: '删除成功!'
          });
          this.getSiteList(sessionStorage.getItem('shopId'))
        } else {
          this.$message({
            showClose: true,
            type: 'info',
            message: '删除失败!' + res.msg
          });
        }
      }).catch(() => {
        this.$message({
          showClose: true,
          type: 'info',
          message: '取消删除'
        });
      });
    },
    // 重新分配员工
    resStaff() {
      // console.log('重新分配员工');
    },
    //打开新增工位弹窗
    add() {
      // console.log('新增工位');
      if (!sessionStorage.getItem('shopId')) {
        this.$message({
          showClose: true,
          type: 'info',
          message: '请先完善店铺基础信息'
        })
      } else {
        this.isEditSite = 2;
        this.addSite = true
        this.name = ''
        this.value = null;
        this.newSiteStatus = null;
      }

    },
    //打开新增工位记录
    addRe() {
      // console.log('新增工位记录');
      if (!sessionStorage.getItem('shopId')) {
        this.$message({
          showClose: true,
          type: 'info',
          message: '请先完善店铺基础信息'
        })
      } else {
        this.$refs.editSR.openCom()
      }

    },
    // 新增编辑工位确定
    async newSite() {
      if (this.name === '') {
        this.$message(
          {
            showClose: true,
            type: 'info',
            message: '请输入工位名'
          }
        )
        return
      }
      if (this.value === '') {
        this.$message(
          {
            showClose: true,
            type: 'info',
            message: '请选择工位类型'
          }
        )
        return
      }
      if (this.isEditSite === 1) {
        const res = await updateShopStation({ name: this.name, id: this.siteId, shopId: sessionStorage.getItem('shopId'), type: this.value, status: this.newSiteStatus })
        // console.log('addsite', res);
        if (res.status === 200) {
          this.$message(
            {
              showClose: true,
              type: 'success',
              message: '编辑成功！'
            }
          )
          this.addSite = false
          this.getSiteList(sessionStorage.getItem('shopId'))
        } else {
          this.$message(
            {
              showClose: true,
              type: 'warning',
              message: res.msg
            }
          )
        }
      } else {
        const res = await addSite({ name: this.name, shopId: sessionStorage.getItem('shopId'), type: this.value, status: this.newSiteStatus })
        // console.log('addsite', res);
        if (res.status === 200) {
          this.$message(
            {
              showClose: true,
              type: 'success',
              message: '新增成功！'
            }
          )
          this.addSite = false
          this.getSiteList(sessionStorage.getItem('shopId'))
        } else {
          this.$message(
            {
              showClose: true,
              type: 'warning',
              message: res.msg
            }
          )
        }
      }
    }

  }
}
</script>

<style lang='scss' scoped>
.siteManagement {
  width: 100%;
  // 输入框和选择框大小\
  .mydatapicker {
    margin-right: 10px;
  }

  .sites {
    width: 100%;
    padding-top: 40px;
    min-height: 100px;
    ::v-deep .el-button {
      width: 70px;
      height: 30px;
      line-height: 5px;
    }
    .searchBar {
      width: 100%;
      height: 30px;
      margin-bottom: 20px;
      display: flex;

      .el-input {
        width: 160px !important;
        margin-right: 10px !important;
      }
      .el-select {
        width: 160px !important;
        height: 30px !important;
        margin-right: 10px;
      }
      button {
        width: 70px;
        height: 30px;
        background: linear-gradient(
          270deg,
          #01df6e 0%,
          #2df891 50%,
          #01df6e 100%
        );
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        border: none;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        line-height: 30px;
        color: #ffffff;
        cursor: pointer;
        margin-right: 10px;
      }
    }
    .searchBar::v-deep .el-input__icon {
      line-height: 30px !important;
    }
    .searchBar::v-deep .el-input__inner {
      height: 30px !important;
    }
    .searchBar::v-deep .el-date-editor .el-range-separator {
      line-height: 21px !important;
    }
    .siteArea {
      width: 100%;
      min-height: 220px;
      margin-top: 20px;
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      .none {
        width: 100%;
        text-align: center;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgb(214, 210, 210);
        line-height: 220px;
      }
      .item {
        width: 295px;
        min-height: 270px;
        background: #ffffff;
        border-radius: 3px;
        border: 1px solid #5f6679;
        margin-bottom: 30px;
        margin-right: 20px;

        display: flex;
        flex-direction: column;
        align-items: center;
        .siteStatus {
          width: 255px;
          height: 29px;
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #dddddd;
          position: relative;

          .order {
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #5f6679;
            line-height: 22px;
          }
          .del {
            width: 25px;
            height: 25px;
            border-radius: 50%;

            display: none;
            background-color: #fff;
            position: absolute;
            right: -32px;
            top: 16px;
            img {
              width: 25px;
              height: 25px;
              cursor: pointer;
            }
          }
          .edit {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: none;
            background-color: #fff;
            position: absolute;
            right: -32px;
            top: -20px;
            img {
              width: 25px;
              height: 25px;
              cursor: pointer;
            }
          }
          .free {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #288ce9;
            line-height: 22px;
          }
          .using {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #36cb0a;
            line-height: 22px;
          }
        }
        .detail {
          width: 255px;
          height: 25px;

          .des {
            margin: 20px 0px;
            display: flex;
            align-content: center;
            .left {
              width: 70px;
              height: 20px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #313131;
              line-height: 20px;
              text-align: left;
              margin-right: 10px;
            }
            .right {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #7d7d7d;
              line-height: 20px;
              display: flex;
              align-items: center;
              .resetStaff {
                width: 73px;
                height: 25px;
                background: #ffffff;
                border-radius: 2px;
                border: 1px solid #232932;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #232932;
                line-height: 25px;
                text-align: center;
                cursor: pointer;
              }
              img {
                width: 18px;
                height: 14px;
                margin-right: 3px;
              }
            }
          }
        }
      }
      .item:hover {
        box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.25);
      }
      .item:hover .del {
        display: inline-block;
      }
      .item:hover .edit {
        display: inline-block;
      }
    }
    .record {
      width: 100%;
      min-height: 300px;
      margin-top: 30px;
      p {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #313131;
        line-height: 20px;
      }
      .recordsTable {
        width: 100%;
        height: 100%;
      }
    }
    .new {
      display: flex;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 20px;
      span {
        display: inline-block;
        width: 70px;
        margin-right: 10px;
        text-align: right;
      }
      .el-input {
        width: 72% !important;
      }
    }
  }
}
</style>