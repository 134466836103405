<template>
  <div class="header">
    <!-- 顶部 -->
    <!-- <div class="slogan">乐电车美管理系统</div> -->
    <div class="slogan">
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </div>
    <div class="welcome">欢迎您~ {{ username }}</div>
    <div class="config">
      <button class="editPwd" @click="editPwd = true">修改密码</button>
      <button class="exit" @click="exit">退出登录</button>
    </div>
    <!-- 修改密码弹窗 -->
    <el-dialog
      title="修改密码"
      :visible.sync="editPwd"
      width="30%"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
        size="small"
        label-width="100px"
      >
        <el-form-item prop="oldPwd" label="旧密码">
          <el-input
            type="password"
            v-model="ruleForm.oldPwd"
            placeholder="请输入旧密码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="newPwd" label="新密码">
          <el-input
            type="password"
            v-model="ruleForm.newPwd"
            placeholder="请输入新密码"
          >
          </el-input>

          <!-- <div></div> -->
        </el-form-item>
        <el-form-item prop="confirmPwd" label="确认新密码">
          <!-- <div></div> -->
          <el-input
            type="password"
            v-model="ruleForm.confirmPwd"
            placeholder="请确认新密码"
          >
          </el-input>
        </el-form-item>

        <!-- <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="small">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" size="small"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { updatePwd } from '@/api/login.js'
export default {
  data() {
    return {
      editPwd: false, //新增商品弹窗状态
      username: "",
      ruleForm: {
        oldPwd: "",
        newPwd: '',
        confirmPwd: "",
      }, //
      rules: {
        oldPwd: [{ required: true, message: "请输入旧密码", trigger: "blur" }],
        newPwd: [{ required: true, message: "请输入新密码", trigger: "blur" }],
        confirmPwd: [
          { required: true, message: "请确认新密码", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    //退出登录
    exit() {
      this.$confirm("是否退出当前登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            showClose: true,
            type: "success",
            message: "退出成功!",
          });
          sessionStorage.clear();
          this.$router.push("/");
        })
        .catch(() => {
          this.$message({
            showClose: true,
            type: "info",
            message: "取消退出",
          });
        });
    },
    //取消修改按钮
    cancel() {
      this.ruleForm = {
        oldPwd: "",
        newPwd: '',
        confirmPwd: "",
      };
      this.editPwd = false;
    },
    // 修改密码确定
    submitForm() {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          if (this.ruleForm.newPwd !== this.ruleForm.confirmPwd) {
            return this.$message({
              showClose: true,
              type: "warning",
              message: "密码不一致！",
            });
          }
          const res = await updatePwd(this.ruleForm)
          if (res.status === 200) {
            this.$message({
              showClose: true,
              message: '修改成功，请重新登录！',
              type: 'success'
            });
            // console.log(res.data)
            this.cancel();
            sessionStorage.clear();
            this.$router.push("/");
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: 'error'
            });
          }

        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
  },
  created() {
    this.username = JSON.parse(sessionStorage.getItem("userLoginfo")).name;
  },
};
</script>

<style lang="scss" scoped>
.header {
  // box-shadow: -1px -1px 1px 0px #DAEEFC, 1px 1px 1px 0px #DAEEFC;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  // height: 80px;
  .slogan {
    max-width: 368px;
    height: 65px;
    font-size: 2.1rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 65px;
    letter-spacing: 5px;
  }
  .welcome {
    max-width: 186px;
    height: 37px;
    font-size: 1.5rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 37px;
  }
  .config {
    width: 250px;

    display: flex;
    justify-content: space-between;
    button {
      width: 109px;
      height: 30px;
      background: #01df6e;
      border-radius: 15px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #fff;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
    }
  }
  .pwdItem {
    display: flex;
    span {
      display: inline-block;
      width: 100px !important;
      text-align: right;
      margin-right: 10px;
    }
  }
}
</style>