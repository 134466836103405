<template>
  <!-- 新增编辑分享配置 -->
  <div class="sharConfiguration">
    <!-- <el-menu
      :default-active="$route.path"
      text-color="#7D7D7D"
      active-text-color="#E3620B"
      mode="horizontal"
      :router="true"
    >
      <el-menu-item :index="addMenuList[0].path">基础信息</el-menu-item>
    </el-menu> -->
    <div class="additional">
      <el-form
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="medium"
        label-width="135px"
        label-position="right"
      >
        <el-form-item label="标题" prop="title">
          <el-input
            v-model="formData.title"
            placeholder="请输入标题"
            clearable
            :style="{ width: '30%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="depict">
          <el-input
            v-model="formData.depict"
            placeholder="请输入描述"
            clearable
            :style="{ width: '30%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select
            v-model="formData.type"
            placeholder="请选择类型"
            clearable
            :style="{ width: '30%' }"
          >
            <el-option
              v-for="(item, index) in typeOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="分享人数"
          prop="peopleNumber"
          v-if="formData.type === 1"
        >
          <el-input
            v-model="formData.peopleNumber"
            placeholder="请输入分享人数"
            clearable
            :style="{ width: '30%' }"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="现金券" prop="discountId">
          <el-select
            v-model="formData.discountId"
            placeholder="请选择现金券"
            clearable
            :style="{ width: '30%' }"
          >
            <el-option
              v-for="(item, index) in discountIdOptions"
              :key="index"
              :label="item.name"
              :value="item.id"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item size="large">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" :loading="loading" @click="submitForm"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { addExtensionConfigure, editExtensionConfigure, getDiscountAll, getExtensionConfigureDetil } from '@/api/sharConfiguration/index.js'
export default {
  name: 'sharConfiguration',
  components: {},
  props: {},
  data() {
    return {
      sharConfigurationType: sessionStorage.getItem('sharConfigurationType'),
      sharConfigurationId: parseInt(sessionStorage.getItem('sharConfigurationId')), // 附加商品id
      // addMenuList: [{ id: 1, title: '员工信息', name: 'first', path: '/home/sharConfiguration' }],
      formData: {
        title: '',
        depict: '',
        type: null,
        peopleNumber: null,
        discountId: null,
      },
      rules: {
        title: [{
          required: true,
          message: '请输入标题',
          trigger: 'blur'
        }],
        depict: [{
          required: true,
          message: '请输入描述',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '请选择类型',
          trigger: 'change'
        }],
        peopleNumber: [{
          required: true,
          message: '请输入分享人数',
          trigger: 'blur'
        }],
        discountId: [{
          required: true,
          message: '请选择现金券',
          trigger: 'change'
        }],
      },
      typeOptions: [{
        "label": "分享人",
        "value": 1
      }, {
        "label": "被分享人",
        "value": 2
      }],
      discountIdOptions: [],
      loading: false,
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getDiscountAll();
    if (this.sharConfigurationType === 'edit') {
      this.getExtensionConfigureDetil();
    }
  },
  methods: {
    // 获取附加商品详情
    async getExtensionConfigureDetil() {
      const res = await getExtensionConfigureDetil(this.sharConfigurationId);
      // console.log(res);
      if (res.status === 200) {
        this.formData.title = res.data.title;
        this.formData.depict = res.data.depict;
        this.formData.type = res.data.type;
        this.formData.peopleNumber = res.data.peopleNumber;
        this.formData.discountId = res.data.discountId;
        this.formData.id = this.sharConfigurationId;
      }
    },
    // 获取现金券
    async getDiscountAll() {
      const res = await getDiscountAll({
        page: 1,
        size: 999,
        isOverdue: 2
      });
      // console.log(res);
      if (res.status === 200) {
        this.discountIdOptions = res.data.rows;
      }
    },
    // 确定按钮
    submitForm() {
      this.$refs['elForm'].validate(async valid => {
        if (!valid) return
        this.loading = true;
        // TODO 提交表单
        if (this.sharConfigurationType === 'add') {
          const res = await addExtensionConfigure(this.formData);
          // console.log(res);
          if (res.status === 200) {
            this.$message({
              showClose: true,
              message: '新增成功！',
              type: 'success'
            });
            this.loading = false;
            this.cancel()
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: 'error'
            });
            this.loading = false;
          }
        } else {
          const res = await editExtensionConfigure(this.formData);
          // console.log(res);
          if (res.status === 200) {
            this.$message({
              showClose: true,
              message: '编辑成功！',
              type: 'success'
            });
            this.loading = false;
            this.cancel()
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: 'error'
            });
            this.loading = false;
          }
        }
      })
    },
    // 取消按钮
    cancel() {
      this.$router.push('/home/sharConfiguration')
    },
  }
}
</script>

<style lang="scss" scoped>
.sharConfiguration {
  padding: 0 30px;
  .additional {
    // width: 320px;
    padding-top: 40px;
    min-height: 300px;
    ::v-deep .el-button {
      width: 70px;
      height: 30px;
      background: linear-gradient(
        270deg,
        #01df6e 0%,
        #2df891 50%,
        #01df6e 100%
      );
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      line-height: 5px;
      margin-top: 30px;
      margin-bottom: 20px;
    }
    .el-button--default {
      background: #f4fff5;
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      color: #01df6e;
      margin-right: 20px;
    }
    .el-myselect {
      width: 100%;
    }
    .el-mytime {
      width: 108px;
    }
  }
}
</style>
