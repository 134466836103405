<template>
  <!-- 机构消费记录 -->
  <div class="order">
    <div class="searchBar">
      <el-select
        v-model="recordData.shopId"
        placeholder="请选择店铺"
        clearable
        @change="queryBtn"
      >
        <el-option
          v-for="item in shopOptions"
          :key="item.shopId"
          :label="item.shopName"
          :value="item.shopId"
        >
        </el-option>
      </el-select>
      <el-input
        v-model="recordData.customerName"
        clearable
        placeholder="客户名称"
      />
      <el-date-picker
        v-model="recordData.time"
        type="daterange"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd HH:mm:ss"
        :default-time="['00:00:00', '23:59:59']"
      >
      </el-date-picker>
      <button @click="queryBtn">查询</button>
      <!-- <button @click="add">新增</button> -->
    </div>

    <div class="staff">
      <el-table :data="recordList" border style="width: 100%">
        <el-table-column type="index" label="编号" width="80">
        </el-table-column>
        <el-table-column prop="orderNumber" label="订单号" width="180">
        </el-table-column>
        <el-table-column prop="carNumber" label="车牌号" width="100">
        </el-table-column>
        <el-table-column prop="customerName" label="客户姓名" width="100">
          <template slot-scope="scope">
            <div class="mycell">
              {{scope.row.customerName}}
              <span v-if="scope.row.gender === 1" style="margin-left: 10px;">先生</span>
              <span v-if="scope.row.gender === 2" style="margin-left: 10px;">女士</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="telephone" label="手机号" width="120"> </el-table-column>
        <!-- <el-table-column prop="carNum" label="会员名称">
          <template slot-scope="scope">
            <div class="mycell">
              <img
                :src="scope.row.iconUrl"
                alt=""
                class="vipIcon"
                v-if="scope.row.iconUrl"
              />
              <span>{{ scope.row.memberName }}</span>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column prop="shopName" label="店铺名称" width="120">
          <template slot-scope="scope">
            <div class="mycell">
              <span v-if="scope.row.shopName">{{ scope.row.shopName }}</span>
              <span v-else style="color: #ccc">暂无</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="consumeMoney" label="消费/充值金额" width="120">
          <template slot-scope="scope">
            <div class="mycell mycell1">
              <span style="color: #BF0502;" v-if="scope.row.relation === 1"
                ><span v-if="scope.row.consumeMoney.indexOf('-') == -1">-</span
                >{{ scope.row.consumeMoney }}</span
              >
              <span style="color: #17B52A;" v-else>+{{ scope.row.consumeMoney }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="time" label="消费/充值时间" width="180"> </el-table-column>
        <el-table-column prop="userName" label="操作人名称" width="100"> </el-table-column>
        <el-table-column prop="applyReason" label="变更原因"> </el-table-column>
        <el-table-column prop="voucherNumber" label="凭证号"> </el-table-column>
        <!-- <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <el-button @click="edit(scope.row)" type="text" size="small"
              >编辑</el-button
            >
            <el-button type="text" size="small" @click="del(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column> -->
      </el-table>
      <div class="block">
        <el-pagination
          v-model="recordData"
          @current-change="handleCurrentChange"
          :current-page.sync="recordData.page"
          :page-size="recordData.size"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getRecordList, getSelectAllShop } from '@/api/mechanismCustomerManagement/mechanismCustomerManagement.js'
export default {
  name: 'order',
  data() {
    return {
      // value: [],
      recordData: {
        id: parseInt(sessionStorage.getItem('mechanismId')),
        shopId: null,
        page: 1,
        size: 8,
        customerName: '',
        time: ''
      },
      total: 0,
      recordList: [],// 记录列表数据
      shopOptions: [], // 店铺列表
    }
  },
  created() {
    this.getRecordList();
    this.getSelectAllShop();
  },
  methods: {
    // 查看所有店铺不分页
    async getSelectAllShop() {
      const res = await getSelectAllShop({
        object: ''
      });
      // console.log(res);
      if (res.status === 200) {
        this.shopOptions = res.data;
      }
    },
    // 获取个人消费充值记录列表
    async getRecordList() {
      const res = await getRecordList(this.recordData);
      // console.log(res);
      if (res.status === 200) {
        this.recordList = res.data.rows;
        this.total = res.data.total;
      }
    },
    // 查询
    queryBtn() {
      // this.recordData.startTime = this.value[0];
      // this.recordData.endTime = this.value[1];
      // console.log(this.recordData);
      this.getRecordList();
    },
    // 翻页
    handleCurrentChange(currentPage) {
      this.recordData.page = currentPage;
      this.getRecordList();
    },
    // 新增
    add() { },
    // 编辑
    edit() { },
    // 删除
    del() {

    }
  }
}
</script>

<style lang='scss' scoped>
.order {
  width: 100%;
  .searchBar {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    .el-select {
      margin-right: 15px;
    }
    .el-input {
      width: 220px;
      margin-right: 15px;
    }
    button {
      width: 70px;
      height: 30px;
      background: linear-gradient(
        270deg,
        #01df6e 0%,
        #2df891 50%,
        #01df6e 100%
      );
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 30px;
      cursor: pointer;
      margin-top: 5px;
      margin-left: 15px;
    }
  }
  .block {
    text-align: center;
    padding-top: 20px;
  }
  .staff {
    width: 100%;
    margin-top: 40px;
    overflow: hidden;
    span {
      cursor: pointer;
    }
    .mycell {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      // justify-content: center;
      img {
        width: 19px;
        height: 16px;
        margin-right: 3px;
      }
      span {
        display: inline-block;
      }
      .isEdit {
        display: inline-block;
        width: 24px;
        height: 16px;
        background: #eb5050;
        border-radius: 2px;

        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 17px;
        text-align: center;
        margin-left: 10px;
      }
      .orderStatusIng {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4995f5;
        line-height: 20px;
      }
      .orderStatusIng::before {
        content: "";
        display: inline-block;
        width: 14px;
        height: 14px;
        background: #4995f5;
        border-radius: 50%;
        vertical-align: top;
        margin-right: 2px;
        margin-top: 3px;
      }
      .orderStatusFinish {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #36cb0a;
        line-height: 20px;
      }
      .orderStatusFinish::before {
        content: "";
        display: inline-block;
        width: 14px;
        height: 14px;
        background: #36cb0a;
        border-radius: 50%;
        vertical-align: top;
        margin-right: 2px;
        margin-top: 3px;
      }
      .orderStatusCancel {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #acacac;
        line-height: 20px;
      }
      .orderStatusCancel::before {
        content: "";
        display: inline-block;
        width: 14px;
        height: 14px;
        background: #acacac;
        border-radius: 50%;
        vertical-align: top;
        margin-right: 2px;
        margin-top: 3px;
      }
    }
    .mycell1 {
      justify-content: end;
    }
  }
  .new {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    span {
      display: inline-block;
      width: 58px;
      text-align: right;
      margin-right: 10px;
    }
    .el-input {
      width: 80% !important;
    }
  }
}
</style>