<template>
  <div class="serviceImg">
    <div class="appearance">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="外观">
          <div class="addbtn">
            <el-button type="primary" @click="addShow1">新增</el-button>
          </div>
          <div v-if="photoList1.length > 0">
            <div
              class="myphoto"
              v-for="(item, index) in photoList1"
              :key="index"
            >
              <img :src="item.url" alt="" srcset="" />
              <div class="delimg" @click="delimg(item.id)">
                <img src="../../assets/delGoods.png" alt="" />
              </div>
              <div class="photoName">{{ item.name }}</div>
            </div>
          </div>
          <div class="wuBox" v-else>暂无清单外观信息</div>
        </el-form-item>
        <el-form-item label="内饰">
          <div class="addbtn">
            <el-button type="primary" @click="addShow2">新增</el-button>
          </div>
          <div v-if="photoList2.length > 0">
            <div
              class="myphoto"
              v-for="(item, index) in photoList2"
              :key="index"
            >
              <img :src="item.url" alt="" srcset="" />
              <div class="delimg" @click="delimg(item.id)">
                <img src="../../assets/delGoods.png" alt="" />
              </div>
              <div class="photoName">{{ item.name }}</div>
            </div>
          </div>
          <div class="wuBox" v-else>暂无清单内饰信息</div>
        </el-form-item>
        <el-form-item label="细节">
          <div class="addbtn">
            <el-button type="primary" @click="addShow3">新增</el-button>
          </div>
          <div v-if="photoList3.length > 0">
            <div
              class="myphoto"
              v-for="(item, index) in photoList3"
              :key="index"
            >
              <img :src="item.url" alt="" srcset="" />
              <div class="delimg" @click="delimg(item.id)">
                <img src="../../assets/delGoods.png" alt="" />
              </div>
              <div class="photoName">{{ item.name }}</div>
            </div>
          </div>
          <div class="wuBox" v-else>暂无清单细节信息</div>
        </el-form-item>
      </el-form>
    </div>

    <!-- 上传弹窗 -->
    <el-dialog
      :title="'新增服务清单(' + isName + ')'"
      :visible.sync="editShow"
      width="30%"
      :show-close="false"
    >
      <el-form ref="form1" label-width="80px">
        <el-form-item label="图片">
          <!--          action="https://ledianchemei.zhihanyixing.com/api/file/common/upload"   -->
          <!--     action="https://manage.lediangroup.cn/api/file/common/upload"     -->
            <!-- action="http://192.168.0.115:8991/api/file/common/upload" -->
          <el-upload
            class="avatar-uploader"
            action="https://manage.lediangroup.cn/api/file/common/upload"
            :show-file-list="false"
            :headers="{ token: token }"
            :on-success="handleSuccess"
            :before-upload="beforeUpload"
          >
            <img
              v-if="detailedStyleData.pictureUrl"
              :src="detailedStyleData.pictureUrl"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="说明">
          <el-input
            type="text"
            maxlength="10"
            placeholder="请输入图片说明"
            v-model="detailedStyleData.name"
          ></el-input>
        </el-form-item>
        <el-form-item class="btnBox" size="large">
          <el-button class="resBox" style="margin-left: 10%" @click="cancelBtn"
            >取消</el-button
          >
          <el-button class="subBox" type="primary" @click="confirmBtn"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getDetailedStyle, addDetailedStyle, delDetailedStyle } from '@/api/goodsManagement/goodsManagement.js'
export default {
  name: 'serviceImg',
  components: {},
  props: {},
  data() {
    return {
      token: sessionStorage.getItem('token'),
      form: {},
      photoList1: [],
      photoList2: [],
      photoList3: [],
      isName: '外观',
      editShow: false,
      loading: false,
      detailedStyleData: {
        name: '',
        pictureUrl: '', // 图片url
        serviceProjectId: parseInt(sessionStorage.getItem('serviceId')), // 服务项目id
        type: 1 // 类型 1外观 2内饰 3细节
      },
      page: {
        page: 1,
        size: 999,
        serviceProjectId: [parseInt(sessionStorage.getItem('serviceId'))], // 服务项目id
      }
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getDetailedStyle()
  },
  methods: {
    // 获取服务清单图片
    async getDetailedStyle() {
      const res = await getDetailedStyle(this.page);
      if (res.status === 200) {
        this.photoList1 = res.data.appearance
        this.photoList2 = res.data.interior
        this.photoList3 = res.data.details
      }
    },
    // 外观添加按钮
    addShow1() {
      this.detailedStyleData.type = 1;
      this.isName = '外观';
      this.editShow = true;
    },
    // 内饰添加按钮
    addShow2() {
      this.detailedStyleData.type = 2;
      this.isName = '内饰';
      this.editShow = true;
    },
    // 细节添加按钮
    addShow3() {
      this.detailedStyleData.type = 3;
      this.isName = '细节';
      this.editShow = true;
    },
    beforeUpload(file) {
      let sizeByMb = file.size / 1024 / 1024;
      if (sizeByMb > 1) {
        this.$message(
          {
            type: 'warning',
            message: '上传文件不能超过1M',
            showClose: true
          }
        );
        return false;
      }
      let types = ['image/jpeg', 'image/jpg', 'image/png'];
      const isImage = types.includes(file.type);
      if (!isImage) {
        // this.$message.error('');
        this.$message(
          {
            type: 'warning',
            message: '上传图片只能是 JPG、JPEG、PNG 格式!',
            showClose: true
          }
        );
        return false;
      }
      return true;
    },
    // 提交服务清单外观图片
    handleSuccess(res, value, fileList) {
      // console.log('12312313', res, value);
      if (res.status === 200) {
        this.$message({
          showClose: true,
          type: 'success',
          message: '选择成功，请点击确定保存操作！'
        });
        this.detailedStyleData.pictureUrl = res.data.url;
      } else {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '上传失败' + res.msg
        });
      }

      // console.log('photo', fileList);
      // 重新加载数据
      // this.init();
    },
    //删除服务清单外观图片
    delimg(val) {
      this.$confirm('是否删除改服务服务清单的相关信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // console.log('del', val);
        const res = await delDetailedStyle(val)
        if (res.status === 200) {
          this.getDetailedStyle()
          this.$message(
            {
              showClose: true,
              type: 'success',
              message: '已删除!'
            }
          )
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
      // console.log('photo', this.photoList1);
    },
    // 弹层取消按钮
    cancelBtn() {
      this.detailedStyleData.pictureUrl = '';
      this.detailedStyleData.name = '';
      this.editShow = false;
    },
    // 弹层确认按钮
    async confirmBtn() {
      const res1 = await addDetailedStyle(this.detailedStyleData);
      if (res1.status === 200) {
        this.$message({
          showClose: true,
          type: 'success',
          message: '新增成功!'
        });
        this.cancelBtn();
        this.getDetailedStyle();
      } else {
        this.$message({
          showClose: true,
          type: 'warning',
          message: res1.msg
        });
      }
    }
    // //取消按钮
    // cancelBtn() {
    //   this.form = {}
    //   this.$router.push('/home/goodsManagement');
    // },
    // // 确定按钮
    // okBtn() {

    // }
  }
}
</script>

<style lang="scss" scoped>
.serviceImg {
  margin-top: 40px;
  padding: 0 30px;
  padding-bottom: 40px;
  ::v-deep.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  ::v-deep.avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  ::v-deep.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  ::v-deep.avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
  ::v-deep .el-button {
    width: 70px;
    height: 30px;
    background: linear-gradient(270deg, #01df6e 0%, #2df891 50%, #01df6e 100%);
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    border: none;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 5px;
    margin-bottom: 20px;
  }
  ::v-deep .el-button--default {
    background: #f4fff5;
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    color: #01df6e;
    margin-right: 20px;
  }
  ::v-deep .el-upload__tip {
    margin-top: 0px;
    margin-left: 10px;
    color: #bbb;
  }
  // 隐藏上传文件列表
  ::v-deep .el-upload-list--picture-card {
    display: none;
  }
  ::v-deep .el-upload--picture-card {
    width: 45px !important;
    height: 45px !important;
    line-height: 55px !important;
    margin-bottom: 10px;
  }
  .myphoto {
    display: inline-block;
    width: 100px;
    // height: 100px;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
    span {
      display: inline-block;
      width: 100px;
      height: 20px;
      background: #fff4eb;
      border-radius: 4px;
      border: 1px solid #ee8539;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ee8539;
      line-height: 20px;
      text-align: center;
      cursor: pointer;
      opacity: 0.8;
      position: absolute;
      bottom: 0;
      left: 0;
      display: none;
    }
    .photoName {
      width: 100px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #696969;
      line-height: 20px;
      text-align: center;
    }
    .myCover {
      display: inline-block;
      width: 100px;
      height: 20px;
      background: #fff4eb;
      border-radius: 4px;
      border: 1px solid #ee8539;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ee8539;
      line-height: 20px;
      text-align: center;
      cursor: pointer;
      opacity: 0.8;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .delimg {
      position: absolute;
      right: -12px;
      top: -10px;
      display: none;
      img {
        width: 25px;
        height: 25px;
        cursor: pointer;
      }
    }
  }
  .myphoto:hover .delimg {
    display: inline-block;
  }
  .myphoto:hover span {
    display: inline-block;
  }
  .wuBox {
    height: 80px;
    line-height: 80px;
    color: #ccc;
  }
  .btnBox {
    margin-top: 50px;
    ::v-deep .el-button {
      width: 70px;
      height: 30px;
      background: linear-gradient(
        270deg,
        #01df6e 0%,
        #2df891 50%,
        #01df6e 100%
      );
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      line-height: 5px;
      margin-bottom: 20px;
    }
    ::v-deep .el-button--default {
      background: #f4fff5;
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      color: #01df6e;
      margin-right: 20px;
    }
  }
}
</style>
